import React, { Component } from 'react';
import UpdatePassword from './Components/UpdatePassword';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import Error from '../../Shared/Common/Error';
import Loading from '../../Shared/Common/Loading';
import UpdatePreferences from './Components/UpdatePreferences';
import Breadcrumbs from '../../Shared/Common/Breadcrumbs';

const PageContent = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 p-2">
                    <div className="col-md-6 form-group">
                        <label>Email</label>
                        <input placeholder={props.state.user.email} type="text" id="email" name="email" className="form-control" />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>First name</label>
                        <input placeholder={props.state.user.firstName} type="text" id="firstName" name="firstName" className="form-control" />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>Last name</label>
                        <input placeholder={props.state.user.lastName} type="text" id="lastName" name="lastName" className="form-control" />
                    </div>
                    <UpdatePreferences preferences={props.state.user.tradeMemberDetails} />
                    <UpdatePassword />
                </div>
            </div>
        </div>
    );
}
class EditAccount extends Component {
    constructor(props) {
        super(props);
        this.state = { user: {} }

        this.updateUser();
    }

    updateUser() {
        apiGet("/api/account/user", true)
            .then((result) => this.setState({ user: result.body, componentState: "pageContent" }))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    state={this.state}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-12">
                    <Breadcrumbs crumbs={this.props.crumbs} pageCrumb={"Edit account"} />
                </div>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default EditAccount;