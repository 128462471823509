import React, { Component } from 'react';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import AuctionCard from './Components/AuctionCard';
import Loading from '../../Shared/Common/Loading';
import Error from '../../Shared/Common/Error';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import moment from 'moment';

class Auctions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentState: "loading",
            buyItNow: [],
            open: [],
            preview: [],
            upcoming: [],
            closed: []
        };

        this.getAuctions = this.getAuctions.bind(this);
    }

    componentDidMount() {
        this.getAuctions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lastNotification.dateTimeStamp !== this.props.lastNotification.dateTimeStamp) {
            if (this.props.lastNotification.table === "auction") {
                this.getAuctions();
            }
        }
    }

    getDuration() {
        for (var x = 0; x < this.state.auction; x++) {
            let start = x.startDateTime;
            let end = x.endDateTime;
            let duration = moment.duration(end.diff(start));

            const obj = { 'duration': duration, 'columnType': String };
            this.setState({
                auction: [...this.state.auction, obj]
            });

            const updatedAuction = this.state.auction.slice(); // Create a copy
            updatedAuction.push(obj); // Push the object
            this.setState({ auction: updatedAuction });
        }
    }

    getAuctions() {
        this.setState({ componentState: "loading" });
        apiGet('/api/auction/search', true)
            .then(result => this.setState({ componentState: "auctions", buyItNow: result.body.buyItNow, open: result.body.open, preview: result.body.preview, upcoming: result.body.upcoming, closed: result.body.closed }))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "auctions":
                return (
                    <div className="container content">
                        {this.state.buyItNow.map(x => <AuctionCard key={x.id} auction={x} message='Buy now' msgClass="buynow" buynow="true" />)}
                        <div className="row mb-2 px-0">
                            <Tabs className="col-12 vm-tabs four numbers">
                                <Tab label={`Live ${this.state.open.length}`}>
                                    <div className="row mx-3 mt-4">
                                        <div className="col-12 text-alert-center mb-0">
                                            {this.state.open && this.state.open.length > 0 ?
                                                <div className="alert alert-success text-center" role="alert">
                                                    These auctions are now open for you to bid
                                                </div>
                                                :
                                                <div className="alert alert-secondary text-center" role="alert">
                                                    There are currently no live auctions
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {this.state.open.map(x => <AuctionCard key={x.id} auction={x} message='This auction is now live' msgClass="live" />)}
                                </Tab>
                                <Tab label={`Preview ${this.state.preview.length}`}>
                                    <div className="row mx-3 mt-4">
                                        <div className="col-12 text-alert-center mb-0">
                                            {this.state.preview && this.state.preview.length > 0 ?
                                                <div className="alert alert-success text-center" role="alert">
                                                    These auctions are now available for preview and open for Proxy Bidding only
                                                </div>
                                                :
                                                <div className="alert alert-secondary text-center" role="alert">
                                                    There are currently no preview auctions available to view
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {this.state.preview.map(x => <AuctionCard key={x.id} auction={x} message='You can preview this auction' msgClass="preview" />)}
                                </Tab>
                                <Tab label={`Upcoming ${this.state.upcoming.length}`}>
                                    <div className="row mx-3 mt-4">
                                        <div className="col-12 text-alert-center mb-0">
                                            {this.state.upcoming && this.state.upcoming.length > 0 ?
                                                <div className="alert alert-success text-center" role="alert">
                                                    These auctions will be available to view soon
                                                </div>
                                                :
                                                <div className="alert alert-secondary text-center" role="alert">
                                                    There are currently no upcoming auctions
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {this.state.upcoming.map(x => <AuctionCard key={x.id} auction={x} message='This auction has not yet started' msgClass="upcoming" />)}
                                </Tab>
                                <Tab label={`Closed ${this.state.closed.length}`}>
                                    <div className="row mx-3 mt-4">
                                        <div className="col-12 text-alert-center mb-0">
                                            {this.state.closed && this.state.closed.length > 0 ?
                                                <div className="alert alert-info text-center" role="alert">
                                                    These auctions have closed
                                                    </div>
                                                :
                                                <div className="alert alert-secondary text-center" role="alert">
                                                    There are currently no closed auctions to view
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    {this.state.closed.map(x => <AuctionCard key={x.id} auction={x} message='This auction has ended' msgClass="closed" />)}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                );
            case "error":
                return <Error refresh={this.getAuctions} error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <>
                {/* <Breadcrumbs pageCrumb={"Auctions"} /> */}
                <div className="col-12 mt-4 no-gutters">
                    {/* <h1>Auctions</h1> */}
                    {this.renderComponentState()}
                </div>
            </>
        );
    }
};

export default Auctions;