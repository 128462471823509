import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatMoney } from '../../../Shared/Utilities/FormatMoney';

const BidHistory = (props) => {
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>Time stamp</th>
                        <th>Lot</th>
                        <th>Bid amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {props.bids.map(
                        (x, i) =>
                            <tr key={i}>
                                <td>{moment(x.timeStamp).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{x.lot.vehicles[0].make} {x.lot.vehicles[0].model}</td>
                                <td>£{formatMoney(x.amount)}</td>
                                <td><Link to={`/vehicle/${x.lot.vehicles[0].id}`}><button className="btn btn-primary">View</button></Link></td>
                            </tr>
                    )}

                </tbody>
            </table>
        </>
    );
};

export default BidHistory;