import React, { Component } from 'react';
import { isLoggedIn } from '../../Shared/Utilities/AuthUtilities';
import { Redirect, Link } from 'react-router-dom';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import Loading from '../../Shared/Common/Loading.js';
import './Styles/Home.css';
import HomeBanner from '../../Images/Home/HomeBanner2.jpg';
import HomeBannerMob from '../../Images/Home/HomeMobBanner.jpg';
import queryString from 'query-string';
import NextAuctionCountdown from '../../Shared/Common/NextAuctionCountdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from './Components/Carousel/Carousel';
import './Styles/Home.css'

const PageContent = (props) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <Link to="/Pledge">
                    <img src={HomeBanner} alt="Pledge" className="d-none d-md-block mx-auto w-100" />
                    <img src={HomeBannerMob} alt="Pledge" className="w-100 d-block d-md-none" /></Link>
            </div>
            <div className='row'>
                <Carousel />
            </div>
            <div className="row bg-white py-5">
                <div className="container py-5 d-flex justify-content-center align-items-center">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="text-center">
                            <h4 style={{ fontWeight: 'bold' }}>Van Monster Remarketing</h4>
                            <p style={{ fontWeight: 'bold' }} className="mt-4">
                                The UK’s dedicated LCV Auction platform.
                                Supporting all sizes of business in selling their vehicles to the motor trade.</p>
                            <p style={{ fontWeight: 'bold' }} className="mt-1">Exclusive online auctions to members only.</p>
                            {
                                !isLoggedIn() &&
                                <div className=" mt-5 d-flex align-items-center justify-content-center">
                                    <Link className='btn btn-success' to="/login">Login</Link>
                                    <p className="mx-2 mb-0"><strong>or</strong></p>
                                    <Link className='btn btn-success' to="/register">Register now</Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {props.nextAuction &&
                <div className="row py-5 bg-next-auction">
                    <div className='container d-flex justify-content-center'>
                        <div className="col-lg-6 col-md-12 text-center d-flex align-items-center justify-content-center">
                            <div className="col-12 countdown-container">
                                <NextAuctionCountdown />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="row bg-grey py-5">
                <div className="container py-5 d-flex justify-content-center align-items-center">
                    <div className="light-grey-box col-12 d-flex align-items-center justify-content-center">
                        <div className="py-2 col-12 col-md-6 col-lg-5 text-center">
                            <h4>Van Search</h4>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="input-group py-3">
                                    <input type="text" id="search" name="search" className="form-control" placeholder="Search" onChange={props.handleChange} />
                                    <div className="input-group-append">
                                        <a className="btn btn-success" href={"/Search?search=" + props.search}>
                                            <i className="glyphicon glyphicon-align-left">
                                                <FontAwesomeIcon icon={['fal', 'search']} />
                                            </i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentState: "Loading",
            nextAuction: {},
            search: '',
            carouselContent: []
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        if (values.ResetPassword !== undefined) {
            this.setState({ redirectPath: "ResetPassword", redirectToken: values.ResetPassword, componentState: "redirect" });
        } else if (values.ConfirmAccount !== undefined) {
            this.setState({ redirectPath: "ConfirmAccount", redirectToken: values.ConfirmAccount, componentState: "redirect" });
        } else {
            this.fetchNextAuction();
            this.fetchCarouselContent();
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    fetchNextAuction = () => {
        apiGet('/api/auction/nextauction', true)
            .then(result => this.setState({ componentState: "pageContent", nextAuction: result.body }))
            .catch(error => this.setState({ componentState: "pageContent", nextAuction: null }));
    }

    fetchCarouselContent = () => {
        apiGet('/api/content/GetActiveCarouselContent', true)
            .then(result => this.setState({ componentState: "pageContent", carouselContent: result.body.carouselContent }))
            .catch(error => this.setState({ componentState: "pageContent", carouselContent: null }));
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "redirect":
                return <Redirect to={"/" + this.state.redirectPath + "/" + encodeURIComponent(this.state.redirectToken)} />;
            case "pageContent":
                return <PageContent
                    nextAuction={this.state.nextAuction}
                    fetchNextAuction={this.fetchNextAuction}
                    handleChange={this.handleChange}
                    search={this.state.search}
                    carouselContent={this.state.carouselContent} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <>
                {this.renderComponentState()}
            </>
        );
    }
}

export default Home;