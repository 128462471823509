import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './Styles/AuctionCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BuyNow from '../../../Images/buy-now.jpg';

const AuctionCard = (props) => {

    return (
        <React.Fragment>
            {props.buynow ?
                <div className="card mb-5 vme-card--buynow">
                    <div className="row no-gutters">
                        <div className="col-lg-2 col-sm-3 img-container">
                            {props.auction.auctionTitleImageUrl && props.auction.auctionTitleImageUrl !== "" ?
                                <img src={props.auction.auctionTitleImageUrl} className="card-img" alt="..." />
                                :
                                <img src={BuyNow} className="card-img" alt="..." />
                            }

                        </div>
                        <div className="col-lg-10 col-md-9 col">
                            <div className="row mb-2">
                                <div className="card-body auction col-lg-6 col-md-4 col-sm-12 ml-3 small-title">
                                    <h4 className="card-title mb-2">{props.auction.name}</h4>
                                    <p className=""><FontAwesomeIcon icon={['fas', 'hashtag']}  className="card-icon-green" /> <strong>Lots:</strong> {props.auction.lotCount}</p>
                                </div>
                                <div className="col btn-group justify-content-end align-self-center">
                                    <Link className="card-btn d-none d-md-block buynow-btns" to={props.buynow ? '/BuyNow' : `/Auction/${props.auction.id}`}>
                                        <button className="btn btn-success btn-block">{props.buynow ? 'Enter Sale' : 'Enter Auction'}</button>
                                    </Link>
                                </div>
                                <div className="col btn-group align-self-center">
                                    <Link className="card-btn d-none d-md-block buynow-btns--left" to={`/Catalogue/${props.auction.id}`}>
                                        <button className="btn btn-info btn-block">View Catalogue</button>
                                    </Link>
                                </div>
                                <div className="col-md-4 col-xs-12 btn-group justify-content-end align-self-center">
                                    <Link className="card-btn buynow-btns" to={`/Auction/${props.auction.id}`}>
                                        <button className="btn btn-success btn-block d-block d-md-none">Enter Auction</button>
                                    </Link>
                                    <Link className="card-btn buynow-btns--left" to={`/Catalogue/${props.auction.id}`}>
                                        <button className="btn btn-info btn-block d-block d-md-none">View Catalogue</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="card mb-3 vme-card">
                    <div className="row no-gutters ">
                        <div className="col-lg-4 img-container">
                            {props.auction.auctionTitleImageUrl && props.auction.auctionTitleImageUrl !== "" ?
                                <img src={props.auction.auctionTitleImageUrl} className="card-img" alt="..." />
                                :
                                <img src="https://vmremarketing.blob.core.windows.net/images/vehicledocs%5C7b4d09e6-05c3-4146-80b5-2451cd7d6010-30ebd0be-3c15-426f-9176-4d29ce937737.png" className="card-img" alt="..." />
                            }

                        </div>
                        <div className="col-lg-8 col-md-12 card-cont">
                            <div className={`row no-gutters card-footer d-block d-lg-none text-center ${props.msgClass}`}>
                                <strong>{props.message}</strong>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-12 px-3 pt-1">
                                    <h4 className="card-title mb-0">{props.auction.name}</h4>
                                    <div className="col-lg-7 col-md-12 px-0 col-sm-12">
                                        <p className="auction-card-description">{props.auction.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row card-content">
                                <div className="card-body auction col-lg-6 col-md-12 ml-3">
                                    <div className="mb-2">
                                    </div>
                                    <div className="mb-2">
                                        <p><FontAwesomeIcon icon={['fas', 'clock']} className="card-icon-green" /> <strong>Start: </strong>{moment(props.auction.startDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                        <p><FontAwesomeIcon icon={['fas', 'clock']}  className="card-icon-orange" /> <strong>End:</strong> {moment(props.auction.endDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                        <p><FontAwesomeIcon icon={['fas', 'hashtag']}  className="card-icon-green" /> <strong>Lots:</strong> {props.auction.lotCount}</p>
                                    </div>

                                </div>
                                <div className="col-lg-5 col-md-12 card-btn-group auction-card-btns">
                                    {props.auction.stateDescription !== 'Upcoming' ?
                                        <div className="container d-flex h-100">
                                            <div className="row justify-content-center align-self-center">
                                                {(props.auction.stateDescription === 'Open' || props.auction.stateDescription === 'Extended Bidding') &&
                                                    <Link className="card-btn--mb" to={`/Auction/${props.auction.id}`}>
                                                        <button className="btn btn-success btn-block mr-2">Enter Auction</button>
                                                    </Link>
                                                }
                                                {props.auction.stateDescription === 'Preview' &&
                                                    <Link className="card-btn--mb" to={`/Auction/${props.auction.id}`}>
                                                        <button className="btn btn-success btn-block mr-2">Preview Auction</button>
                                                    </Link>
                                                }
                                                {props.auction.stateDescription === 'Closed' &&
                                                    <Link className="card-btn--mb" to={`/Auction/${props.auction.id}`}>
                                                        <button className="btn btn-success btn-block mr-2">Enter Auction</button>
                                                    </Link>
                                                }
                                                <Link className="card-btn" to={`/Catalogue/${props.auction.id}`}>
                                                    <button className="btn btn-info btn-block">View Catalogue</button>
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        <div className="container d-grid h-100">
                                            <div className="row justify-content-center align-self-center">
                                                <Link className="card-btn--mb" to={`/Auction/${props.auction.id}`}>
                                                    <button className="btn btn-success btn-block mr-2">Enter Auction</button>
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={`row no-gutters card-footer d-none d-lg-block text-center ${props.msgClass}`}>
                                <strong>{props.message}</strong>
                            </div>
                        </div>
                    </div>

                    {props.auction.stateDescription !== 'Upcoming' ?
                        <div className="row no-gutters card-footer--white small-card">
                            <div className="col-xs-12 col-sm-6 small-btns--left">
                                <Link className="card-btn--mb" to={`/Catalogue/${props.auction.id}`}>
                                    <button className="btn btn-info btn-block">View Catalogue</button>
                                </Link>
                            </div>
                            <div className="col-xs-12 col-sm-6 small-btns--right">
                                {props.auction.stateDescription === 'Open' &&
                                    <Link className="card-btn" to={`/Auction/${props.auction.id}`}>
                                        <button className="btn btn-success btn-block">Enter Auction</button>
                                    </Link>
                                }
                                {props.auction.stateDescription === 'Preview' &&
                                    <Link className="card-btn" to={`/Auction/${props.auction.id}`}>
                                        <button className="btn btn-success btn-block">Preview Auction</button>
                                    </Link>
                                }
                                {props.auction.stateDescription === 'Closed' &&
                                    <Link className="card-btn" to={`/Auction/${props.auction.id}`}>
                                        <button className="btn btn-success btn-block">Enter Auction</button>
                                    </Link>
                                }
                            </div>
                        </div>
                        :
                        <div className="row no-gutters card-footer--white small-card">
                            <div className="container d-grid h-100">
                                <div className="row justify-content-center align-self-center">
                                    <Link className="card-btn--mb" to={`/Auction/${props.auction.id}`}>
                                        <button className="btn btn-success btn-block mr-2">Enter Auction</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            }
        </React.Fragment>
    );
};

export default AuctionCard;