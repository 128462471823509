import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import { formatMoney } from '../../../Shared/Utilities/FormatMoney';
import { apiGet } from '../../../Shared/Utilities/ApiFetch';
import { getInvoiceAndDownload, openPdf } from './invoicePdf';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginTop: theme.spacing(2),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 375,
    },
    head: {
        border: 'none'
    }
}));

const Invoices = (props) => {
    const classes = useStyles();
    const [invoices, setInvoices] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        getInvoices(pageNumber);
    }, [pageNumber])

    const getInvoices = (pageNum) => {
        apiGet(`/api/Account/GetInvoiceListForTradeMember?pageNumber=${pageNum}`, true)
            .then(result => { setInvoices(result.body.invoices); setTotalPages(result.body.totalPages); });
    }

    const handlePageChange = pageNumber => {
        setPageNumber(pageNumber);
    }

    return (
        <div className={`${classes.root}`}>
            {
                invoices && invoices.length &&
                <div className="col-12">
                    <Paper className={`paper-table ${classes.paper}`}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="head-text" align="right">Invoice No.</TableCell>
                                    <TableCell className="head-text" align="right">Lot</TableCell>
                                    <TableCell className="head-text" />
                                    <TableCell className="head-text" />
                                    <TableCell className="head-text" align="right">Total</TableCell>
                                    <TableCell className="head-text" align="right">Invoice Date</TableCell>
                                    <TableCell className="head-text" align="right">Paid</TableCell>
                                    <TableCell className="head-text" align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    invoices.map(
                                        (x, i) =>
                                            <TableRow key={i} className={x.cancelledDateTime != null && 'cancelled'}>
                                                <TableCell align="right">{x.invoiceNumberFormatted} </TableCell>
                                                <TableCell component="th" colSpan="3"><strong>{x.registrationNumber}</strong>
                                                    <br />
                                                    {x.description}
                                                    {x.releaseCode && x.releaseCode.length > 0 &&
                                                        <> <br /> <span style={{ color: "red" }}>
                                                            <strong>Release Code:</strong> {x.releaseCode}</span>
                                                        </>
                                                    }
                                                </TableCell>
                                                <TableCell align="right">£{formatMoney(x.totalAmount)}</TableCell>
                                                <TableCell align="right">{moment(x.generatedDateTime).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell align="right"> {x.paid ?
                                                    <span><FontAwesomeIcon icon={['fas', 'check']} /></span>
                                                    :
                                                    <span><FontAwesomeIcon icon={['fas', 'times']} /></span>
                                                }</TableCell>

                                                <TableCell align="right">
                                                    {x.sentToBuyer || x.cancelledDateTime == null ?
                                                        <>
                                                            <FontAwesomeIcon title="Open" onClick={() => openPdf(x.id)} className="pull-right ml-2 " icon={['fas', 'external-link']} style={{ fontSize: "0.9rem", color: "grey", cursor: "pointer" }} />
                                                            <FontAwesomeIcon title="Download" onClick={() => getInvoiceAndDownload(x.id)} className="pull-right ml-2" icon={['fas', 'arrow-to-bottom']} style={{ fontSize: "0.9rem", color: "grey", cursor: "pointer" }} />
                                                        </>
                                                        :

                                                        <span class="ml-2 badge badge-danger" style={{ fontSize: '0.8rem' }}>Void</span>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                    <div className="lot-card">
                        {
                            invoices.map(
                                (x, i) =>
                                    <div className="card" key={i}>
                                        <div className="card-body">
                                            <h5 className="card-title">{x.invoiceNumberFormatted} ({x.registrationNumber})</h5>
                                            <h6 className="card-subtitle">{x.description}</h6>
                                            <p className="card-text">Total Amount: £{formatMoney(x.totalAmount)}</p>
                                            <button className="btn btn-success" onClick={() => getInvoiceAndDownload(x.id)}>View</button>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                    <div className="col-12 text-center">
                        <p>Page {pageNumber} of {totalPages}</p>
                        <button disabled={pageNumber < 2} onClick={() => { handlePageChange(pageNumber - 1) }} className="btn btn-primary mr-4">Previous</button>
                        <button disabled={pageNumber === totalPages} onClick={() => { handlePageChange(pageNumber + 1) }} className="btn btn-primary">Next</button>
                    </div>
                </div>
            }

        </div>
    );
};

export default Invoices;