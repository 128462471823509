import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import NextAuctionCountdown from '../../../Shared/Common/NextAuctionCountdown';
import { formatMoney } from '../../../Shared/Utilities/FormatMoney';
import { apiGet } from './../../../Shared/Utilities/ApiFetch';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginTop: theme.spacing(2),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 375,
    },
    head: {
        border: 'none'
    }
}));

const SecuredLots = (props) => {
    const classes = useStyles();
    const [lots, setLots] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        getBidHistory(pageNumber);
    }, [pageNumber])

    const getBidHistory = (pageNum) => {
        apiGet(`/api/Account/UserLotsWon?pageNumber=${pageNum}`, true)
            .then(result => { setLots(result.body.lotsWon); setTotalPages(result.body.totalPages); });
    }

    const handlePageChange = pageNumber => {
        setPageNumber(pageNumber);
    }

    return (
        <div className={`col-12 ${classes.root}`}>
            {
                lots && lots.length ?
                    <div className="col-12">
                        <Paper className={`paper-table ${classes.paper}`}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="head-text">Vehicle</TableCell>
                                        <TableCell className="head-text" />
                                        <TableCell className="head-text" />
                                        <TableCell className="head-text" align="right">Sale price</TableCell>
                                        <TableCell className="head-text" align="right">Lot</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        lots.map(
                                            (x, i) =>
                                                <TableRow key={i}>
                                                    <TableCell component="th" colSpan="3">{x.vehicles[0].make} {x.vehicles[0].model} {x.vehicles[0].derivative} ({x.vehicles[0].registrationNumber})</TableCell>
                                                    <TableCell align="right">£{formatMoney(x.currentBid.amount)}</TableCell>
                                                    <TableCell align="right"><a target="_blank" rel="noopener noreferrer" href={`/AuctionVehicle/${x.auctionId}/${x.vehicles[0].id}`}><button className="btn btn-success">View lot</button></a> </TableCell>
                                                </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                        <div className="lot-card">
                            {
                                lots.map(
                                    (x, i) =>
                                        <div className="card" key={i}>
                                            <div className="card-body">
                                                <h5 className="card-title">{x.vehicles[0].make} {x.vehicles[0].model}</h5>
                                                <h6 className="card-subtitle">{x.vehicles[0].derivative} ({x.vehicles[0].registrationNumber})</h6>
                                                <p className="card-text">Sale price: £{formatMoney(x.currentBid.amount)}</p>
                                                <a target="_blank" rel="noopener noreferrer" href={`/vehicle/${x.vehicles[0].id}`}><button className="btn btn-success">View lot</button></a>
                                            </div>
                                        </div>
                                )
                            }
                        </div>
                        <div className="col-12 text-center">
                            <p>Page {pageNumber} of {totalPages}</p>
                            <button disabled={pageNumber < 2} onClick={() => { handlePageChange(pageNumber - 1) }} className="btn btn-primary mr-4">Previous</button>
                            <button disabled={pageNumber === totalPages} onClick={() => { handlePageChange(pageNumber + 1) }} className="btn btn-primary">Next</button>
                        </div>
                    </div>
                    :
                    <>
                        {!props.nextAuction &&
                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 mt-3 text-center">
                                <NextAuctionCountdown />
                            </div>
                        }
                        <div style={{ paddingTop: "6rem" }}>
                            <div className="col-12 text-center">
                                <p>You have not placed any bids</p>
                                <p className="vme-green">Start Bidding Now</p>
                            </div>
                        </div>
                    </>
            }

        </div>
    );
};

export default SecuredLots;