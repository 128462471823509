import React,{Component} from 'react';
import Modal from 'react-bootstrap4-modal';
class ReusableModal extends Component {
    render(){
        return(<Modal visible={this.props.showModal} dialogClassName={this.props.className}>
            <div className="modal-header">
                <h5 className="modal-title">{this.props.modalTitle}</h5>
            </div>
            <div className="modal-body">
                {this.props.modalContentBody()}
            </div>
            <div className="modal-footer">
                <button onClick={() => { this.props.onActionClick() }} className="btn btn-success">{this.props.actionName}</button>
                { this.props.cancelName &&
                    <button onClick={() => { this.props.onCancelClick() }} className="btn btn-secondary">{this.props.cancelName}</button>
                }
            </div>
        </Modal>);
    } 
}
export default ReusableModal;