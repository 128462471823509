import { jsPDF } from "jspdf";
import VMRLogo from '../../../Images/Invoice/NewVmLogo.PNG';
import moment from 'moment';
import { apiGet } from './../../../Shared/Utilities/ApiFetch';

export function getInvoiceAndDownload(id) {
    apiGet('/api/invoice/GetInvoiceForTradeMemberAccount/' + id, true)
        .then(result => {
            printPDF(result.body.invoice);
        })
        .catch(error => console.log(error));
}

export function openPdf(id) {
    apiGet('/api/invoice/GetInvoiceForTradeMemberAccount/' + id, true)
        .then(result => {
            printPDF(result.body.invoice, true);
        })
        .catch(error => console.log(error));
}

export function printPDF(invoiceData, open = false) {

    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(VMRLogo, 'JPEG', 6, 10, 80, 23);

    //Green invoice text
    pdf.setTextColor(31, 178, 89);
    pdf.setFontSize(22);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${invoiceData.isProForma ? 'PRO FORMA INVOICE' : 'INVOICE'}`, 200, 21, 'right');

    //main font
    pdf.setTextColor(88, 87, 87);
    pdf.setFontSize(10);

    //VMR Details - left
    pdf.setFont("helvetica", "bold");
    pdf.text(`Van Monster Remarketing`, 10, 40, 'left');
    pdf.setFont("helvetica", "normal");
    pdf.text(`23 Allington Way`, 10, 47, 'left');
    pdf.text(`Yarm Road Business Park`, 10, 53, 'left');
    pdf.text(`Darlington`, 10, 59, 'left');
    pdf.text(`DL1 4QB`, 10, 65, 'left');
    pdf.setFont("helvetica", "bold");
    pdf.text(`Tel: 01325 390 900`, 10, 73, 'left');

    //Right side
    pdf.setFont("helvetica", "bold");
    pdf.text(`INVOICE #`, 200, 30, 'right');
    pdf.setFont("helvetica", "normal");
    pdf.text(`${invoiceData.invoiceNumberFormatted}`, 200, 35, 'right');

    pdf.setFont("helvetica", "bold");
    pdf.text(`DATE`, 200, 45, 'right');
    pdf.setFont("helvetica", "normal");
    pdf.text(`${moment(invoiceData.generatedDateTime).format('DD/MM/YYYY')}`, 200, 50, 'right');

    pdf.setFont("helvetica", "bold");
    pdf.text(`BALANCE DUE`, 200, 60, 'right');
    pdf.setFont("helvetica", "normal");
    pdf.text(`£${invoiceData.totalAmount}`, 200, 65, 'right');

    //Bill to
    pdf.setFont("helvetica", "bold");
    pdf.text(`INVOICE TO:`, 10, 90, 'left');
    pdf.setLineWidth(0.25);
    pdf.setDrawColor(133, 133, 133);
    pdf.line(9, 92, 90, 92);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${invoiceData.tradingName}`, 10, 99, 'left');
    pdf.setFont("helvetica", "normal");
    pdf.text(`${invoiceData.invoiceFirstName} ${invoiceData.invoiceLastName}`, 10, 106, 'left');
    pdf.text(`${invoiceData.invoiceAddressLine1}`, 10, 112, 'left');
    pdf.text(`${invoiceData.invoiceAddressLine2 !== null ? invoiceData.invoiceAddressLine2 : invoiceData.invoiceTownCity}`, 10, 118, 'left');
    pdf.text(`${invoiceData.invoiceAddressLine2 !== null ? invoiceData.invoiceTownCity : invoiceData.invoiceCounty}`, 10, 124, 'left');
    pdf.text(`${invoiceData.invoiceAddressLine2 !== null ? invoiceData.invoiceCounty : invoiceData.invoicePostcode}`, 10, 130, 'left');
    pdf.text(`${invoiceData.invoiceAddressLine2 !== null ? invoiceData.invoicePostcode : ""}`, 10, 136, 'left');

    //Ship To
    pdf.setFont("helvetica", "bold");
    pdf.text(`DELIVERY TO:`, 110, 90, 'left');
    pdf.setLineWidth(0.25);
    pdf.setDrawColor(133, 133, 133);
    pdf.line(109, 92, 200, 92);
    pdf.setFont("helvetica", "bold");
    pdf.text(`${invoiceData.tradingName}`, 110, 99, 'left');
    pdf.setFont("helvetica", "normal");
    pdf.text(`${invoiceData.firstName} ${invoiceData.lastName}`, 110, 106, 'left');
    pdf.text(`${invoiceData.addressLine1}`, 110, 112, 'left');
    pdf.text(`${invoiceData.addressLine2 !== null ? invoiceData.addressLine2 : invoiceData.townCity}`, 110, 118, 'left');
    pdf.text(`${invoiceData.addressLine2 !== null ? invoiceData.townCity : invoiceData.county}`, 110, 124, 'left');
    pdf.text(`${invoiceData.addressLine2 !== null ? invoiceData.county : invoiceData.postcode}`, 110, 130, 'left');
    pdf.text(`${invoiceData.addressLine2 !== null ? invoiceData.postcode : ""}`, 110, 136, 'left');

    //Invoice Prices section
    pdf.setDrawColor(88, 87, 87);
    pdf.line(9, 145, 200, 145);
    pdf.setLineWidth(0.5);
    pdf.line(9, 153, 200, 153);

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(9);
    pdf.text(`DESCRIPTION`, 10, 150, 'left');

    if (invoiceData.vatRate === "VatInclusive") {
        pdf.text(`GROSS`, 140, 150, 'right');
        pdf.text(`VAT`, 165, 150, 'right');
    }
    pdf.text(`PRICE`, 200, 150, 'right');

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);

    var splitTitle = pdf.splitTextToSize(`${invoiceData.registrationNumber} ${invoiceData.description}`, 100);
    pdf.text(10, 162, splitTitle);
    if (invoiceData.vatRate === "VatInclusive") {
        pdf.text(`£${(invoiceData.vehiclePrice / 1.2).toFixed(2)}`, 140, 162, 'right');
        pdf.text(`£${(invoiceData.vehiclePrice - (invoiceData.vehiclePrice / 1.2)).toFixed(2)}`, 167, 162, 'right');
    }
    pdf.text(`£${invoiceData.vehiclePrice}`, 200, 162, 'right');

    var nextLine = 162 + (splitTitle.length > 1 ? splitTitle.length * 4 : 5);
    pdf.text(25, nextLine, `Mileage: ${invoiceData.mileage} ${invoiceData.mileageMiles ? "miles" : "km"}`);
    pdf.text(25, nextLine + 5, `Registration Date: ${moment(invoiceData.registrationDate).format('DD/MM/YYYY')}`);

    var nextLineCoord = nextLine + 14;

    if (invoiceData.buyersFee !== "0.00") {
        pdf.text(`Buyers Fee`, 10, nextLineCoord, 'left');
        pdf.text(`£${invoiceData.buyersFee}`, 200, nextLineCoord, 'right');
        nextLineCoord = nextLineCoord + 8;
    }
    if (invoiceData.assist40 !== "0.00") {
        pdf.text(`Assist40`, 10, nextLineCoord, 'left');
        pdf.text(`£${invoiceData.assist40}`, 200, nextLineCoord, 'right');
        nextLineCoord = nextLineCoord + 8;
    }
    if (invoiceData.fees !== "0.00") {
        pdf.text(`Additional Fees`, 10, nextLineCoord, 'left');
        pdf.text(`£${invoiceData.fees}`, 200, nextLineCoord, 'right');
        nextLineCoord = nextLineCoord + 8;
    }

    pdf.setLineWidth(0.25);
    pdf.line(9, nextLineCoord, 200, nextLineCoord);

    nextLineCoord = nextLineCoord + 8;

    var notesCoord = nextLineCoord;
    //notes section
    pdf.setFont("helvetica", "bold");
    pdf.text(`Please Note:`, 10, notesCoord, 'left');
    pdf.setFont("helvetica", "normal");
    pdf.text(`Payment due within 3 days`, 10, notesCoord + 5, 'left');
    pdf.text('', 10, notesCoord + 10, 'left');

    //Subtotal section
    pdf.text(`Total Vat`, 110, nextLineCoord, 'left');
    pdf.text(`£${invoiceData.totalVat}`, 200, nextLineCoord, 'right');

    var vatRate = invoiceData.vatRate === "PlusVat" ? '20%' : '0%';
    nextLineCoord = nextLineCoord + 8;
    pdf.text(`Vat Rate`, 110, nextLineCoord, 'left');
    pdf.text(`${vatRate}`, 200, nextLineCoord, 'right');

    if (invoiceData.deductions !== "0.00") {
        nextLineCoord = nextLineCoord + 8;
        pdf.text(`Deductions`, 110, nextLineCoord, 'left');
        pdf.text(`£${invoiceData.deductions}`, 200, nextLineCoord, 'right');
    }

    //Total
    nextLineCoord = nextLineCoord + 5;
    pdf.setLineWidth(0.5);
    pdf.setFontSize(11);
    pdf.line(109, nextLineCoord, 200, nextLineCoord);
    nextLineCoord = nextLineCoord + 7;
    pdf.setFont("helvetica", "bold");
    pdf.text(`Total Due`, 110, nextLineCoord, 'left');
    pdf.text(`£${invoiceData.totalAmount}`, 200, nextLineCoord, 'right');
    nextLineCoord = nextLineCoord + 5;
    pdf.line(109, nextLineCoord, 200, nextLineCoord);

    //Bottom text
    pdf.setFont("helvetica", "bold");
    pdf.text('Thank you for your business', 10, 265, 'left');
    nextLineCoord = 274;
    pdf.setFontSize(9);
    pdf.setFont("helvetica", "normal");
    pdf.text(`Northgate Vehicle Sales Limited t/a Van Monster`, 10, nextLineCoord, 'left');
    pdf.text(`Registered Office: Northgate Centre, Lingfield Way, Darlington, DL1 4PZ`, 10, nextLineCoord + 5, 'left');
    pdf.text('Registered in England No: 02337128', 10, nextLineCoord + 10, 'left');
    pdf.text('VAT Registration No. GB244223002', 10, nextLineCoord + 15, 'left');

    if (open) {
        pdf.output('dataurlnewwindow', `${invoiceData.invoiceNumberFormatted}-(${invoiceData.registrationNumber})`);
    } else {
        pdf.save(`${invoiceData.invoiceNumberFormatted}-(${invoiceData.registrationNumber})`);
    }
}