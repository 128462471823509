const cookieConsentName = 'cookieConsent';
export function hasConsented()
{
    var hasAccepted = getCookie(cookieConsentName);
    return hasAccepted === "true";
}
export function cookieContains(cookieName,contains)
{
    var containsData = false
    var cookieData = getCookie(cookieName);
    var data = cookieData.split(',');
    if(data!== null)
    {
        containsData = data.some(a=> a === contains);
    }
    return containsData;
}
export function addCookie(cookieName, content, expiryTimeInMins)
{
    var d = new Date();
    //d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000)); // 1 week
    d.setTime(d.getTime() + expiryTimeInMins * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + content + ";" + expires + ";path=/";
}
export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}