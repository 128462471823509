import React from 'react';
import { isAllowed } from '../../../../Shared/Utilities/AuthUtilities';

const LotCardBadges = (props) => {
    return (
        <div className="col-md-4 col-sm-6 col-6 order-sm-2 order-2 pl-0">
            <div className="">
                {
                    isAllowed('Buyer') &&
                    props.lotState.showBidNow &&
                    props.lotState.sold === null &&
                    <>
                        {
                            (props.lotState.bidAmount !== null && props.lotState.reserveMet) ?
                                <p className="text-right"><span className="badge badge-warning">ON SALE</span></p>
                                :
                                <>
                                    {
                                        !props.lotState.reserve ?
                                            <p className="text-right"><span className="badge badge-info">NO RESERVE</span></p>
                                            :
                                            !props.lotState.reserveMet &&
                                            props.lotState.bidAmount !== null &&
                                            <p className="text-right"><span className="badge badge-info">RESERVE NOT MET</span></p>
                                    }
                                </>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default LotCardBadges;