import React from 'react';
import ImageMapper from 'react-image-mapper';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const DamageTab = (props) => {
    return (
        <React.Fragment>
            {props.damageLightBoxIsOpen &&
                <Lightbox
                    key={props.damageImage.image}
                    animationOnKeyInput={true}
                    mainSrc={props.damageImage.uri.concat(props.damageImage.name)}
                    onCloseRequest={props.toggleDamageLightBox}
                    imageCaption={props.damageImage.imageDescription}
                />
            }
            <div className={`d-block`} id={props.vd.id} role="tabpanel" >
                <div className="form-row">
                    <div className="rounded mx-auto d-block">
                        <ImageMapper
                            map={props.damageGetMapArea(props.vd.id)}
                            src={props.vd.image.uri.concat(props.vd.image.name)}
                            onMouseEnter={area => props.damageDiagramEnterArea(area)}
                            // onMouseLeave={area => props.damageDiagramLeaveArea(area)}
                            alt={props.vd.description + " " + props.vd.location}
                            width={props.damageImgWidth}
                            imgWidth={420}
                            _id={props.vd.id}
                            onClick={area => props.handleDamageAreaClick(area)}
                        />
                    </div>
                </div>
                {props.vehicle.damage.length > 0 &&
                    <div className="col-md-8 offset-md-2 mt-3 text-center">
                        <p>Click a circle on the diagram to see an image of the damage.</p>
                    </div>
                }
                <div className="col-md-8 px-0 offset-md-2 mt-3">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Area</th>
                                <th>Location</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.vehicle.damage.map(d =>
                                    <tr key={d.id}>
                                        <td>{d.diagram}</td>
                                        <td>{d.location}</td>
                                        <td>{d.description}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DamageTab;