import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatMoney } from '../../../../Shared/Utilities/FormatMoney';
import Button from '../../../../Shared/Common/Button';

const BuyNowModal = (props) => {
    return (
        <Modal visible={props.showBuyItNowModal}>
        <div className="modal-header">
            <h5 className="modal-title">Buy now</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={() => props.handleBuyItNowModalClick()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            {
                props.buyItNowSuccess ?
                    <>
                        <p className="text-center"><FontAwesomeIcon icon={['fas', 'check']} className="bid-icon" /></p>
                        <p className="text-center">{props.buyItNowSuccess}</p>
                    </>
                    :
                    <>
                        <p>You are about to buy {props.vehicle.registrationNumber} for £{formatMoney(props.lot.buyItNowPrice)} {props.vehicle.vatDescription}</p>
                        <p>{props.buyItNowError}</p>
                    </>
            }
        </div>
        <div className="modal-footer">
            {
                props.buyItNowSuccess ?
                    <button type="button" className="btn btn-outline-success" onClick={() => props.handleBuyItNowModalClick()}>Close</button>
                    :
                    <>
                        <Button buttonText="Cancel" theme={props.theme} divClass="btn btn-danger" buttonType={props.theme.btnDanger} buttonTypeHover={props.theme.btnDangerHover} clickFunc={() => props.handleBuyItNowModalClick()} />

                        {!props.disableBuyNowButton ?
                            <button type="button" className="btn btn-success" onClick={() => props.buyItNow(props.id)}>Confirm</button>
                            :
                            <button type="button" className="btn btn-primary " disabled={true} >Processing ...</button>
                        }
                    </>
            }
        </div>
    </Modal>
    );
};

export default BuyNowModal;