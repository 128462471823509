import React, { Component } from 'react';
import Logo from '../../Images/VM_Remarketing-NoTagline_Logo_CMYK.png';
import './Login.scss'
import { Link } from 'react-router-dom';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="my-5 light-grey-box">
                    <div className="p-3">
                        <div className="d-flex justify-content-center">
                            <img src={Logo} className="login-logo img-fluid" alt="VmRemarketing" />
                        </div>
                        <div className="form-signin">
                            <div className="form-group">
                                {/* {this.props.response !== null &&
                                        <p className="hasErrors">{this.props.response.message}</p>
                                        } */}
                                <label>Email address</label>
                                <input autoComplete="off" onChange={this.props.handleChange} type="email" name="username" id="username" className="form-control" placeholder="Email address" required />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input autoComplete="off" onChange={this.props.handleChange} type="password" id="password" name="password" className="form-control" placeholder="Password" required />
                            </div>
                            <Link className="forgotPassword text-center" to="/ForgotPassword">Forgot your password?</Link>

                            <div className="form-group text-center mt-2">
                                {this.props.loginError &&
                                    <p className="error text-bold">{this.props.loginResponseMessage}</p>
                                }
                            </div>
                            <button className="btn btn-success btn-block" onClick={this.props.handleLogin}>Log in</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;