import React from 'react';
import { Link } from 'react-router-dom';
import { formatMoney } from '../../../Shared/Utilities/FormatMoney';

const LotsWon = (props) => {
    return (
        <table className="table">
        <thead>
            <tr>
                <th>Registration Number</th>
                <th>Make</th>
                <th>Model</th>
                <th>Price</th>
                <th>Lot</th>
                <th>Invoice</th>
            </tr>
        </thead>
        <tbody>
            {props.lots.map(
                (x, i) =>
                    <tr key={i}>
                        <td>{x.vehicles[0].registrationNumber}</td>
                        <td>{x.vehicles[0].make}</td>
                        <td>{x.vehicles[0].model}</td>
                        <td>£{formatMoney(x.currentBid.amount)}</td>
                        <td><Link to={`/vehicle/${x.vehicles[0].id}`}><button className="btn btn-primary">View lot</button></Link></td>
                        <td><Link to={`/vehicle/${x.vehicles[0].id}`}><button className="btn btn-primary">View invoice</button></Link></td>
                    </tr>
            )}

        </tbody>

    </table>
    );
};

export default LotsWon;