import React, { Component } from 'react';

class ThankYou extends Component {
    render() {
        return (
            <div>
                <h1>Thank you for registering!</h1>
                <p>You can now log in to your account below using the email and password you provided</p>
            </div>
        );
    }
}

export default ThankYou;