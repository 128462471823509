import React, { Component } from 'react';
import Timer from 'react-compound-timer';
import { Link } from 'react-router-dom';
import './Styles/NextAuctionCountdown.css';
import { apiGet } from '../Utilities/ApiFetch';

const Countdown = (props) => {
    return (
        <>
            <Timer
                initialTime={props.starts}
                lastUnit={props.startDays ? "d" : "h"}
                direction="backward"
                timeToUpdate="0"
                checkpoints={[
                    {
                        time: 0,
                        callback: () => { props.handleAuctionStart(); },
                    },
                    {
                        time: 86400000,
                        callback: () => { props.handleDays("start") }
                    }
                ]}
            >
                {() => (
                    <>
                        <div className='title'>
                            <p className="mt-2 countdown-head">{props.name} </p>
                            <p className="countdown-subhead">{props.description} </p>
                        </div>
                        <div className="col-12 coming-soon">
                            <div className="next-countdown">
                                <div className="text-center ctdwn-title"><p>{props.title}</p></div>
                                <div className="ctdown">
                                    {props.startDays &&
                                        <>
                                            <div className="col time-box"><p><Timer.Days /></p> <span>DAYS</span></div>
                                            <div className="separator">:</div>
                                        </>
                                    }
                                    <div className="col time-box"><p><Timer.Hours /></p> <span>HOURS</span></div>
                                    <div className="separator">:</div>
                                    <div className="col time-box"><p><Timer.Minutes /></p> <span>MINUTES</span></div>
                                    <div className="separator">:</div>
                                    <div className="col time-box"><p><Timer.Seconds /></p> <span>SECONDS</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-3 py-3">
                            <Link to={`/Auction/${props.url}`}><button className="btn btn-success">View Auction</button></Link>
                        </div>
                    </>
                )}
            </Timer>
        </>
    )
}
const EndCountdown = (props) => {
    return (
        <>
            <Timer
                initialTime={props.ends}
                lastUnit={props.endDays ? "d" : "h"}
                direction="backward"
                timeToUpdate="0"
                checkpoints={[
                    {
                        time: 0,
                        callback: () => { props.handleAuctionEnd() },
                    },
                    {
                        time: 86400000,
                        callback: () => { props.handleDays("end") }
                    }
                ]}
            >
                {() => (
                    <>
                        <div className='title'>
                            <p className="mt-2 countdown-head">{props.name} </p>
                            <p className="countdown-subhead">{props.description} </p>
                        </div>
                        <div className="col-12 coming-soon">
                            <div className="next-countdown">
                                <div className="text-center ctdwn-title"><p>{props.title}</p></div>
                                <div className="ctdown">
                                    {props.endDays &&
                                        <>
                                            <div className="col time-box"><p><Timer.Days /></p> <span>DAYS</span></div>
                                            <div className="separator">:</div>
                                        </>
                                    }
                                    <div className="col time-box"><p><Timer.Hours /></p> <span>HOURS</span></div>
                                    <div className="separator">:</div>
                                    <div className="col time-box"><p><Timer.Minutes /></p> <span>MINUTES</span></div>
                                    <div className="separator">:</div>
                                    <div className="col time-box"><p><Timer.Seconds /></p> <span>SECONDS</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-3 py-3">
                            <Link to={`/Auction/${props.url}`}><button className="btn btn-success">View Auction</button></Link>
                        </div>
                    </>
                )}
            </Timer>
        </>
    )
}


const AuctionEnded = (props) => {
    return (
        <>
            <div className='title'>
                <p className="mt-2 countdown-head">{props.name} </p>
                <p className="countdown-subhead">{props.description} </p>
            </div>
            <div className="col-12 coming-soon">
                <div className="next-countdown">
                    <div className="text-center ctdwn-title"><p>{props.title}</p></div>
                    <div className="ctdown">
                        <p className="ended-p">This auction has ended</p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6 offset-lg-3 py-3">
                <Link to={`/Auction/${props.url}`}><button className="btn btn-success">View Auction</button></Link>
            </div>
        </>
    )
}

class AuctionCountdown extends Component {
    constructor(props) {
        super(props);
        this.state = { nextAuction: {} };
    }

    componentDidMount() {
        this.fetchNextAuction();
    }

    fetchNextAuction = () => {
        this.setState({ componentState: "loading" });
        apiGet('/api/auction/nextauction', true)
            .then((result) => {
                if (result.body !== null) {
                    this.setState({
                        nextAuction: result.body,
                        componentState: result.body.startMilliSeconds === 0 ? "endCountdown" : "countdown",
                        endDays: result.body.endMilliSeconds > 86400000 ? true : false,
                        startDays: result.body.startMilliSeconds > 86400000 ? true : false
                    })
                }
                else {
                    this.setState({ componentState: "ended" })
                }
            })
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    handleDays = (days) => {
        days === "end" ? this.setState({ endDays: false }) : this.setState({ startDays: false })
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "countdown":
                return <Countdown
                    starts={this.state.nextAuction.startMilliSeconds}
                    handleAuctionStart={this.fetchNextAuction}
                    handleDays={this.handleDays}
                    title="AUCTION STARTS IN"
                    startDays={this.state.startDays}
                    name={this.state.nextAuction.name}
                    description={this.state.nextAuction.description}
                    url={this.state.nextAuction.id}
                />;
            case "endCountdown":
                return <EndCountdown
                    ends={this.state.nextAuction.endMilliSeconds}
                    handleDays={this.handleDays}
                    handleAuctionEnd={this.fetchNextAuction}
                    title="AUCTION ENDS IN"
                    endDays={this.state.endDays}
                    name={this.state.nextAuction.name}
                    description={this.state.nextAuction.description}
                    url={this.state.nextAuction.id}
                />;
            case "ended":
                return <AuctionEnded
                    name={this.state.nextAuction !== null ? this.state.nextAuction.name : 'Ended'}
                    description={this.state.nextAuction !== null ? this.state.nextAuction.description : 'Ended'}
                    url={this.state.nextAuction !== null ? this.state.nextAuction.id : null} />;
            default:
                return null
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default AuctionCountdown;