import {getCookie,cookieContains,hasConsented,addCookie} from '../Utilities/CookieUtilities';
import { apiPost } from '../Utilities/ApiFetch';
import { isLoggedIn } from '../Utilities/AuthUtilities';
const auctionTrackingCookieName = "auction";

export function TrackView(auctionId)
{
    if(isLoggedIn())
    {
        if(hasConsented())
        {
            if (!ViewTracked(auctionId))
            {
                addAuctionToTrackingCookie(auctionId);
                trackAuctionView(auctionId);
            }
        }
    }
    
    return true;
}
export function ViewTracked(auctionId)
{
    var viewTracked = false;
    var auctionCookie = getCookie(auctionTrackingCookieName);
    if(auctionCookie !=null)
    {
        viewTracked = cookieContains(auctionTrackingCookieName,auctionId);
    }
    return viewTracked;
}

function addAuctionToTrackingCookie(auctionId)
{
    var auctionCookie = getCookie(auctionTrackingCookieName);
    var dataToAdd = auctionCookie!= null ? ',' + auctionId : auctionId;
    auctionCookie += dataToAdd;    
    addCookie(auctionTrackingCookieName, auctionCookie, 10080);
}
function trackAuctionView(auctionId)
{
    console.log('auctionId: ' +auctionId);
    apiPost('/api/tracking/auctionview?auctionId=' + auctionId, true);
}