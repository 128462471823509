import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReusableModal from './ReusableModal'
import { apiGet, apiPost } from '../../Shared/Utilities/ApiFetch'
import DynamicContent from '../../Containers/Home/DynamicContent'
const TermsAndConditionsKeyword = 'terms-and-conditions'

class TermsAndConditionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTermsAndConditionsModal: false,
            showTermsAndConditions: false
        }
        this.modalTitle = "Terms and Conditions Updated";
        this.actionButtonText = "Agree";
        this.showTermsAndConditionsModal = this.showTermsAndConditionsModal.bind(this);
        this.toggleShowTermsAndConditions = this.toggleShowTermsAndConditions.bind(this);
        this.modalContentBody = this.modalContentBody.bind(this);
        this.termsAndCondtionsCheckUrl = '/api/Account/HasAcceptedLatestTermsAndConditions';
        this.acceptTermsAndConditionsUrl = '/api/Account/AcceptTermsAndConditions';
    }
    componentDidMount() {
        this.checkTermsAndConditions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.checkTermsAndConditions();
        }
    }

    showTermsAndConditionsModal(value) {
        this.setState({ showTermsAndConditionsModal: value });
    }

    showTermsAndConditions() {
        this.setState({ showTermsAndConditions: true });
    }

    haventCheckedTermsAndConditions() {
        return !this.props.haveCheckedTermsAndConditions;
    }

    userIsLoggedIn() {
        return this.props.user != null;
    }

    checkTermsAndConditions() {
        if (this.haventCheckedTermsAndConditions() && this.userIsLoggedIn()) {
            this.userTermsAndConditionsCheck(this.props.user);
            this.props.setHaveCheckedTermsAndConditions(true);
        }
    }

    userTermsAndConditionsCheck(user) {

        return apiGet(`${this.termsAndCondtionsCheckUrl}?userId=${user.id}`, true)
            .then(result => {
                if (result.body === false) {
                    this.showTermsAndConditionsModal(true);
                }
            }).catch(error => {
                this.showTermsAndConditionsModal(false);
            });
    }

    toggleShowTermsAndConditions() {
        this.setState({ showTermsAndConditions: !this.state.showTermsAndConditions });
    }

    acceptTermsAndConditions() {
        let successful = false;
        let data = { userId: this.props.user.id };
        apiPost(`${this.acceptTermsAndConditionsUrl}`, true, data)
            .then(result => {
                successful = result.body.successful;
            })
            .finally(error => {
                this.showTermsAndConditionsModal(false);
            });
        return successful;
    }

    modalContentBody() {
        return (
            <>
                <p>We have recently updated our terms and conditions.</p>
                <p>These updates apply to everyone using the Van Monster Remarketing Platform.</p>
                <p>We recommend that you review our <Link onClick={() => { this.toggleShowTermsAndConditions() }} to="#">Terms and Conditions</Link> carefully.</p>
                {this.state.showTermsAndConditions &&
                    <div className={"border border-grey border-left-0 border-right-0"}>
                        <DynamicContent keyword={TermsAndConditionsKeyword} />
                    </div>
                }
                <p>If you agree, click the agree button below to continue to use our site.</p>
            </>
        );
    }

    render() {
        return (<ReusableModal className="modal-lg" showModal={this.state.showTermsAndConditionsModal} modalTitle={this.modalTitle} modalContentBody={this.modalContentBody} onActionClick={this.acceptTermsAndConditions} actionName={this.actionButtonText} showTermsAndConditionsModal={this.showTermsAndConditionsModal} props={this.props} acceptTermsAndConditionsUrl={this.acceptTermsAndConditionsUrl} />
        );
    }
}
export default TermsAndConditionsModal;