import React from 'react';
import { formatMoney } from '../../../../Shared/Utilities/FormatMoney';
import Button from '../../../../Shared/Common/Button';

const LotCardPricing = (props) => {
    return (
        <>
            {
                props.lotState.sold === true ?
                    <div className="col mt-auto d-flex flex-column ended">
                        <h3 className="text-right sold-lot">SOLD</h3>
                    </div>
                    :
                    (
                        props.lotState.sold === false ?
                            <div className="col mt-auto d-flex flex-column ended">
                                <h3 className="text-right closed-lot">CLOSED</h3>
                            </div>
                            :
                            <>
                                <div className="col-md-4 col-6 d-flex flex-column cta-container">
                                    <div className="mt-auto">
                                        {
                                            props.lotState.showBuyItNow &&
                                            (props.extendedBiddingState === false || props.extendedBiddingCanBid) &&
                                            <>
                                                <p className="bid-title card-text">Buy now: </p>
                                                <div className="buynow-price text-right">
                                                    <p><strong>£</strong></p><h4>{formatMoney(props.lot.buyItNowPrice)}</h4>
                                                    <p className="vat-price"> {props.vehicle.vatDescription}</p>
                                                </div>
                                                <div className="mt-auto">
                                                    <Button buttonText="Buy Now" theme={props.theme} divClass="btn btn-primary btn-block my-2" clickFunc={props.handleBuyItNowModalClick} buttonType={props.theme.btnPrimary} buttonTypeHover={props.theme.btnPrimaryHover} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 d-flex flex-column cta-container">
                                    {
                                        props.lotState.showPricing ?
                                            (props.lotState.bidAmount !== null ?
                                                <>
                                                    <p className="bid-title card-text">{props.lotState.sold === null ? "Current bid:" : "Highest bid:"}</p>
                                                    <div className={`bid-price text-right ${props.bidAlertClass}`}>
                                                        <p><strong>£</strong></p><h4>{formatMoney(props.lotState.bidAmount)}</h4>
                                                        <p className="vat-price"> {props.vehicle.vatDescription}</p>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <p className="bid-title card-text">Start price:</p>
                                                    <div className="bid-price text-right">
                                                        <p><strong>£</strong></p><h4 className="start-price">{formatMoney(props.lot.startPrice)}</h4>
                                                        <p className="vat-price"> {props.vehicle.vatDescription}</p>
                                                    </div>
                                                </>
                                            )
                                            :
                                            <>
                                                {props.lotState.state === 3 && props.lotState.proxyBidders.includes(props.userId) ?
                                                    <p className="bid-title card-text">Proxy bid accepted</p>
                                                    :
                                                    <p className="bid-title card-text">&nbsp;</p>
                                                }
                                                <div className="bid-price text-right">
                                                    <h4 className="start-price">&nbsp;</h4>
                                                </div>
                                            </>
                                    }

                                    {
                                        props.lotState.showBidNow &&
                                        (props.extendedBiddingState === false || props.extendedBiddingCanBid) &&
                                        <Button buttonText="Bid Now" theme={props.theme} clickFunc={props.handleBidNowModalClick} divClass="btn btn-success btn-block my-2" buttonType={props.theme.btnSuccess} buttonTypeHover={props.theme.btnSuccessHover} />
                                    }
                                </div>
                            </>
                    )
            }
        </>
    );
};

export default LotCardPricing;