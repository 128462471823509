import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import './CarouselLayout.scss';


const CarouselLayout = ({ subheading, heading, image, text, rowReverse, buttonText, buttonUrl, isDealOfTheWeek, regNo, make, model, derivative, year, auctionName, isComingSoon, auctionDate, auctionStartTime, auctionEndTime }) => {

    return (
        <section className={`row no-gutters text-image ${image ? '' : 'no-image'}`} >
            <div className={`container container-flex`} style={{ minHeight: '350px' }}>
                <div className={`text-image-container no-gutters section-flex`}>
                    <div className={`col-12 ${image ? 'col-md-6' : 'col-12'} pr-0 pr-md-5`}>
                        <div className={`${image ? 'text' : 'center-text'}`}>
                            {isComingSoon &&
                                <span className='badge badge-warning'>Coming Soon</span>
                            }
                            <h2>{heading}</h2>
                            <h5>{subheading}</h5>
                            <div>
                                <p>{text}</p>
                            </div>
                            {isDealOfTheWeek &&
                                <div className=''>
                                    <h4>{auctionName}</h4>
                                        <h4>
                                            {auctionDate && <>{moment(auctionDate).format('MMMM Do')}</>}
                                            {!isComingSoon && <> {auctionStartTime} - {auctionEndTime}</>}
                                        </h4>
                                </div>
                            }
                        </div>
                        {buttonText && buttonUrl &&
                            <div className="text-center text-md-left pt-3">
                                <Link className='btn btn-success' to={buttonUrl}>{buttonText}</Link>
                            </div>
                        }
                    </div>
                    {image &&
                        <div className={`col-12 col-md-6 text-center image`}>
                            {isDealOfTheWeek &&
                                <>
                                    <h4 style={{ fontWeight: 'bold' }}>{year} {make} {model} </h4>
                                    <h5 style={{ color: '#20a64b' }}>{derivative} </h5>
                                </>
                            }
                            <div className='image-container'>
                                <img src={image} alt="" />
                                <span className='badge badge-warning reg-badge'>{regNo}</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default CarouselLayout;