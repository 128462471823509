/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import Assist40Logo from "../../Images/assist40.png";
import './Styles/About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Assist40Example from "../../Images/Assist40_example.pdf";
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../../Shared/Utilities/AuthUtilities';

const Services = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 grey-box my-4 text-center">
                    <h1 className="text-center mt-5">Our Services</h1>
                    <p className="mb-5">What we offer</p>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12 col-md-8 d-flex align-items-center mt-3">
                    <div>
                        <p>We’ve put our decades of experience in used van sales into creating a simple checklist that gives potential buyers the information they need to make a purchasing decision and allows sellers to declare any known defects. </p>
                        <p><Link to={Assist40Example} target="_blank" rel="noopener noreferrer">Click here to view a completed example Assist40</Link></p>
                    </div>
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
                    <img className="d-block w-lg-75 w-md-100 w-75 px-4" src={Assist40Logo} alt="Assist40" />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <div className="row row-eq-height mt-5">
                        <div className="col-sm-12 col-lg-4 mb-3">
                            <div className="services border p-3 h-100 rounded text-center">
                                <FontAwesomeIcon icon={['fal', 'user-headset']} className="services-icon" />
                                <div className="mt-3">
                                    {!isLoggedIn() ?
                                        <h4>Account Manager</h4>
                                        :
                                        <Link to="/MyAccount"><h4 className="link">Account Manager</h4></Link>
                                    }
                                    <p>Team of five dedicated specialists on hand to answer your queries.</p>
                                </div>
                            </div>
                        </div>
                        {props.buyNowId !== '' &&
                            <div className="col-sm-12 col-lg-4 mb-3">
                                <div className="services border p-3 h-100 rounded text-center">
                                    <FontAwesomeIcon icon={['fal', 'pound-sign']} className="services-icon" />
                                    <div className="mt-3">
                                        <h4>Buy Now</h4>
                                        <p>Vehicles available to buy 24/7</p>
                                        <p><a href="/BuyNow">Enter Buy Now Auction</a></p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-sm-12 col-lg-4 mb-3 rounded text-center">
                            <div className="services border p-3 h-100 rounded">
                                <FontAwesomeIcon icon={['fal', 'warehouse']} className="services-icon" />
                                <div className="mt-3">
                                    <h4>Storage & Reconditioning Options</h4>
                                    <p>for more information call your account manager.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;