/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import './Styles/Sell.scss';
import Sell from '../../Images/Sell/SellBanner.jpg';
import SellMob from '../../Images/Sell/SellMobBanner.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const SellPage = (props) => {
    return (
        <div className="container-fluid content">
            <div className="container">
                <div className="row">
                    <img src={Sell} alt="Sell" className="w-100 d-none d-md-block" />
                    <img src={SellMob} alt="Sell" className="w-100 d-block d-md-none" />
                </div>
                <div className="row my-5 d-flex justify-content-center align-items-center">
                    {props.user === null ?
                        <div className='col-12 col-md-4'>
                            <div className='card p-4 register-card'>
                                <h5>Want to sell your vehicles?</h5>
                                <p>To get started, register with us and start selling your vehicles with ease! </p>
                                <div>
                                    <button className="btn px-4 float-right">
                                        <Link to="/register">Register Now</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-12 col-md-4'>
                            <div className='card p-4 register-card'>
                                <h5>Want to sell your vehicles?</h5>
                                <p>If you are already registered as a Trade Member, get in touch with your TSE for more details. </p>
                            </div>
                        </div>
                    }                    <div className='col-12 col-md-8'>
                        <p className="text-center m-0">Our Van Monster Remarketing App takes the time, cost and hassle out of selling vans you no longer need. The App has been designed specifically to help our customers to sell vehicles easily and conveniently nationwide without leaving their premises.</p>
                    </div>
                </div>
                <div className="row sell">
                    <div className="col-12">
                        <div className="">
                            <div className="light-grey-box">
                                <ul className="fa-ul my-3">
                                    <li data-text="Time">
                                        <p>Easily upload images of the vehicles you want to sell to the Van Monster Remarketing App. The App will suggest allowances for damage to give you the most accurate price vs CAP.</p>
                                    </li>
                                    <li data-text="Cost">
                                        <p>Transparent fees and no movement costs as the vans stay with you until they are sold.</p>
                                    </li>
                                    <li data-text="No Hassle">
                                        <p>There is no need to take the van to a physical auction. We can arrange collection once it’s sold, you can do all this without leaving your desk!</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-features">
                    <div className="amazing_feature">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center heading-main">
                                    <h4 className="mb-5">Key Features &amp; Benefits</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                                    <div className="single_feature">
                                        <div className="feature_icon"><FontAwesomeIcon icon={['fal', 'hand-holding-box']} /></div>
                                        <h3>Trusted Traders</h3>
                                        <p>You'll have hundreds of potential buyers at your fingertips thanks to our strong nationwide motor trade relationships.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                                    <div className="single_feature">
                                        <div className="feature_icon"><FontAwesomeIcon icon={['fal', 'cloud-upload']} /></div>
                                        <h3>Intuitive Upload</h3>
                                        <p>Upload images and details of your fleet. We even estimate the effect any damage may have to provide a realistic value. </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                                    <div className="single_feature">
                                        <div className="feature_icon"><FontAwesomeIcon icon={['fal', 'file-chart-line']} /></div>
                                        <h3>Detailed Reports</h3>
                                        <p>Our 'Assist 40' appraisal provides a no-nonsense report to notify buyers of any pre-existing mechanical issues.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                                    <div className="single_feature">
                                        <div className="feature_icon"><FontAwesomeIcon icon={['fal', 'gavel']} /></div>

                                        <h3>Tailored Auctions</h3>
                                        <p>Unlike traditional auctions, our sales aren't limited in the same way as other sites are. You can also choose to sell alongside other vendors e.g. Northgate, or even build your own timed/branded sale.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                                    <div className="single_feature">
                                        <div className="feature_icon"><FontAwesomeIcon icon={['fal', 'pound-sign']} /></div>
                                        <h3>No Fuss</h3>
                                        <p>We endeavour to pay you within 72 hours of securing a sale. We can even settle any outstanding finance agreements for you as part of the process.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                                    <div className="single_feature">
                                        <div className="feature_icon"><FontAwesomeIcon icon={['fal', 'user-headset']} /></div>
                                        <h3>Customer Support</h3>
                                        <p> We've created a dedicated team who specialise in providing the help and support you might need. We are available to every customer no matter what your fleet size.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="steps steps-area mt-5">
                    <h4 className="text-center pb-4">How does it work?</h4>
                    <ul className="timeline">
                        <li className="timeline-box wow fadeInLeft">
                            <i className="icon-layers"></i>
                            <p className="timeline-title">STEP 1</p>
                            <p className="timeline-details">Download the Van Monster Remarketing App from the App Store and register.</p>
                            <span>1</span>
                        </li>
                        <li className="timeline-box wow fadeInRight">
                            <i className="icon-chat"></i>
                            <p className="timeline-title">STEP 2</p>
                            <p className="timeline-details">Upload the vehicle images and specifications.</p>
                            <span>2</span>
                        </li>
                        <li className="timeline-box  wow fadeInLeft">
                            <i className="icon-tools"></i>
                            <p className="timeline-title">STEP 3</p>
                            <p className="timeline-details">Decide on the format of your auction. We will help you to value your fleet and can advise on potential sales prices and reserve figures that you might ask to be met.</p>
                            <span>3</span>
                        </li>
                        <li className="timeline-box wow fadeInRight">
                            <i className="icon-trophy"></i>
                            <p className="timeline-title">STEP 4</p>
                            <p className="timeline-details">You can decide for how long you want your auction to run, our team will be happy to discuss the options that will work best for you.</p>
                            <span>4</span>
                        </li>
                        <li className="timeline-box  wow fadeInLeft">
                            <i className="icon-tools"></i>
                            <p className="timeline-title">STEP 5</p>
                            <p className="timeline-details">When the auction has finished, we will send you a post-sale report telling you what has been sold and how much for.</p>
                            <span>5</span>
                        </li>
                    </ul>
                </div>


                <div className="row my-5">
                    <div className="col-12">
                        <div className="light-grey-box text-center p-5">
                            To find out more about how we can help sell your vans quickly and conveniently or to get a valuation on any vehicles that you may want to sell in the future, contact one of our specialist team today <a href={"mailto:vendor.vm@vanmonsterremarketing.com"}>vendor.vm@vanmonsterremarketing.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellPage;