import React from 'react';
import { apiPostFile } from '../../Shared/Utilities/ApiFetch';
import Error from '../../Shared/Common/Error.js';
import Loading from '../../Shared/Common/Loading.js';
import './Register.scss';
import { Link } from 'react-router-dom';

const PageContent = (props) => {
    return (
        <>
            <div className="container content">
                <div className="row">
                    <div className="col-12 my-3">
                        <div className="row register form-container mx-0 mt-4">
                            <div className="col">
                                <div className="mt-2">
                                    <h4>Register</h4>
                                </div>
                            </div>
                            <div className="col-12">
                                {props.message && <strong><p className="errorMessage">{props.message}</p></strong>}
                                <div className="form-row">
                                    <div className="col-lg-6 col-sm-12">
                                        <label>Trading Name*</label>
                                        <input type="text" id="tradingName" name="tradingName" className="form-control" onChange={props.handleChange} />
                                        <p id="TradingNameError" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <label>First Name*</label>
                                        <input type="text" id="firstName" name="firstName" className="form-control" onChange={props.handleChange} />
                                        <p id="FirstNameError" className="errorMessage"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>Last Name*</label>
                                        <input type="text" id="lastName" name="lastName" className="form-control" onChange={props.handleChange} />
                                        <p id="LastNameError" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>Email*</label>
                                        <input type="text" id="email" name="email" className="form-control" onChange={props.handleChange} />
                                        <p id="EmailError" className="errorMessage"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>Phone*</label>
                                        <input type="text" id="phone" name="phone" className="form-control" onChange={props.handleChange} />
                                        <p id="PhoneNumberError" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>Address Line 1*</label>
                                        <input type="text" id="addressLine1" name="addressLine1" className="form-control" onChange={props.handleChange} />
                                        <p id="AddressLine1Error" className="errorMessage"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>Address Line 2</label>
                                        <input type="text" id="addressLine2" name="addressLine2" className="form-control" onChange={props.handleChange} />
                                        <p></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>Town or City</label>
                                        <input type="text" id="townCity" name="townCity" className="form-control" onChange={props.handleChange} />
                                        <p id="TownCityError" className="errorMessage"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>County*</label>
                                        <input type="text" id="county" name="county" className="form-control" onChange={props.handleChange} />
                                        <p id="CountyError" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>Postcode*</label>
                                        <input type="text" id="postcode" name="postcode" className="form-control" onChange={props.handleChange} />
                                        <p id="PostcodeError" className="errorMessage"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 invoice">
                                <hr />
                                <h4>Invoice details (if different)</h4>
                                <p className="invoice-fill" onClick={props.completeInvoiceAddress}>My invoice details are the same</p>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>First Name*</label>
                                        <input value={props.state.firstNameInvoice} type="text" id="firstNameInvoice" name="firstNameInvoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoiceFirstNameError" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>Last Name*</label>
                                        <input value={props.state.lastNameInvoice} type="text" id="lastNameInvoice" name="lastNameInvoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoiceLastNameError" className="errorMessage"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>Email*</label>
                                        <input value={props.state.emailInvoice} type="text" id="emailInvoice" name="emailInvoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoiceEmailError" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>Phone*</label>
                                        <input value={props.state.phoneInvoice} type="text" id="phoneInvoice" name="phoneInvoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoicePhoneNumberError" className="errorMessage"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>Address Line 1*</label>
                                        <input value={props.state.addressLine1Invoice} type="text" id="addressLine1Invoice" name="addressLine1Invoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoiceAddressLine1Error" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>Address Line 2</label>
                                        <input value={props.state.addressLine2Invoice} type="text" id="addressLine2Invoice" name="addressLine2Invoice" className="form-control" onChange={props.handleChange} />
                                        <p></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>Town or City*</label>
                                        <input value={props.state.townCityInvoice} type="text" id="townCityInvoice" name="townCityInvoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoiceTownCityError" className="errorMessage"></p>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <label>County*</label>
                                        <input value={props.state.countyInvoice} type="text" id="countyInvoice" name="countyInvoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoiceCountyError" className="errorMessage"></p>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>Postcode*</label>
                                        <input value={props.state.postcodeInvoice} type="text" id="postcodeInvoice" name="postcodeInvoice" className="form-control" onChange={props.handleChange} />
                                        <p id="InvoicePostcodeError" className="errorMessage"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr />
                                <h4>Business Information</h4>
                                <div className="form-row">
                                    <div className="col-lg-6 col-12">
                                        <label>VAT number*</label>
                                        <input type="text" id="vatNumber" name="vatNumber" className="form-control" onChange={props.handleChange} />
                                        <p id="VatNumberError" className="errorMessage"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr />
                                <h4>Account Type</h4>
                                <p>Please select from the following options:</p>
                                <div className="form-row py-3">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-check">
                                            <input type="checkbox" checked={props.state.buyer || props.state.both} id="buyer" name="buyer" onChange={props.handleCheck} />
                                            <label className="form-check-label">Buyer</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-check">
                                            <input type="checkbox" checked={props.state.seller || props.state.both} id="seller" name="seller" onChange={props.handleCheck} />
                                            <label className="form-check-label">Seller</label>
                                        </div>
                                    </div>
                                    <p id="BuyerError" className="errorMessage pl-2"></p>
                                </div>
                            </div>

                            {props.state.buyer &&
                                <div className="col-12">
                                    <hr />
                                    <h4>Buyer Details</h4>
                                    <div className="form-row">
                                        <div className="col-lg-6 col-12">
                                            <div className="form-check pt-4 pb-4 pl-0 mb-3">
                                                <input type="checkbox" checked={props.state.hasNextGear} id="hasNextGear" name="hasNextGear" onChange={props.handleCheck} />
                                                <label className="form-check-label">Do you have a NextGear account?</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 mb-3">
                                            {props.state.hasNextGear &&
                                                <>
                                                    <label>NextGear account number</label>
                                                    <input type="text" id="ngAccNum" name="ngAccNum" className="form-control" onChange={props.handleChange} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label>Please upload a copy of your motor trade insurance*</label>
                                            <input accept=".pdf,.jpeg,.jpg" type="file" id="insuranceDoc" name="insuranceDoc" className="form-control" onChange={props.handleChange} />
                                            <p id="InsuranceDocError" className="errorMessage"></p>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label>Insurance expiry date*</label>
                                            <input type="date" id="expiryDate" name="expiryDate" className="form-control" onChange={props.handleChange} />
                                            <p id="TradeInsuranceExpiryDateError" className="errorMessage"></p>
                                        </div>
                                    </div>
                                </div>
                            }
                            {props.state.seller &&
                                <div className="col-12">
                                    <hr />
                                    <h4>Seller Details</h4>
                                    <div className="form-row">
                                        <div className="col-lg-6 col-12">
                                            <div className="form-check pt-4 pb-4 pl-0 mb-3">
                                                <input type="checkbox" checked={props.state.selfBillInvoice} id="selfBillInvoice" name="selfBillInvoice" onChange={props.handleCheck} />
                                                <label className="form-check-label">Agree to recieve Self Bill invoice?</label>
                                            </div>
                                        </div>

                                        {/* <div className="col-lg-6 col-12">
                                            <label>Please upload bank details on letter headed paper</label>
                                            <input accept=".pdf,.jpeg,.jpg" type="file" id="bankDetails" name="bankDetails" className="form-control" onChange={props.handleChange} />
                                            <p id="BankDetailsError" className="errorMessage"></p>
                                        </div> */}
                                    </div>
                                </div>
                            }
                            <div className="col-12 grey-box grey-box--bottom my-3 submit ">
                                <p>Occasionally we may like to send you information on Van Monster's products and services, that may help run your business. Please tick boxes below to let us know you'd like to hear from us.</p>
                                <div className="col mb-md-3">
                                    <div className="col-lg-6 col-12 mt-3">
                                        <div className="form-check">
                                            <input onChange={props.handleCheck} type="checkbox" checked={props.state.emailContact} id="emailContact" name="emailContact" />
                                            <label className="form-check-label">Email</label>
                                        </div>
                                        <div className="form-check">
                                            <input onChange={props.handleCheck} type="checkbox" checked={props.state.smsContact} id="smsContact" name="smsContact" />
                                            <label className="form-check-label">Sms</label>
                                        </div>
                                        <div className="form-check">
                                            <input onChange={props.handleCheck} type="checkbox" checked={props.state.telephoneContact} id="telephoneContact" name="telephoneContact" />
                                            <label className="form-check-label">Telephone</label>
                                        </div>
                                        <div className="form-check">
                                            <input onChange={props.handleCheck} type="checkbox" checked={props.state.postContact} id="postContact" name="postContact" />
                                            <label className="form-check-label">Post</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="privacy-text mt-3"> <input onChange={props.handleCheck} type="checkbox" checked={props.state.termsAndConditions} id="termsAndConditions" name="termsAndConditions" /> I agree by submitting the above details you are giving Van Monster Remarketing permission to contact you regarding this enquiry as per the details provided in our <Link to="/PrivacyPolicy">Privacy Policy</Link>. You agree to the Van Monster Remarketing website <Link to="/TermsOfUse">Terms of Use</Link> and our <Link to="/TAndC">Terms and Conditions</Link></p>
                                    <br /><p id="TermsAndConditionsError" className="errorMessage"></p>
                                    <div className="col-12 float-right">
                                        <button className="btn btn-success float-right" onClick={props.onRegister}>Apply Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

class RegisterV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tradingName: '',
            email: '',
            emailInvoice: '',
            firstName: '',
            firstNameInvoice: '',
            lastName: '',
            lastNameInvoice: '',
            phone: '',
            phoneInvoice: '',
            addressLine1: '',
            addressLine1Invoice: '',
            addressLine2: '',
            addressLine2Invoice: '',
            townCity: '',
            townCityInvoice: '',
            county: '',
            countyInvoice: '',
            postcode: '',
            postcodeInvoice: '',
            vatNumber: '',
            hasNextGear: false,
            ngAccNum: '',
            expiryDate: '',
            response: { errors: null, message: '', success: false },
            smsContact: false,
            postContact: false,
            emailContact: false,
            telephoneContact: false,
            termsAndConditions: false,
            buyer: false,
            seller: false,
            selfBillInvoice: false,
            error: {}
        }
        this.inputRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({ componentState: "pageContent" })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleCheck = (evt) => {
        let name = evt.target.name;
        this.setState({ [name]: !this.state[name] })
    }

    completeInvoiceAddress = () => {
        this.setState({
            emailInvoice: this.state.email,
            firstNameInvoice: this.state.firstName,
            lastNameInvoice: this.state.lastName,
            phoneInvoice: this.state.phone,
            addressLine1Invoice: this.state.addressLine1,
            addressLine2Invoice: this.state.addressLine2,
            townCityInvoice: this.state.townCity,
            countyInvoice: this.state.county,
            postcodeInvoice: this.state.postcode
        })
    }

    onRegister = () => {
        this.clearErrors();

        let fileToRead = null;
        if (this.state.buyer) {
            fileToRead = document.getElementById('insuranceDoc').files[0];
        }

        let form = new FormData();
        form.append("insuranceDoc", fileToRead);
        form.append("email", this.state.email);
        form.append("invoiceEmail", this.state.emailInvoice);
        form.append("firstName", this.state.firstName);
        form.append("invoiceFirstName", this.state.firstNameInvoice);
        form.append("lastName", this.state.lastName);
        form.append("invoiceLastName", this.state.lastNameInvoice);
        form.append("phoneNumber", this.state.phone);
        form.append("invoicePhoneNumber", this.state.phoneInvoice);
        form.append("addressLine1", this.state.addressLine1);
        form.append("invoiceAddressLine1", this.state.addressLine1Invoice);
        form.append("addressLine2", this.state.addressLine2);
        form.append("invoiceAddressLine2", this.state.addressLine2Invoice);
        form.append("townCity", this.state.townCity);
        form.append("invoiceTownCity", this.state.townCityInvoice);
        form.append("county", this.state.countyInvoice);
        form.append("invoiceCounty", this.state.countyInvoice);
        form.append("postcode", this.state.postcode);
        form.append("invoicePostcode", this.state.postcodeInvoice);
        form.append("vatNumber", this.state.vatNumber);
        form.append("nextGearAccount", this.state.hasNextGear);
        form.append("nextGearAccountNumber", this.state.ngAccNum);
        form.append("tradeInsuranceExpiryDate", this.state.expiryDate);
        form.append("tradingName", this.state.tradingName);
        form.append("canBeContactedByEmail", this.state.emailContact);
        form.append("canBeContactedBySms", this.state.smsContact);
        form.append("canBeContactedByPost", this.state.postContact);
        form.append("canBeContactedByTelephone", this.state.telephoneContact);
        form.append("termsAndConditions", this.state.termsAndConditions);

        form.append("buyer", this.state.buyer);
        form.append("seller", this.state.seller);
        form.append("selfBillInvoice", this.state.selfBillInvoice);

        if (this.state.both) {
            form.append("buyer", true);
            form.append("seller", true);
        } else {
            form.append("buyer", this.state.buyer);
            form.append("seller", this.state.seller);
        }

        apiPostFile('/api/account/registerV2', false, form)
            .then(result => this.setState({ componentState: "pageContent", response: result.body },
                () => {
                    if (this.state.response.success) { this.props.history.push('/ThanksForRegistering') }
                    else {
                        this.handleErrors();
                        window.scrollTo(0, 0);
                    }
                }
            ))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    clearErrors = () => {
        if (this.state.response.errors) {
            this.state.response.errors.forEach(
                (errorObject) => {
                    let el = document.getElementById(`${errorObject.key}Error`);
                    let input = document.getElementById(`${errorObject.key}`);

                    if (el !== null) {
                        el.innerHTML = ""
                    }

                    if (input !== null) {
                        input.classList.remove("errorBorder");
                    }
                }
            )
        }
    }

    handleErrors = () => {
        if (this.state.response.errors) {
            this.state.response.errors.forEach(
                (errorObject) => {
                    let el = document.getElementById(`${errorObject.key}Error`);
                    let input = document.getElementById(`${errorObject.key}`);

                    if (el !== null) {
                        el.innerHTML += errorObject.error
                    }

                    if (input !== null) {
                        input.classList.add("errorBorder");
                    }
                }
            )
        }
    }

    renderComponentState = () => {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    handleChange={this.handleChange}
                    onRegister={this.onRegister}
                    handleCheck={this.handleCheck}
                    completeInvoiceAddress={this.completeInvoiceAddress}
                    state={this.state}
                    message={this.state.response.message}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    {this.renderComponentState()}
                </div>
            </>
        );
    }
}

export default RegisterV2;
