import React, { Component } from 'react';
import { apiPost } from '../../../Shared/Utilities/ApiFetch';
import Error from '../../../Shared/Common/Error.js';
import Loading from '../../../Shared/Common/Loading';
import toastr from 'toastr';
import { Link } from 'react-router-dom';

const PageContent = (props) => {
    return (
        <div className="col-12 grey-box grey-box--bottom  mb-3">
             <p>Please tick boxes below to let us know you'd like to hear from us.</p>
            <div className="col mb-md-3">
                <div className="form-check">
                    <input onChange={props.handleCheck} type="checkbox" checked={props.state.canBeContactedByEmail} id="canBeContactedByEmail" name="canBeContactedByEmail" />
                    <label className="form-check-label">Email</label>
                </div>
                <div className="form-check">
                    <input onChange={props.handleCheck} type="checkbox" checked={props.state.canBeContactedBySms} id="canBeContactedBySms" name="canBeContactedBySms" />
                    <label className="form-check-label">SMS</label>
                </div>
                <div className="form-check">
                    <input onChange={props.handleCheck} type="checkbox" checked={props.state.canBeContactedByTelephone} id="canBeContactedByTelephone" name="canBeContactedByTelephone" />
                    <label className="form-check-label">Telephone</label>
                </div>
                <div className="form-check">
                    <input onChange={props.handleCheck} type="checkbox" checked={props.state.canBeContactedByPost} id="canBeContactedByPost" name="canBeContactedByPost" />
                    <label className="form-check-label">Post</label>
                </div>
            </div>
            <p className="privacy-text">Van Monster take data privacy seriously and will only use your information in accordance with our <Link to='/PrivacyPolicy'>privacy policy</Link> and you can unsubscribe at any time.</p>
            <div>
                <button className="btn btn-success float-right mb-md-3" onClick={props.handleSubmit}>Update preferences</button>
            </div>
        </div>

    )
}

class UpdatePreferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canBeContactedByEmail: false,
            canBeContactedBySms: false,
            canBeContactedByPost: false,
            canBeContactedByTelephone: false
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {

        if(this.props.preferences != null) {
        this.setState({
            canBeContactedByEmail: this.props.preferences.canBeContactedByEmail,
            canBeContactedBySms: this.props.preferences.canBeContactedBySms,
            canBeContactedByTelephone: this.props.preferences.canBeContactedByTelephone,
            canBeContactedByPost: this.props.preferences.canBeContactedByPost,
            componentState: "pageContent"
        })
    }
    }

    handleSubmit() {
        let dataToPost = {
            canBeContactedByEmail: this.state.canBeContactedByEmail,
            canBeContactedBySms: this.state.canBeContactedBySms,
            canBeContactedByPost: this.state.canBeContactedByPost,
            canBeContactedByTelephone: this.state.canBeContactedByTelephone
        };

        apiPost('/api/account/UpdatePreferences', true, dataToPost)
            .then((result) => { this.popToast(result.body) })
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    handleCheck(evt) {
        let name = evt.target.name;
        this.setState({ [name]: !this.state[name] })
    }

    popToast(result) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "30000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
            "toastClass": "toastr"
        }

        toastr.success(result);
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    state={this.state}
                    handleCheck={this.handleCheck}
                    handleSubmit={this.handleSubmit}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default UpdatePreferences;