import React, { Component } from 'react';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import VideoCard from './Components/VideoCard';
import Modal from 'react-bootstrap4-modal';
import moment from 'moment';
import ReactPlayer from 'react-player';
import Error from '../../Shared/Common/Error';
import Loading from '../../Shared/Common/Loading';

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentState: "loading",
            videos: [],
            currentVideo: null,
            currentVideoId: null
        };

        this.playVideo = this.playVideo.bind(this);
        this.closeVideo = this.closeVideo.bind(this);
    }

    componentDidMount() {
        this.getVideos();
    }

    getVideos() {
        this.setState({ componentState: "loading" });
        apiGet('/api/help', true)
            .then(result =>
                this.setState({ componentState: "videos", videos: result.body })
            )
            .then(result =>
                this.setState({ currentVideo: this.state.videos.find(v => v.id === this.props.match.params.id) })
            )
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    playVideo(id) {
        this.setState({ currentVideo: this.state.videos.find(el => el.id === id), currentVideoId: id });
        this.props.history.push('/Help/' + id);
    }

    closeVideo() {
        this.setState({ currentVideo: null, currentVideoId: null });
        this.props.history.push('/Help');
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "videos":
                return (
                    <div className="container">
                        {this.state.currentVideo != null &&
                            <Modal visible={this.state.currentVideo != null} className="video">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.currentVideo.title}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeVideo}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <ReactPlayer url={this.state.currentVideo.url} playing width="100%" height="90%" config={{
                                        youtube: {
                                            playerVars: { autoplay: 0 }
                                        }
                                    }} />
                                    <p className="mt-3">{this.state.currentVideo.description}</p>
                                </div>
                                <div className="modal-footer">
                                    <small>Updated: {moment(this.state.currentVideo.updatedDate).format("D MMM YYYY")}</small>
                                </div>
                            </Modal>
                        }


                        <div className="row">
                            <div className="col-12 grey-box my-4 text-center">
                                <h1 className="text-center mt-5">Help Videos</h1>
                                <p className="mb-5">Some tips to get you started</p>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.videos.length <= 0 &&
                                <div className="col-12 my-3">
                                    <p>Help videos coming soon.</p>
                                </div>
                            }
                            {this.state.videos.map(x => <VideoCard key={x.id} video={x} playVideo={this.playVideo} />)}
                        </div>
                    </div>
                );
            case "error":
                return <Error refresh={this.getAuctions} error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    {this.renderComponentState()}
                </div>
            </div>
        );
    }
};

export default Help;