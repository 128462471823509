import React, { Component } from 'react';
import FLogo from '../../Images/VMR_Logo_White.png';
import { Link } from 'react-router-dom';
import './Footer.scss';

class Footer extends Component {
    render() {
        return (
            <footer className="container-fluid">
                <div className="row vmeFooter py-1">
                    <div className="container">
                        <div className="row mt-4 mb-2">
                            <div className="brand col-lg-3 col-md-12 col-sm-12 mb-3">
                                <div className="flogo">
                                    <img src={FLogo} alt="logo" className=" my-2 img-fluid footer-logo" />
                                </div>
                            </div>
                            <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12" >
                                <div className="finfo">
                                    <h2>Our Services</h2>
                                    <Link className="footer-link" to="/Services"><p>Our Services</p></Link>
                                    <Link className="footer-link" to="/Partners"><p>Partners</p></Link>
                                    <Link className="footer-link" to="/Help"><p>Help Guides</p></Link>
                                    <Link className="footer-link" to="/Assist40"><p>Assist40</p></Link>
                                    <Link className="footer-link" to="/BuyersFees"><p>Buyer Fees</p></Link>
                                </div>
                            </div>
                            <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12" >
                                <div className="finfo">
                                    <h2>About</h2>
                                    <Link className="footer-link" to="/ContactUs"><p>Contact Us</p></Link>
                                    <Link className="footer-link" to="/TAndC"><p>Terms & Conditions</p></Link>
                                    <Link className="footer-link" to="/PrivacyPolicy"><p>Privacy Policy</p></Link>
                                    <Link className="footer-link" to="/CookiePolicy"><p>Cookie Policy</p></Link>
                                    <Link className="footer-link" to="/TermsOfUse"><p>Terms of Use</p></Link>
                                </div>
                            </div>
                            <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12" >
                                <div className="finfo">
                                    <h2>Registered Office</h2>
                                    <p>Northgate PLC</p>
                                    <p>Northgate Centre</p>
                                    <p>Lingfield Way</p>
                                    <p>Darlington</p>
                                    <p>DL1 4PZ</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row footer-bottom">
                    <div className="col-12 pt-3 text-center">
                        <p>&copy; VanMonsterRemarketing</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;