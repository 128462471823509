import React, { Component } from 'react';
import Error from '../../../Shared/Common/Error.js';
import Loading from '../../../Shared/Common/Loading';
import UpdatePreferences from './UpdatePreferences';

const PageContent = (props) => {
    return (
        <div className="col">
            <div className="mt-2">
                <h4>Change your account information.</h4>
            </div>
            <div>
                <p className="font">Occasionally we may like to send you information on Van Monster's products and services, that may help run your business.</p>
                <UpdatePreferences preferences={props.state.preferences} />
            </div>
        </div>
    )
}

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preferences: []
        }
    }

    componentDidMount() {
        this.setState({
            preferences: this.props.preferences,
            componentState: "pageContent"
        })
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    state={this.state}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default Settings;