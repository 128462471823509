import React, { Component } from 'react';
import moment from 'moment';
import { baseUrl } from '../../../Shared/Utilities/AppConfig';
import { apiGet } from '../../../Shared/Utilities/ApiFetch';
import Loading from '../../../Shared/Common/Loading';
import Error from '../../../Shared/Common/Error';
import { ThemeContext } from '../../../ThemeContext';
import Button from '../../../Shared/Common/Button';

class Catalogue extends Component {

    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = {
            componentState: "loading"
        }

        this.getCatalogue = this.getCatalogue.bind(this);
    }

    componentDidMount() {
        this.getCatalogue();
    }

    componentWillUnmount() {
        this.props.clearTheme();
    }

    getCatalogue() {
        this.setState({ componentState: "loading" });

        apiGet('/api/Catalogue/Catalogue/' + this.props.match.params.id, true)
            .then(result => this.setState({ componentState: "catalogue", catalogue: result.body }, () => this.props.getTheme(result.body.organisationId)))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    renderCatalogue() {
        const list = this.state.catalogue.lots.map(l => {
            const v = l.vehicles[0];
            return (
                <tr key={l.id}>
                    {
                        !this.state.catalogue.isBuyNow > 0 &&
                        <td>{l.ordinal}</td>
                    }
                    <td>{v.registrationNumber}<br />{v.registrationYear}</td>
                    <td>{v.make} {v.model} {v.derivative} / {v.transmission} / {v.colour}<br />
                    Aircon: {v.airConDescription} / V5: {v.v5Description} / Service History: {v.serviceHistoryDescription} / MOT: {v.Mot ? moment(v.Mot).format('DD/MM/YYYY') : "Unknown"}<br />
                    Location: {v.location} / Odometer: {v.mileage} {v.mileageMilesDescription} / VAT: {v.vatDescription}<br />
                        {
                            !this.state.catalogue.isBuyNow > 0 ?
                                <>
                                    Prices: Start Price: {l.startPrice} / Cap Clean: {v.capClean} / Cap Average: {v.capAverage} / Cap Below: {v.capBelow}
                                </>
                                :
                                <>
                                    Prices: Buy It Now Price: {l.buyItNowPrice}
                                </>
                        }
                    </td>
                    <td>{v.contact}</td>
                </tr>
            )
        });

        return (
            <>
                <ThemeContext.Consumer>{(theme) => {
                    return (
                        <div className="container">
                            <div className="row">
                                <div className="col-12 grey-box my-4 text-center">
                                    <h1 className="text-center mt-5" style={theme.themePrimaryFontColor}>Catalogue</h1>
                                    <p className="mb-1">{this.state.catalogue.name}</p>
                                    <p className="mb-5">{this.state.catalogue.description}</p>
                                    {
                                        !this.state.catalogue.isBuyNow > 0 &&
                                        <div className="row">
                                            <div className="col-12 col-lg-3 offset-lg-3 text-center">
                                                <p><strong>Starts:</strong> {moment(this.state.catalogue.startDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                            </div>
                                            <div className="col-12 col-lg-3 text-center">
                                                <p><strong>Ends:</strong> {moment(this.state.catalogue.endDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-4">
                                    <Button buttonText="Print" theme={theme} divClass="btn btn-block btn-success" clickFunc={() => window.print()} buttonType={theme.btnSuccess} buttonTypeHover={theme.btnSuccessHover} />
                                </div>

                                <div className="col-4">
                                    <a href={baseUrl() + '/api/Catalogue/CatalogueCsv/' + this.props.match.params.id} download>
                                        <Button buttonText="Download CSV" theme={theme} divClass="btn btn-block btn-success" buttonType={theme.btnSuccess} buttonTypeHover={theme.btnSuccessHover} />
                                    </a>
                                </div>

                                <div className="col-4">
                                    <a href={baseUrl() + '/api/Catalogue/CataloguePdf/' + this.props.match.params.id} download>
                                        <Button buttonText="Download PDF" theme={theme} divClass="btn btn-block btn-success" buttonType={theme.btnSuccess} buttonTypeHover={theme.btnSuccessHover} />
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {
                                                !this.state.catalogue.isBuyNow > 0 &&
                                                <th>Lot</th>
                                            }
                                            <th>Reg No / Year</th>
                                            <th>Specification</th>
                                            <th>Contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }}</ThemeContext.Consumer>
            </>
        )
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "catalogue":
                return this.renderCatalogue();
            case "error":
                return <Error refresh={this.getCatalogue} error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default Catalogue;