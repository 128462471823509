import React from 'react';
import SearchResults from './SearchResults';
import SearchFacet from './SearchFacet';
import './Styles/SearchFilters.scss';

const SearchFilters = (props) => {

    return (
        <>
            <div className="col-12 search-filters mt-2 search-filters--green mobile-filters d-none d-md-block" id="filter" style={props.theme.searchFilter}>
                <div className="row mob-filters" >
                    {
                        props.facets &&
                        props.facets.map(x => <SearchFacet theme={props.theme} updateFilter={props.updateFilter} key={x.name} data={x} />)
                    }

                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="form-group form-check mt-4 ml-2">
                            <input className="form-check-input" type="checkbox" checked={props.hideEnded} onChange={(e) => props.updateHideEnded(e)} name="checkboxHideEnded" />
                            <label style={props.theme.searchFilterLabel} className="form-check-label" htmlFor="checkboxHideEnded">Hide Ended</label>
                        </div>
                    </div>
                </div>
            </div>

            <SearchResults
                data={props.data}
                auctionId={props.auctionId}
                hasMore={props.hasMore}
                loadMore={props.loadMore}
                bidIncrement={props.bidIncrement}
                addToWatchList={props.addToWatchList}
                removeFromWatchList={props.removeFromWatchList}
                auctionState={props.auctionState}
                isBuyItnowSale={props.isBuyItnowSale}
                addToPageHistory={props.addToPageHistory}
                theme={props.theme}
                getFavourites={props.getFavourites}

            />
        </>
    );
};

export default SearchFilters;