import React, { Component } from 'react';
import BidHistory from './Components/BidHistory';
import Error from '../../Shared/Common/Error';
import Loading from '../../Shared/Common/Loading';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import LotsWon from './Components/LotsWon';
import './Components/BidHistory.css';
import LotsWonVsLotsBid from './Components/LotsWonVsLotsBid';
import Stats from './Components/Stats';
import BidsPlacedPerAuction from './Components/BidsPlacedPerAuction';
import Breadcrumbs from '../../Shared/Common/Breadcrumbs';

const PageContent = (props) => {
    return (
        <React.Fragment>
            <div className="row">
                <Breadcrumbs pageCrumb={"Dashboard"} />
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-6">
                    <h2>Bids placed per auction</h2>
                    <hr />
                    <BidsPlacedPerAuction data={props.member.bidsPlaced} />
                </div>
                <div className="col-sm-12 col-lg-6">
                    <h2>Lots won vs lots bid on</h2>
                    <hr />
                    <LotsWonVsLotsBid won={props.member.numberOfLotsWon} bid={props.member.numberOfLotsBidOn} />
                </div>
                <div className="col-sm-12 col-lg-6">
                    <h2>Stats</h2>
                    <hr />
                    <Stats stats={props.member.stats} />
                </div>

                <div className="col-sm-12 col-lg-6">
                    <h2>Lots won</h2>
                    <hr />
                    <div className="bidHistory">
                        <LotsWon lots={props.member.lotsWon} />
                    </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                    <h2>Bid history</h2>
                    <hr />
                    <div className="bidHistory">
                        <BidHistory bids={props.member.bids} />
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member: { claims: [] },
            bidsPageNumber: 1,

        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        apiGet(`/api/Account/Dashboard`, true)
            .then(result => this.setState({ componentState: "pageContent", member: result.body }))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    handleChange={this.handleChange}
                    member={this.state.member}
                    sendMessage={this.sendMessage}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default Dashboard;