import decode from 'jwt-decode';

function AutoLogout() {

    const keys = {
        user: 'user',
        storage: 'storage',
        loginEvent: 'login-event',
        logoutEvent: 'logout-event',
        autoLogoutKey: 'autoLogout'
    }

    function setup() {

        const user = JSON.parse(localStorage.getItem(keys.user));

        if (!user) {
            return;
        }

        const expiration = getExpiration(user);

        if (alreadySetup(expiration)) {
            return;
        }

        setTimerToExpire(expiration);
    }
    
    function getExpiration(user) {

        const token = decode(user.token);

        return token.exp * 1000;
    }

    function alreadySetup(expiration) {

        const tracker = getTracker();

        if (!tracker) {
            return false;
        }

        if (expiration === tracker.expiration) {
            return true;
        }

        return false;
    }

    function setTimerToExpire(expiration) {

        const currentTicks = new Date().getTime();

        const difference = expiration - currentTicks;

        const timeoutId = setTimeout(() => {
            logout();
        }, difference);

        sessionStorage.setItem(keys.autoLogoutKey, JSON.stringify({
            expiration: expiration,
            timeoutId: timeoutId
        }));
    }

    function logout() {

        triggerLogoutInOtherTabs();

        logoutCurrentTab();
    }

    function logoutCurrentTab() {

        cancelLogoutTimer();

        localStorage.removeItem(keys.user);

        window.location.reload(true);
    }

    function cancelLogoutTimer() {

        const tracker = getTracker();

        if (tracker) {

            clearTimeout(tracker.timeoutId);

            sessionStorage.removeItem(keys.autoLogoutKey);
        }
    }
    
    function triggerLogoutInOtherTabs() {

        localStorage.setItem(keys.logoutEvent, JSON.stringify(keys.autoLogoutKey + Math.random()));
    }
    
    function listenForLogoutInOtherTabs() {

        window.addEventListener(keys.storage, function (e) {

            if (e.key === keys.logoutEvent) {
                logoutCurrentTab();
            }
        });
    }

    function triggerLoginInOtherTabs() {

        localStorage.setItem(keys.loginEvent, JSON.stringify(keys.autoLogoutKey + Math.random()));
    }

    function listenForNewLoginInOtherTabs() {

        window.addEventListener(keys.storage, function (e) {

            if (e.key === keys.loginEvent) {

                const user = JSON.parse(localStorage.getItem(keys.user));

                if (user){
                    cancelLogoutTimer();
                    setup();
                }
            }
        });
    }

    function getTracker() {
        
        return JSON.parse(sessionStorage.getItem(keys.autoLogoutKey));
    }
   
    function construct() {

        listenForLogoutInOtherTabs();
        listenForNewLoginInOtherTabs();

        const autoLogout = {
            setup: setup,
            logout: logout,
            triggerNewLogin: triggerLoginInOtherTabs
        }

        return autoLogout;
    }

    return construct();
}

const autoLogout = AutoLogout();

export { autoLogout };