import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ({ theme, buttonText, clickFunc, divClass, buttonType, buttonTypeHover, icon }) => {

    const [hover, setHover] = useState(false);

    return (
        <>
            {
                !clickFunc ?
                    <button className={`${theme == null ? 'btn btn-primary' : 'btn'} ${divClass}`}
                        style={hover ? buttonTypeHover : buttonType}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    > {buttonText} {icon ? <FontAwesomeIcon icon={icon} /> : ""}</button>
                    :
                    <button className={`${theme == null ? 'btn btn-primary' : 'btn'} ${divClass}`}
                        style={hover ? buttonTypeHover : buttonType}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        onClick={clickFunc}
                    > {buttonText} {icon ? <FontAwesomeIcon icon={icon} /> : ""}</button>
            }
        </>
    );
};

export default Button;