import React, { Component } from 'react';

class Registered extends Component {
    render() {
        return (
            <div className="container pt-4">
                <h1>Thank you for registering</h1>
                <div className="row form-container mt-3 mx-0">
                    <h6>We are now in the process of reviewing your application, once it has been approved or if we need more information, we will send you an email.</h6>
                </div>
            </div>
        );
    }
}

export default Registered;