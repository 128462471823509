export function baseUrl() {
    if (window.location.hostname.startsWith("vm-evo-")) {
        return 'https://' + window.location.hostname.replace("client", "api").replace("admin", "api");
    } 
    else if (window.location.hostname.includes("vanmonsterremarketing")) {
        return 'https://vanmonsterremarketingapi.azurewebsites.net';
    } 
    else {
        return 'https://localhost:44373';
    }
}
