export function tryScrollToElement(elementId,offsetElementIds)
{
    const maxTrys =5;
    const additionalPadding = 5;
    if(!scrollToElement(elementId,offsetElementIds,additionalPadding))
    {
        for (let i = 0; i <= maxTrys; i++) {
            let success = false;
            //Retry to find the element after a timeout
            setTimeout(() => {
               success = scrollToElement(elementId,offsetElementIds,additionalPadding);
            }, 100); 
            if (success===true){
                break;
            }
        }
    }
}
export function scrollToElement(elementId,offsetElementIds,extraPadding){       
    let success = false;
    let element = document.getElementById(elementId);
    if(element!==null)
    {
        let isLargeScreensize = window.matchMedia('(min-width: 992px)').matches;
        element.scrollIntoView();
        //If the screen is large it needs an offset to position the result
        if(isLargeScreensize)
        {
            let scrolledY = window.scrollY;
            if(scrolledY){
                window.scroll(0, scrolledY - getOffsetTotal(offsetElementIds,extraPadding));
            }  
        }
        success = true;
    }
    return success;
}
function getOffsetTotal(offsetElementIds,extraPadding)
{
    let offsetTotal = extraPadding;
    for (var i = 0; i < offsetElementIds.length; i++) {
        let offsetElement = document.getElementById(offsetElementIds[i]);
        if(offsetElement!==null)
        {
            offsetTotal+=offsetElement.offsetHeight;
        }  
    }
    return offsetTotal;
}
