import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatMoney } from '../../../../Shared/Utilities/FormatMoney';


const BidNowModal = (props) => {
    return (
        <>
            <Modal visible={props.showBidModal}>
                <div className="modal-header">
                    <h5 className="modal-title">Place your bid</h5>
                    <button type="button" className="close" data-dismiss="modal" onClick={() => props.handleBidNowModalClick()} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    {
                        props.placeBidSuccess ?
                            <>
                                <p className="text-center"><FontAwesomeIcon icon={['fas', 'check']}  className="bid-icon" /></p>
                                <p className="text-center">{props.placeBidSuccess}</p>
                            </>
                            :
                            <>
                                <div className='text-center mb-4'>

                                    <p>Bids must be in multiples of £{formatMoney(props.bidIncrement || 10)}.</p>
                                    <p>All bids must be recieved by the end of the auction.</p>
                                    {
                                        props.lotState.state === 3 && // preview
                                        <p><strong>Proxy bids are only applied once the auction has opened.</strong></p>
                                    }
                                    <p>Please enter your bid (minimum is £{formatMoney(props.minimumBid())} {props.vehicle.vatDescription})</p>
                                </div>
                                <div className='text-center font-weight-bold mb-3'>
                                    <p>You are bidding</p>
                                    <h4 className='text-success'>£{formatMoney(props.amountToBid)}</h4>
                                    <p>on Lot# {props.lot.ordinal}</p>
                                </div>

                                <div className="col-12 col-md-8 mx-auto">
                                    <label className="font-weight-bold text-muted text-center">Enter your Maximum Bid</label>
                                    <input id={props.elementId}
                                        type="number"
                                        value={props.amountToBid}
                                        min={props.minimumBid()}
                                        step={props.bidIncrement || 10}
                                        className="form-control"
                                        onChange={props.handleChange} />
                                    <span className="errorMessage font-weight-bold">{props.placeBidError}</span>
                                </div>
                            </>
                    }
                </div>
                <div className="modal-footer d-flex justify-content-between">
                    {
                        props.placeBidSuccess ?
                            <button type="button" className="btn btn-success" onClick={() => props.handleBidNowModalClick()}>Close</button>
                            :
                            <>
                                <button type="button" className="btn btn-danger pull-left" onClick={() => props.handleBidNowModalClick()}>Cancel</button>

                                {!props.disableBidButton ?
                                    <button type="button" className="btn btn-success pull-right" onClick={() => props.placeBid(props.id, false)}>Submit bid</button>
                                    :
                                    <button type="button" className="btn btn-primary pull-right" disabled={true} >Processing...</button>
                                }
                            </>
                    }
                </div>
            </Modal>
        </>

    );
};

export default BidNowModal;