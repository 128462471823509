import React from 'react';
import Lot from './Lot';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../../../Shared/Common/Loading';

const SearchResults = (props) => {

    const localUser = localStorage.getItem('user');
    const user = JSON.parse(localUser);
    const userId = user != null ? user.id : "";

    const lots = props.data.map(x =>
        <Lot key={x.id}
            id={x.id}
            auctionId={props.auctionId}
            vehicles={x.vehicles}
            lotState={x.lotState}
            lotStateSetTimestamp={x.lotStateSetTimestamp}
            lot={x}
            lotType="Auction"
            bidIncrement={props.bidIncrement}
            addToWatchList={props.addToWatchList}
            removeFromWatchList={props.removeFromWatchList}
            bidAlertClass={x.lotState && x.lotState.sold && x.lotState.bidUserId === userId ? "bidder-bought" : (x.lotState && x.lotState.bidUserId === userId ? "highest-bidder" : (x.lotState && x.lotState.bidders.includes(userId) ? "bidder-outbid" : ""))}
            auctionState={props.auctionState}
            isBuyItnowSale={props.isBuyItnowSale}
            addToPageHistory={props.addToPageHistory}
            theme={props.theme}
            getFavourites={props.getFavourites}
        />
    )

    return (
        <>
            {lots.length > 0 ?
                <InfiniteScroll
                    pageStart={0}
                    loadMore={props.loadMore}
                    hasMore={props.hasMore}
                    loader={<Loading key={0} />}
                    useWindow={true}
                    threshold={2000}>
                    {lots}
                </InfiniteScroll>
                :
                <h2>Sorry there are no lots available in this sale</h2>
            }
        </>
    );
};

export default SearchResults;