import React, { Component } from 'react';
import { BarChart, Bar,  XAxis, YAxis, CartesianGrid } from 'recharts';

class BidsPlacedPerAuction extends Component {
    render() {
        
        return (
            <BarChart
                width={500}
                height={300}
                data={this.props.data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="auctionName" />
                <YAxis dataKey="numberOfBids" />
                <Bar dataKey="numberOfBids" fill="#8884d8" />

            </BarChart>
        );
    }
}

export default BidsPlacedPerAuction;