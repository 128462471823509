import React, { Component } from 'react';
import Error from '../../Shared/Common/Error';
import Loading from '../../Shared/Common/Loading';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import './Styles/User.css';
import UpdatePassword from './Components/UpdatePassword';
import Settings from './Components/Settings'
import SecuredLots from './Components/SecuredLots';
import BidHistory from './Components/BidHistory';
import { Link } from 'react-router-dom';
import Invoices from './Components/Invoices';

const PageContent = (props) => {
    return (
        <div className="container mb-4">
            <div className="row mt-5">
                <Tabs className="vm-tabs col-12 four">
                    <Tab label="Settings">
                        <Settings preferences={props.state.user.tradeMemberDetails} />
                        <div className="col">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <UpdatePassword preferences={props.state.user.tradeMemberDetails} />
                                </div>
                                <div className="col-12 col-lg-6 mob-mb-3">
                                    <h4>Account Manager</h4>
                                    <div className="grey-box d-flex align-self-stretch">
                                        {
                                            props.state.tse ?
                                                <div>
                                                    <p>Welcome to Van Monster Remarketing, please find below the name and contact details of your account manager. Please do not hesitate to contact them if you have any further queries.</p>
                                                    <p className="mt-4 mb-3"><strong>{props.state.tse.firstName} {props.state.tse.lastName}</strong></p>
                                                    <p><a href={"mailto:" + props.state.tse.email}>{props.state.tse.email}</a></p>
                                                    <p>Tel. {props.state.tse.phoneNumber}</p>
                                                </div> :
                                                <>
                                                    <p>You have not yet been assigned an Account Manager. If you have any enquiries please don't hesitate to <Link to="/ContactUs">contact us</Link></p>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Secured Lots">
                        <SecuredLots nextAuction={props.nextAuction} />
                    </Tab>
                    <Tab label="Invoices">
                        <Invoices nextAuction={props.nextAuction} />
                    </Tab>
                    <Tab label="Bidding History">
                        <BidHistory nextAuction={props.nextAuction} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}
class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            tse: {},
            member: {
                claims: []
            },
            nextAuction: {}
        }
    }

    componentDidMount() {
        this.updateUser();
    }

    updateUser() {
        apiGet("/api/account/user", true)
            .then((result) => this.setState({ user: result.body, tse: result.body.tradeSalesExec, componentState: "pageContent" }))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    fetchNextAuction = () => {
        apiGet('/api/auction/nextauction', true)
            .then(result => this.setState({ nextAuction: result.body }))
            .catch(error => this.setState({ nextAuction: null }));
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    state={this.state}
                    tse={this.state.tse}
                    member={this.state.member}
                    nextAuction={this.state.nextAuction}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default Account;