import React, { Component } from 'react';
import { apiGet } from '../../../Shared/Utilities/ApiFetch';
import Vehicle from '../../../Shared/Containers/Vehicle/Components/Vehicle';
import Loading from '../../../Shared/Common/Loading';
import Error from '../../../Shared/Common/Error';
import queryString from 'query-string';
import { ThemeContext } from '../../../ThemeContext'

class VehicleDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentState: "loading",
            vehicleTitle: '',
            lotNumber: 0
        };
    }

    componentDidMount() {
        this.getVehicle();
        this.setState({ lotNumber: this.getLotNumber() })
    }

    componentWillUnmount() {
        this.props.clearTheme();
    }

    getVehicle = () => {
        this.setState({ componentState: "loading" });
        apiGet('/api/vehicle/' + this.props.match.params.id, true)
            .then(result => this.setState({
                componentState: "vehicle",
                vehicle: result.body,
                vehicleTitle: result.body.registrationYear + ' ' + result.body.make + ' ' + result.body.model + ' ' + result.body.derivative + ' (' + result.body.registrationNumber + ')'
            }, this.props.getTheme(result.body.organisation.id)))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    getLotNumber = () => {
        let lotNumber = 0;
        const querystringValues = queryString.parse(window.location.search);
        if (querystringValues.pos !== null && querystringValues.pos > 0) {
            lotNumber = querystringValues.pos;
        }
        return lotNumber;
    }

    renderComponentState(theme) {
        switch (this.state.componentState) {
            case "vehicle":
                return <Vehicle theme={theme} vehicle={this.state.vehicle} goBack={this.props.goBack} lotNumber={this.state.lotNumber} />;
            case "error":
                return <Error refresh={this.getVehicle} error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <ThemeContext.Consumer>{(theme) => {
                return (
                    <div className="container content">
                        {this.renderComponentState(theme)}
                    </div>
                )
            }
            }
            </ThemeContext.Consumer>
        );
    }
}

export default VehicleDetail;