import React, { Component } from 'react';
import moment from 'moment';
import './Login.scss';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAllowed } from '../../Shared/Utilities/AuthUtilities.js';

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotifications: false
        };
        this.showNotifications = this.showNotifications.bind(this);
    }

    componentDidMount() {
        this.props.updateUser();
    }

    showNotifications() {
        this.setState({ showNotifications: !this.state.showNotifications })
    }

    render() {
        return (
            <React.Fragment>
                <div className="profile-nav">
                    {this.props.user === null ?
                        <div className="mob-nav" id="navbarSupportedContent">
                            <ul className="navbar-nav vm-navbar">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Login">
                                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']} className="logout-icon mr-2" />
                                        Login
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        :
                        <div>
                            <div className="collapse navbar-collapse account-btns" id="navbarSupportedContent">
                                {
                                    this.props.notifications &&
                                    <ul className="navbar-nav d-none d-lg-block">
                                        <li className="nav-item dropdown">
                                            <span className="nav-link notification" id="navbarDropdown">
                                                <FontAwesomeIcon icon={['fas', 'bell']} className="user-profile-icon" />&nbsp;{this.props.notifications.length}
                                            </span>
                                            {this.props.notifications.length > 0 &&
                                                <div className="dropdown-content right notifications-scroll" aria-labelledby="navbarDropdown">
                                                    <div className="notification-lg">
                                                        <div className="mt-2">
                                                            <p onClick={this.props.markAllAsSeen} className="text-right clickable">
                                                                <span className="clear-all mr-3">
                                                                    Clear all <FontAwesomeIcon icon={['far', 'times-circle']} className="nav-notification ml-1"></FontAwesomeIcon>
                                                                </span>
                                                            </p>
                                                        </div>
                                                        {
                                                            this.props.notifications.map((x, i) =>
                                                                <div className="notifications-container py-1 px-3" value={x.id} key={i}>
                                                                    <div> <span className="notification-time-sm">{moment(x.dateTimeStamp).format('DD/MM/YYYY HH:mm a')}</span></div>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={['fas', 'circle']} className="nav-notification-dot" />
                                                                        <span className="message">{x.message}</span>
                                                                    </span>
                                                                    <span className="pull-right ml-2 not-times">
                                                                        <span className="notification-time">{moment(x.dateTimeStamp).format('DD/MM/YYYY HH:mm a')}</span>
                                                                        <FontAwesomeIcon icon={['far', 'times-circle']} onClick={() => { this.props.markAsSeen(x.id) }} className="nav-notification ml-2" />
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                }
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <NavLink className="nav-link dropbtn profile" to="/MyAccount">
                                            <FontAwesomeIcon icon={['fas', 'user-circle']} className="user-profile-icon" />
                                            <p className=""> {this.props.user.firstName}</p>
                                        </NavLink>
                                        <span className="float-right toggle-icon  d-lg-none">
                                            <FontAwesomeIcon icon={['fas', 'sign-out-alt']} className="logout-icon" onClick={this.props.handleLogout} />
                                        </span>
                                        <div className="dropdown-content right">
                                            <NavLink className="dropdown-item vm-dropdown-item" to="/MyAccount">My Account</NavLink>
                                            {isAllowed('Seller') &&
                                                <a className="dropdown-item vm-dropdown-item" href="https://vanmonsterremarketing-admin.azurewebsites.net/">My Vendor Account</a>
                                            }
                                            <NavLink className="dropdown-item vm-dropdown-item" to="#" onClick={this.props.handleLogout}>Logout<span className="float-right"><FontAwesomeIcon icon={['fas', 'sign-out-alt']} className="logout-icon" /></span></NavLink>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}