import React, { Component } from 'react';
import SearchFilters from './SearchFilters';
import * as signalR from '@aspnet/signalr';
import './Styles/Auction.scss';
import moment from 'moment';
import { baseUrl } from '../../../Shared/Utilities/AppConfig';
import { apiGet, apiPost, apiDelete } from '../../../Shared/Utilities/ApiFetch';
import WatchList from './WatchList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Loading from '../../../Shared/Common/Loading';
import { isLoggedIn, isAllowed } from '../../../Shared/Utilities/AuthUtilities';
import { formatMoney } from '../../../Shared/Utilities/FormatMoney';
import queryString from 'query-string';
import { tryScrollToElement } from '../../../Shared/Utilities/ScrollUtilities';
import BackgroundImage from '../../../Images/30ebd0be-3c15-426f-9176-4d29ce937737.png';
import {TrackView} from '../../../Shared/Utilities/Tracking';
import { ThemeContext } from '../../../ThemeContext'

class BuyNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auction: {},
            lots: [],
            filteredLots: [],
            skip: 0,
            take: 10,
            searchUrl: this.props.match.params.id !== undefined ? `/api/auction/search/${this.props.match.params.id}` : `/api/auction/search/${this.props.buyNowId}`,
            initialUrl: this.props.match.params.id !== undefined ? `/api/auction/search/${this.props.match.params.id}` : `/api/auction/search/${this.props.buyNowId}`,
            selectedFacets: [],
            hasMore: false,
            facets: [],
            connected: false,
            user: null,
            auctionId: this.props.match.params.id !== undefined ? this.props.match.params.id : this.props.buyNowId,
            watchList: [],
            hideEnded: false,
            myVans: false,
            componentState: "loading",
            allowLoadMoreResults: true,
            loadMoreResultsErrorCount: 0,
            maximumLoadMoreErrors: 10,
        };

        this.loadMore = this.loadMore.bind(this);
        this.loadResults = this.loadResults.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.getFavourites = this.getFavourites.bind(this);
        this.addToWatchList = this.addToWatchList.bind(this);
        this.removeFromWatchList = this.removeFromWatchList.bind(this);
        this.updateHideEnded = this.updateHideEnded.bind(this);
        this.filterLots = this.filterLots.bind(this);
        this.updateLotState = this.updateLotState.bind(this);
        this.updateAuctionLotState = this.updateAuctionLotState.bind(this);
        this.addLotStateSetTimestamp = this.addLotStateSetTimestamp.bind(this);
        this.openMyVans = this.openMyVans.bind(this);
    }

    componentDidMount() {
        this.getAuction();
        this.props.getTheme();

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(baseUrl() + '/bidhub')
            .build();

        this.messageHandler(connection);
        this.startConnection(connection);
        this.updateUser();

        if (isLoggedIn()) {
            this.getFavourites();
        }
        TrackView(this.state.auctionId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lastNotification.dateTimeStamp !== this.props.lastNotification.dateTimeStamp) {
            if (this.props.lastNotification.table === "auction") {
                if (this.props.lastNotification.tableId === this.state.auction.id) {
                    this.performSearch();
                }
            }
        }
    }

    getFavourites() {
        if (isAllowed('Buyer')) {
            apiGet('/api/WatchList?auctionId=' + this.state.auctionId, true)
                .then(result => { this.addLotStateSetTimestamp(result.body); this.setState({ watchList: result.body }) });
        }
    }

    addToWatchList(lotId) {
        if (isAllowed('Buyer')) {
            apiPost('/api/WatchList', true, { lotId: lotId })
                .then(response => { this.updateLotState(response.body); this.getFavourites(); });
        }
    }

    removeFromWatchList(lotId) {
        if (isAllowed('Buyer')) {
            apiDelete('/api/WatchList', true, { lotId: lotId })
                .then(response => { this.updateLotState(response.body); this.getFavourites(); });
        }
    }

    updateHideEnded(e) {
        this.setState({ hideEnded: e.target.checked }, () => this.filterLots());
    }

    filterLots() {
        const lots = this.state.lots;

        if (this.state.hideEnded) {
            this.setState({ filteredLots: lots.filter(function (lot) { return lot.lotState.sold == null }) });
        } else {
            this.setState({ filteredLots: lots });
        }
    }

    getAuction() {
        const querystringValues = queryString.parse(window.location.search);
        if (querystringValues.pos !== null && querystringValues.pos > 0) {
            this.getAuctionByPosition(querystringValues.pos);
        } else {
            this.fetchAuction(`${this.state.searchUrl}/${this.state.take}/${this.state.skip}`);
        }
    }

    getAuctionByPosition(position) {
        //If we have a postion in the auction specified,load, and find the relevant result
        const newTake = Math.ceil(position / this.state.take) * this.state.take;
        this.fetchAuctionToPosition(`${this.state.searchUrl}/${newTake}/${this.state.skip}`, position, newTake);
    }

    fetchAuctionToPosition(url, position, newTake) {
        apiGet(url, true)
            .then(result => {
                this.addLotStateSetTimestamp(result.body.lots);
                this.setState({ auctionSetTimestamp: Date.now(), auction: result.body, facets: result.body.facets, lots: result.body.lots, hasMore: result.body.lots.length === newTake, componentState: "auction", skip: (newTake - this.state.take) }
                    , () => {
                        this.filterLots();
                    })
            }).then(
                () => {
                    tryScrollToElement("lot_" + position, ['navBar'])
                }
            );
    }

    fetchAuction(url) {
        apiGet(url, true)
            .then(result => {
                this.addLotStateSetTimestamp(result.body.lots);
                this.setState({ auctionSetTimestamp: Date.now(), auction: result.body, facets: result.body.facets, lots: result.body.lots, hasMore: result.body.lots.length === this.state.take, componentState: "auction" }, () => this.filterLots())
            });
    }

    performSearch() {
        this.setState({ skip: 0 });
        let currentUrl = `${this.state.searchUrl}/${this.state.take}/0`;
        let newSearchUrl = currentUrl + this.queryStringBuilder();
        this.fetchAuction(newSearchUrl);
    }

    queryStringBuilder() {
        if (this.state.selectedFacets.length === 0) {
            return '';
        }

        let queryString = '?facets=';
        let other = '&facets=';
        let pos = 1;

        const facets = this.state.selectedFacets.map((sf) => (sf.values.map((f) => (sf.name + "~" + f))));
        facets.forEach((x) => {
            if (pos === 1) {
                queryString = queryString + x;
            } else {
                queryString = queryString + other + x;
            }

            pos++;
        });

        return queryString;
    }

    updateFilter(facetName, values) {
        this.setState({ selectedFacets: this.state.selectedFacets.filter((sf) => sf.name !== facetName) }, () => {
            if (values.length > 0) {
                const newFacet = {
                    name: facetName,
                    values: values
                };
                this.setState({ selectedFacets: [...this.state.selectedFacets, newFacet] }, () => this.performSearch());
            } else {
                this.performSearch();
            }
        });
    }

    startConnection = (connection) => {
        connection.start()
            .then(c => {
                this.setState({ connected: true })
            })
            .catch(e => {
                this.setState({ connected: false })
                setTimeout(() => this.startConnection(connection), 5000);
            });
    }

    messageHandler = async (connection) => {
        connection.on("BidEvent", (lotState) => this.updateLotState(lotState));

        connection.onclose((error) => {
            this.setState({ connected: false });
            this.startConnection(connection);
        });
    }

    updateLotState(lotState) {
        this.updateAuctionLotState(lotState, this.state.lots, "lots");
        this.updateAuctionLotState(lotState, this.state.watchList, "watchList");
    }

    updateAuctionLotState(lotState, lots, statePropertyName) {
        let existingLots = lots;
        let elementPos = existingLots.map(function (x) { return x.id; }).indexOf(lotState.lotId);
        if (elementPos !== -1) {
            existingLots[elementPos].lotState = lotState;
            existingLots[elementPos].lotStateSetTimestamp = Date.now()
            this.setState({ [statePropertyName]: existingLots })
        }
    }

    addLotStateSetTimestamp(lots) {
        lots.forEach((lot) => { lot.lotStateSetTimestamp = Date.now() })
    }

    placeBid = (lotId, amount, startPrice) => {
        if (amount === null || amount === undefined || amount === "") {
            return Promise.reject({ message: 'Provide the bid amount' });
        }
        //if the bid is not the start price
        if (amount !== startPrice) {
            if ((amount - startPrice) % this.state.auction.bidIncrement) {
                return Promise.reject({ message: 'The bid must be an increment of £' + formatMoney(this.state.auction.bidIncrement) });
            }
        }

        if (amount < 0) {
            return Promise.reject({ message: 'The bid must be a positive value' });
        }

        const data = {
            LotId: lotId,
            Amount: amount
        }

        return apiPost('/api/Bid/placebid', true, data)
            .then(result => {
                this.getFavourites();
                this.updateUser();
                if (result.body.success) {
                    return Promise.resolve({ message: result.body.message });
                } else {

                    return Promise.reject({ message: result.body.message });
                }
            })
            .catch(error => {
                this.updateUser();
                if (navigator.onLine) {
                    return Promise.reject({ message: error.message });
                } else {
                    return Promise.reject({ message: 'You have no internet connection. Check the connection and try again.' });
                };
            });
    }

    buyItNow = (lotId, auctionId) => {
        const data = {
            LotId: lotId,
            auctionId: auctionId
        }

        return apiPost('/api/Bid/buyItNow', true, data)
            .then(result => {
                if (result.body.success) {
                    return Promise.resolve({ message: result.body.message })
                } else {
                    return Promise.reject({ message: result.body.message })
                }
            })
            .catch(error => {
                if (navigator.onLine) {
                    return Promise.reject({ message: error.message });
                } else {
                    return Promise.reject({ message: 'You have no internet connection. Check the connection and try again.' });
                };
            });
    }

    loadMore() {
        if (this.state.allowLoadMoreResults) {
            this.setState({ allowLoadMoreResults: false }, () => this.loadResults());
        }
    }

    loadResults() {
        let newSkip = this.state.skip + this.state.take;
        let newUrl = `${this.state.searchUrl}/${this.state.take}/${newSkip}${this.queryStringBuilder()}`;
        apiGet(newUrl, true)
            .then(result => {
                this.addLotStateSetTimestamp(result.body.lots);
                this.setState({ skip: newSkip, lots: [...this.state.lots, ...result.body.lots], hasMore: result.body.lots.length === this.state.take, allowLoadMoreResults: true, loadMoreResultsErrorCount: 0 }, () => this.filterLots())
            }).catch(error => {
                this.setLoadMoreErrorCount();
                this.resetAllowLoadMoreResults();
            });
    }

    resetAllowLoadMoreResults() {
        //Wait before allowing the loading of results more results
        setTimeout(() => {
            this.setState({ allowLoadMoreResults: true })
        }, this.state.loadMoreResultsErrorCount * 1000);

    }

    setLoadMoreErrorCount() {
        //Set the error count to a maximum -- This is so we can increment the amount of time we wait to try again
        if (this.state.loadMoreResultsErrorCount < this.state.maximumLoadMoreErrors) {
            this.setState({ loadMoreResultsErrorCount: this.state.loadMoreResultsErrorCount + 1 });
        }
    }

    updateUser() {
        apiGet(`/api/account/userBidInfo/${this.state.auctionId}`, true)
            .then(result => this.setState({ user: result.body }));
    }

    openMyVans() {
        let open = this.state.myVans === false ? true : false;
        this.setState({ myVans: open });
    }

    render() {
        const bgImage = {
            background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(' + BackgroundImage + ')',
            backgroundSize: 'cover'
        };

        const toggleFilter = (id) => {
            let el = $(`#${id}`);
            el.toggleClass('d-none');

            $('html,body').animate({
                scrollTop: $(".mobile-buttons").offset().top - 68
            }, 'slow');
        }

        switch (this.state.componentState) {
            case "auction":
                return (
                    <ThemeContext.Consumer>{(theme) => {
                        return (
                            <div className="auction content">
                                {
                                    this.state.auction.hasOwnProperty("id") &&
                                    <>
                                        <div id="scroller-anchor"></div>
                                        <div className="row no-gutters">
                                            <div className="col-12 px-0">
                                                <div className="py-4 auction-img " style={bgImage} >
                                                    <div className="col-12 auction-title py-2 text-center">
                                                        <div className="col">
                                                            {
                                                                [0, 1, 4].includes(this.state.auction.state) &&
                                                                (this.state.connected ?
                                                                    <span className="online badge-pill badge-success statusBadge">Live</span> :
                                                                    <span className="offline badge-pill badge-danger statusBadge">Offline</span>)
                                                            }
                                                        </div>

                                                        <h2 className="pt-2">{this.state.auction.name} </h2>

                                                        <h3 className="auction-description">{this.state.auction.description}</h3>
                                                    </div>

                                                    <div className="row auction-info">
                                                        <div className="col-12 col-lg-3 offset-lg-3 text-center">
                                                            {
                                                                this.state.auction.state > 0 &&
                                                                <React.Fragment>
                                                                    <p><strong>Starts:</strong> {moment(this.state.auction.startDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                        <div className="col-12 col-lg-3 text-center">
                                                            {
                                                                this.state.auction.state > 0 &&
                                                                <React.Fragment>
                                                                    <p><strong>Ends:</strong> {moment(this.state.auction.endDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                        <div className="col-12 text-center">
                                                            <p><strong>Status: </strong><span className={[0, 1, 4].includes(this.state.auction.state) ? "open" : "offline"}> {this.state.auction.stateDescription}</span> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row py-3 mobile-buttons" id="mobileButtons">
                                                <div className="col-12 text-md-center">
                                                    <React.Fragment>
                                                        <div className="btn-group" role="group" aria-label="Basic example">
                                                            <button type="button" className="btn btn-primary" onClick={() => this.setState({ myVans: false })}>Auction</button>
                                                            {isLoggedIn() &&
                                                                <button type="button" className="btn btn-success" onClick={() => this.setState({ myVans: true })}>My Auction  <FontAwesomeIcon icon={['fas', 'star']} className="my-auction" /></button>
                                                            }
                                                        </div>
                                                        <button type="button" className="btn btn-primary pull-right d-sm-block d-md-none" onClick={() => toggleFilter('filter')}><FontAwesomeIcon icon={['far', 'sliders-h']} className="lot-icons location-icon" /> Filter</button>
                                                    </React.Fragment>
                                                </div>
                                            </div>

                                            {this.state.myVans === true ?
                                                <div className="col-12">
                                                    <div className="card-deck pt-3">
                                                        <WatchList watchList={this.state.watchList}
                                                            auctionId={this.state.auction.id}
                                                            bidIncrement={this.state.auction.bidIncrement}
                                                            placeBid={this.placeBid}
                                                            buyItNow={this.state.buyItNow}
                                                            addToWatchList={this.addToWatchList}
                                                            removeFromWatchList={this.removeFromWatchList}
                                                            auctionState={this.state.auction.state}
                                                            isBuyItnowSale={this.state.auction.stateDescription}
                                                            theme={theme} />
                                                    </div>
                                                </div> :
                                                <SearchFilters updateFilter={this.updateFilter}
                                                    loadMore={this.loadMore}
                                                    auctionId={this.state.auction.id}
                                                    data={this.state.filteredLots}
                                                    bidIncrement={this.state.auction.bidIncrement}
                                                    placeBid={this.placeBid} buyItNow={this.buyItNow}
                                                    facets={this.state.facets} hasMore={this.state.hasMore}
                                                    hideEnded={this.state.hideEnded}
                                                    updateHideEnded={this.updateHideEnded}
                                                    watchList={this.state.watchList}
                                                    addToWatchList={this.addToWatchList}
                                                    removeFromWatchList={this.removeFromWatchList}
                                                    myVans={this.state.myVans}
                                                    openMyVans={this.openMyVans}
                                                    auctionState={this.state.auction.state}
                                                    isBuyItnowSale={this.state.auction.stateDescription}
                                                    addToPageHistory={this.props.addToPageHistory}
                                                    theme={theme}
                                                />
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        )
                    }}</ThemeContext.Consumer>
                );
            default:
                return <Loading />
        }
    }
}

export default BuyNow;