import React, { Component } from 'react';
import { formatMoney } from '../../../Shared/Utilities/FormatMoney';

class Stats extends Component {
    render() {
        return (
            <div>
                <p>You've participated in {this.props.stats.auctionsParticipatedIn} auctions</p>
                <p>You've placed {this.props.stats.averageBidsPerAuction} bids on average per auction</p>
                <p>The average price of lots you've won is £{formatMoney(this.props.stats.averagePriceOfLotsWon)}</p>
                <p>You've placed a total of {this.props.stats.totalBidsPlaced} bids across all auctions</p>

            </div>
        );
    }
}

export default Stats;