import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ThanksForEnquiring extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 my-4">
                        <div className="row d-block form-container mx-0 mt-4 text-center">
                            <FontAwesomeIcon icon={['fas', 'thumbs-up']} className="thanks-icon" />
                            <h4>Thank you for your enquiry</h4>
                            <p>A member of our team will contact you shortly.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThanksForEnquiring;