import React from 'react';
import { isAllowed } from '../../../../Shared/Utilities/AuthUtilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LotCardHeader = (props) => {
    return (
        <div className="card-header px-3" style={props.theme.lotCardHeader}>
            <div className="row no-gutters">
                <div className="col head">
                    {/*if it a buy now auction - dont display lot information */}
                    {props.auctionState === 0 ?
                        <h5>{props.vehicle.registrationYear} {props.vehicle.make} {props.vehicle.model} {props.vehicle.derivative} ({props.vehicle.registrationNumber})</h5>
                        :
                        <h5>Lot {props.lot.ordinal} - {props.vehicle.registrationYear} {props.vehicle.make} {props.vehicle.model} {props.vehicle.derivative} ({props.vehicle.registrationNumber})</h5>
                    }
                </div>
                <div className="col-1 text-right">
                    {
                        isAllowed('Buyer') &&
                        <>
                            {props.lotState.watchListUserIds.includes(props.userId) ?
                                <FontAwesomeIcon icon={['fas', 'star']} className="star-icon" style={props.theme.icons} onClick={() => props.removeFromWatchList(props.lot.id)} /> :
                                <FontAwesomeIcon icon={['fal', 'star']} className="star-icon" style={props.theme.icons} onClick={() => props.addToWatchList(props.lot.id)} />
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default LotCardHeader;