import React, { Component } from 'react';
import Error from '../../Shared/Common/Error';
import Loading from '../../Shared/Common/Loading';
import { apiPost } from '../../Shared/Utilities/ApiFetch';
import './ConfirmAccount.css';

const PageContent = (props) => {
    return(
        <div className="row">
                <div className="col-12">
                    <p className="error">{props.response}</p>
                <div className="form-group">
                        <label>Password</label>
                        <input value={props.password} type="password" id="password" name="password" className="form-control" onChange={props.handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Repeat Password</label>
                        <input value={props.passwordRepeat} type="password" id="passwordRepeat" name="passwordRepeat" className="form-control" onChange={props.handleChange} />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" onClick={props.handleSubmit}>Complete registration</button>
                    </div>
                </div>
            </div>
    )
}

class ConfirmAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', 
            token: '', 
            password: '', 
            passwordRepeat: '',
            response: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
        this.setState({componentState: "pageContent",
        token: this.props.match.params.token, })
    }
    
    handleSubmit(){
        let dataToPost = {
            token: this.state.token,
            password: this.state.password,
            passwordRepeat: this.state.passwordRepeat
        }

        apiPost('/api/Admin/confirmAccount', false, dataToPost)
        .then(result => 
            { 
                if(result.body.succeeded)
                {
                    this.props.history.push('/login');
                }
                else{
                    this.setState({response: result.body.message})
                }        
        }
        );
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return(<PageContent
                    handleChange={this.handleChange}
                    password={this.state.password}
                    passwordRepeat={this.state.passwordRepeat}
                    handleSubmit={this.handleSubmit}
                    response={this.state.response}
                />);
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default ConfirmAccount;