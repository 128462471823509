import React, { Component } from 'react';
import Error from '../../Shared/Common/Error';
import Loading from '../../Shared/Common/Loading';
import { apiPost } from '../../Shared/Utilities/ApiFetch';
import { Link } from 'react-router-dom';


const PageContent = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 my-3">
                    <div className="row form-container mx-0 mt-4">
                        <div className="col-12">
                            <div className="mt-2">
                                <h4>Contact Us</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" id="firstName" name="firstName" className="form-control" onChange={props.handleChange} />
                                <p id="FirstNameError" className="errorMessage"></p>
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" id="lastName" name="lastName" className="form-control" onChange={props.handleChange} />
                                <p id="LastNameError" className="errorMessage"></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" id="email" name="email" className="form-control" onChange={props.handleChange} />
                                <p id="EmailError" className="errorMessage"></p>
                            </div>
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="text" id="phoneNumber" name="phoneNumber" className="form-control" onChange={props.handleChange} />
                                <p id="PhoneNumberError" className="errorMessage"></p>
                            </div>
                        </div>

                        <div className="col-12">

                            <div className="form-group">
                                <label>Enquiry</label>
                                <textarea type="text" id="enquiry" name="enquiry" className="form-control" onChange={props.handleChange} />
                                <p id="EnquiryError" className="errorMessage"></p>
                            </div>

                            <div className="col-12 grey-box grey-box--bottom mb-3">
                                <p>How can we contact you?</p>
                                <div className="form-check">
                                    <input onChange={props.handleCheck} type="checkbox" checked={props.state.canBeContactedByEmail} id="canBeContactedByEmail" name="canBeContactedByEmail" />
                                    <label className="form-check-label">Email</label>
                                </div>

                                <div className="form-check">
                                    <input onChange={props.handleCheck} type="checkbox" checked={props.state.canBeContactedBySms} id="canBeContactedBySms" name="canBeContactedBySms" />
                                    <label className="form-check-label">Sms</label>
                                </div>

                                <div className="form-check">
                                    <input onChange={props.handleCheck} type="checkbox" checked={props.state.canBeContactedByTelephone} id="canBeContactedByTelephone" name="canBeContactedByTelephone" />
                                    <label className="form-check-label">Telephone</label>
                                </div>
                                <p className="privacy-text mb-2 mt-3">Van Monster take data privacy seriously and will only use your information in accordance with our <Link to='/PrivacyPolicy'>privacy policy</Link> and you can unsubscribe at any time.</p>
                                <div>
                                    <button className="btn btn-success float-right mb-md-3" onClick={props.handleSubmit}>Enquire Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            enquirey: "",
            canBeContactedByEmail: false,
            canBeContactedBySms: false,
            canBeContactedByTelephone: false,
            response: { errors: [] }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

    }

    componentDidMount() {
        this.setState({ componentState: "pageContent" })
    }

    handleSubmit() {

        if (this.state.response !== null && this.state.response.errors.length > 0) {
            this.clearErrors();
        }

        let dataToPost = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            enquiry: this.state.enquiry,
            canBeContactedByEmail: this.state.canBeContactedByEmail,
            canBeContactedByTelephone: this.state.canBeContactedByTelephone,
            canBeContactedBySms: this.state.canBeContactedBySms
        }

        apiPost("/api/ContactUs", false, dataToPost)
            .then(result => this.setState({ componentState: "pageContent", response: result.body },
                () => {
                    if (this.state.response.success) { this.props.history.push('/ThanksForEnquiring') }
                    else {
                        this.handleErrors()
                    }
                }
            ))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    handleCheck(evt) {
        let name = evt.target.name;
        this.setState({ [name]: !this.state[name] })
    }

    handleChange(evt) {
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState({ [name]: value })
    }

    handleErrors() {
        if (this.state.response.errors) {
            this.state.response.errors.forEach(
                (errorObject) => {
                    let el = document.getElementById(`${errorObject.key}Error`);
                    let input = document.getElementById(`${errorObject.key}`);

                    if (el !== null) {
                        el.innerHTML += errorObject.error
                    }

                    if (input !== null) {
                        input.classList.add("errorBorder");
                    }
                }
            )
        }
    }

    clearErrors() {
        if (this.state.response.errors) {
            this.state.response.errors.forEach(
                (errorObject) => {
                    let el = document.getElementById(`${errorObject.key}Error`);
                    let input = document.getElementById(`${errorObject.key}`);

                    if (el !== null) {
                        el.innerHTML = ""
                    }

                    if (input !== null) {
                        input.classList.remove("errorBorder");
                    }
                }
            )
        }
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    handleCheck={this.handleCheck}
                    state={this.state}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default ContactUs;