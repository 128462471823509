import React from 'react';
import Assist40Logo from "../../../../Images/assist40small.png";
import Img from 'react-image';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const LotCardImage = (props) => {
    return (
        <div className="img-container col-md-12 col-lg-4">
            <div className="img-label--top">
                {props.vehicle.vendorLogoUrl &&
                    <span className="badge badge-light m-0 vendor-logo">
                        <img src={props.vehicle.vendorLogoUrl} title="Vendor" className="float-right" alt="vendor" />
                    </span>
                }
            </div>
            <div className="img-label--bottom">
                {props.vehicle.hasAssist40 &&
                    <a href={props.vehicle.assist40Url} className="badge badge-light m-0" target="_blank" rel="noopener noreferrer">
                        <img src={Assist40Logo} title="Assist40" width="75" className="float-right" alt="assist40" />
                    </a>
                }
            </div>
            <Img onLoad={props.handleImageLoaded} src={props.vehicle.thumbnailUrl} className="lot-img" alt={props.vehicle.registrationNumber} />
            <Link to={props.isBuyItnowSale === "Buy Now" ? `/BuyNow/${props.vehicle.id}` : `/AuctionVehicle/${props.auctionId}/${props.vehicle.id}`}>
                <div className="overlay">
                    <span><h4>Click to view</h4></span>
                </div>
            </Link>
        </div>
    );
};

export default LotCardImage;