import React from 'react';
import moment from 'moment';
import './Video.css';

const VideoCard = (props) => {
    return (
        <React.Fragment>
            <div className="col-md-4 col-sm-6 col-xs-12 my-3">
            <div className="grey-box p-3 h-100">
                <h5 className="mb-3">{props.video.title}</h5>
                <div className="video-wrapper">
                    <img src={props.video.thumbnail} onClick={() => props.playVideo(props.video.id)} alt={props.video.title} />
                </div>
                <p className="video-category">{props.video.category}</p>
                <p><small>Updated: {moment(props.video.updatedDate).format('D MMM YYYY')}</small></p>
                <p>{props.video.description}</p>
            </div>
            </div>
        </React.Fragment>
    );
};

export default VideoCard;