import React, { useState, useEffect } from 'react';
import Loading from '../../Shared/Common/Loading.js';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import RawHtmlContent from '../Home/RawHtmlContent';
import '../Home/Styles/dynamicContent.css';

export default function DynamicContent(props) {
  const [content, setContent] = useState('');
  useEffect(() => {
    let contentUrl = `/api/content/${props.keyword}`
    let authenticationRequired = false;
    apiGet(contentUrl, authenticationRequired)
      .then(result => {
        if (result.body) {
          setContent(result.body.blob);
        }
      });
  }, [props.keyword, content]);

  if (isLoading(content)) {
    return <Loading />;
  }
  return <RawHtmlContent html={content} />;
}
function isLoading(content) {
  return content === null;
}