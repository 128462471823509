import React, { Component } from 'react';
import Error from '../../../Shared/Common/Error';
import Loading from '../../../Shared/Common/Loading';
import { apiPost } from '../../../Shared/Utilities/ApiFetch';

const PageContent = (props) => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 grey-box my-4 text-center">
                    <h1 className="text-center mt-5">Reset password</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">

                    <p>
                        Here are the reset password steps.
                </p>
                    <ol>
                        <li>Enter the email address that you registered with</li>
                        <li>If your email address matches an account we will <b>email</b> you with a link to reset your password</li>
                        <li>Visit the link and reset your password</li>
                    </ol>
                    <div className="form-group">
                        <label>Please enter your email address</label>
                        <input value={props.email} type="text" id="email" name="email" className="form-control" onChange={props.handleChange} />
                    </div>
                    {
                        props.result && props.result.message && props.result.message.length > 0 &&
                        <div className={"alert " + (props.result.success ? 'alert-success' : 'alert-danger')} role="alert">
                            <p>{props.result.message}</p>
                        </div>
                    }
                    <div className="form-group">
                        {props.isSendingForgottenPassword ?
                            <button className="btn btn-success" disabled={true}>Processing ...</button>
                            :
                            <button onClick={props.handleSubmit} className="btn btn-success">Reset password</button>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            result: null,
            isSendingForgottenPassword: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ componentState: "pageContent" })
    }

    handleSubmit() {
        this.setState({ isSendingForgottenPassword: true, result: null });
        let dataToPost = {
            email: this.state.email
        }

        apiPost('/api/Account/ForgotPassword', false, dataToPost)
            .then((result) => { this.setState({ result: result.body, componentState: "pageContent", isSendingForgottenPassword: false }); })
            .catch(error => this.setState({ componentState: "error", error: error, isSendingForgottenPassword: false }));
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return (<PageContent
                    handleChange={this.handleChange}
                    email={this.state.email}
                    handleSubmit={this.handleSubmit}
                    result={this.state.result}
                    isSendingForgottenPassword={this.state.isSendingForgottenPassword}
                />);
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default ForgotPassword;