import React, { Component } from 'react';
import SearchFilters from './SearchFilters';
import * as signalR from '@aspnet/signalr';
import './Styles/Auction.scss';
import moment from 'moment';
import { baseUrl } from '../../../Shared/Utilities/AppConfig';
import { apiGet, apiPost, apiDelete } from '../../../Shared/Utilities/ApiFetch';
import AuctionCountdown from './AuctionCountdown';
import WatchList from './WatchList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Loading from '../../../Shared/Common/Loading';
import { isLoggedIn, isAllowed } from '../../../Shared/Utilities/AuthUtilities';
import queryString from 'query-string';
import { tryScrollToElement } from '../../../Shared/Utilities/ScrollUtilities';
import BackgroundImage from '../../../Images/30ebd0be-3c15-426f-9176-4d29ce937737.png';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../ThemeContext'
import Button from '../../../Shared/Common/Button';
import Message from '../../../Shared/Common/Message';
import { TrackView } from '../../../Shared/Utilities/Tracking';
import Error from '../../../Shared/Common/Error';

class Auction extends Component {

    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = {
            auction: {},
            lots: [],
            filteredLots: [],
            skip: 0,
            take: 10,
            searchUrl: this.props.match.params.id !== undefined ? `/api/auction/search/${this.props.match.params.id}` : `/api/auction/search/${this.props.buyNowId}`,
            initialUrl: this.props.match.params.id !== undefined ? `/api/auction/search/${this.props.match.params.id}` : `/api/auction/search/${this.props.buyNowId}`,
            selectedFacets: [],
            hasMore: false,
            facets: [],
            connected: false,
            user: null,
            auctionId: this.props.match.params.id !== undefined ? this.props.match.params.id : this.props.buyNowId,
            watchList: [],
            hideEnded: false,
            myVans: false,
            componentState: "loading",
            allowLoadMoreResults: true,
            loadMoreResultsErrorCount: 0,
            maximumLoadMoreErrors: 10
        };
    }

    componentDidMount() {
        this.getAuction();
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(baseUrl() + '/bidhub')
            .build();

        this.messageHandler(connection);
        this.startConnection(connection);
        // this.updateUser();

        if (isLoggedIn()) {
            this.props.isUserLockedOut();
            this.getFavourites();
        }
        TrackView(this.state.auctionId);
    }

    componentWillUnmount() {
        this.props.clearTheme();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lastNotification.dateTimeStamp !== this.props.lastNotification.dateTimeStamp) {
            if (this.props.lastNotification.table === "auction") {
                if (this.props.lastNotification.tableId === this.state.auction.id) {
                    this.performSearch();
                }
            }
        }
    }

    getFavourites = () => {
        if (isAllowed('Buyer')) {
            apiGet('/api/WatchList?auctionId=' + this.state.auctionId, true)
                .then(result => {
                    this.addLotStateSetTimestamp(result.body); 
                    this.setState({ watchList: result.body });
                });
        }
    }

    addToWatchList = (lotId) => {
        if (isAllowed('Buyer')) {
            apiPost('/api/WatchList', true, { lotId: lotId })
                .then(response => {
                    this.updateLotState(response.body); this.getFavourites();
                });
        }
    }

    removeFromWatchList = (lotId) => {
        if (isAllowed('Buyer')) {
            apiDelete('/api/WatchList', true, { lotId: lotId })
                .then(response => { this.updateLotState(response.body); this.getFavourites(); });
        }
    }

    updateHideEnded = (e) => {
        this.setState({ hideEnded: e.target.checked }, () => this.filterLots());
    }

    filterLots = () => {
        const lots = this.state.lots;
        if (this.state.hideEnded && isLoggedIn()) {
            this.setState({ filteredLots: lots.filter(function (lot) { return lot.lotState.sold == null }), componentState: 'auction' });
        } else {
            this.setState({ filteredLots: lots, componentState: 'auction' });
        }
    }

    getAuction = () => {
        const querystringValues = queryString.parse(window.location.search);
        if (querystringValues.pos !== null && querystringValues.pos > 0) {
            this.getAuctionByPosition(querystringValues.pos);
        } else {
            this.fetchAuction(`${this.state.searchUrl}/${this.state.take}/${this.state.skip}`);
        }
    }

    getAuctionByPosition = (position) => {
        //If we have a postion in the auction specified,load, and find the relevant result
        const newTake = Math.ceil(position / this.state.take) * this.state.take;
        this.fetchAuctionToPosition(`${this.state.searchUrl}/${newTake}/${this.state.skip}`, position, newTake);
    }

    fetchAuctionToPosition = (url, position, newTake) => {
        apiGet(url, true)
            .then(result => {
                this.addLotStateSetTimestamp(result.body.lots);
                this.setState({
                    auctionSetTimestamp: Date.now(),
                    auction: result.body.auction,
                    facets: result.body.facets,
                    lots: result.body.lots,
                    hasMore: result.body.lots.length === newTake,
                    skip: (newTake - this.state.take)
                }, () => {
                    this.filterLots();
                    this.props.getTheme(this.state.auction.organisationId);
                })
            }).then(
                () => {
                    tryScrollToElement("lot_" + position, ['navBar'])
                }
            ).catch(e => {
                this.setState({ error: e, componentState: 'error' });
            });
    }

    fetchAuction = (url) => {
        apiGet(url, true)
            .then(result => {
                this.addLotStateSetTimestamp(result.body.lots);
                this.setState({
                    auctionSetTimestamp: Date.now(),
                    auction: result.body.auction,
                    facets: result.body.facets,
                    lots: result.body.lots,
                    hasMore: result.body.lots.length === this.state.take,
                }, () => {
                    this.filterLots();
                    this.props.getTheme(this.state.auction.organisationId);
                })
            }).catch(e => {
                this.setState({ error: e, componentState: 'error' });
            });
    }

    performSearch = () => {
        this.setState({ skip: 0 });
        let currentUrl = `${this.state.searchUrl}/${this.state.take}/0`;
        let newSearchUrl = currentUrl + this.queryStringBuilder();
        this.fetchAuction(newSearchUrl);
    }

    queryStringBuilder = () => {
        if (this.state.selectedFacets.length === 0) {
            return '';
        }

        let queryString = '?facets=';
        let other = '&facets=';
        let pos = 1;

        const facets = this.state.selectedFacets.map((sf) => (sf.values.map((f) => (sf.name + "~" + f))));
        facets.forEach((x) => {
            if (pos === 1) {
                queryString = queryString + x;
            } else {
                queryString = queryString + other + x;
            }
            pos++;
        });

        return queryString;
    }

    updateFilter = (facetName, values) => {
        this.setState({ selectedFacets: this.state.selectedFacets.filter((sf) => sf.name !== facetName) }, () => {
            if (values.length > 0) {
                const newFacet = {
                    name: facetName,
                    values: values
                };
                this.setState({ selectedFacets: [...this.state.selectedFacets, newFacet] }, () => this.performSearch());
            } else {
                this.performSearch();
            }
        });
    }

    startConnection = (connection) => {
        connection.start()
            .then(c => {
                this.setState({ connected: true })
            })
            .catch(e => {
                this.setState({ connected: false })
                setTimeout(() => this.startConnection(connection), 5000);
            });
    }

    messageHandler = async (connection) => {
        connection.on("BidEvent", (lotState) => this.updateLotState(lotState));

        connection.onclose((error) => {
            this.setState({ connected: false });
            this.startConnection(connection);
        });
    }

    updateLotState = (lotState) => {
        this.updateAuctionLotState(lotState, this.state.lots, "lots");
        this.updateAuctionLotState(lotState, this.state.watchList, "watchList");
    }

    updateAuctionLotState = (lotState, lots, statePropertyName) => {
        let existingLots = lots;
        let elementPos = existingLots.map(function (x) { return x.id; }).indexOf(lotState.lotId);
        if (elementPos !== -1) {
            existingLots[elementPos].lotState = lotState;
            existingLots[elementPos].lotStateSetTimestamp = Date.now()
            this.setState({ [statePropertyName]: existingLots })
        }
    }

    addLotStateSetTimestamp = (lots) => {
        lots.length > 0 &&
            lots.forEach((lot) => { lot.lotStateSetTimestamp = Date.now() })
    }

    loadMore = () => {
        if (this.state.allowLoadMoreResults) {
            this.setState({ allowLoadMoreResults: false }, () => this.loadResults());
        }
    }

    loadResults = () => {
        let newSkip = this.state.skip + this.state.take;
        let newUrl = `${this.state.searchUrl}/${this.state.take}/${newSkip}${this.queryStringBuilder()}`;
        apiGet(newUrl, true)
            .then(result => {
                this.addLotStateSetTimestamp(result.body.lots);
                this.setState({ 
                    skip: newSkip, 
                    lots: [...this.state.lots, ...result.body.lots], 
                    hasMore: result.body.lots.length === this.state.take, 
                    allowLoadMoreResults: true, 
                    loadMoreResultsErrorCount: 0 }, () => this.filterLots())
            }).catch(error => {
                this.setLoadMoreErrorCount();
                this.resetAllowLoadMoreResults();
            });
    }

    resetAllowLoadMoreResults = () => {
        //Wait before allowing the loading of results more results
        setTimeout(() => {
            this.setState({ allowLoadMoreResults: true })
        }, this.state.loadMoreResultsErrorCount * 1000);
    }

    setLoadMoreErrorCount = () => {
        //Set the error count to a maximum -- This is so we can increment the amount of time we wait to try again
        if (this.state.loadMoreResultsErrorCount < this.state.maximumLoadMoreErrors) {
            this.setState({ loadMoreResultsErrorCount: this.state.loadMoreResultsErrorCount + 1 });
        }
    }

    //Is this even used???
    // updateUser = () => {
    //     apiGet(`/api/account/userBidInfo/${this.state.auctionId}`, true)
    //         .then(result => this.setState({ user: result.body }));
    // }

    openMyVans = () => {
        let open = this.state.myVans === false ? true : false;
        this.setState({ myVans: open });
    }

    setMyVans = () => {
        this.setState({ myVans: !this.state.myVans })
    }

    getAuctionContentKeyword() {
        return "auction-" + this.state.auctionId
    }

    render() {
        let image = this.state.auction.auctionHeaderImageUrl !== null ? this.state.auction.auctionHeaderImageUrl : BackgroundImage;
        const bgImage = {
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(' + image + ')',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        const toggleFilter = (id) => {
            let el = $(`#${id}`);
            el.toggleClass('d-none');

            $('html,body').animate({
                scrollTop: $(".mobile-buttons").offset().top - 68
            }, 'slow');
        }

        switch (this.state.componentState) {
            case "error":
                return (
                    <Error error={this.state.error}/>
                );
            case "auction":
                return (
                    <ThemeContext.Consumer>{(theme) => {
                        return (
                            <div className="auction content" style={theme.auction}>
                                {
                                    this.state.auction.hasOwnProperty("id") &&
                                    <>
                                        <div id="scroller-anchor"></div>
                                        <div className="row no-gutters">
                                            <div className="col-12 px-0 pb-3">
                                                <div className="py-4 auction-img " style={bgImage} >
                                                    <div className="col-12 auction-title py-2 text-center">
                                                        <div className="col">
                                                            {
                                                                [0, 1, 4].includes(this.state.auction.state) &&
                                                                (this.state.connected ?
                                                                    <span style={theme.themePrimaryBgColor} className="online badge-pill badge-success statusBadge">Live</span> :
                                                                    <span className="offline badge-pill badge-danger statusBadge">Offline</span>)
                                                            }
                                                        </div>

                                                        <h2 style={theme.title} className="pt-2">{this.state.auction.name} </h2>
                                                        <h3 style={theme.description} className="auction-description">{this.state.auction.description}</h3>
                                                        <div className="col-12 text-center auction-info">
                                                            <p><strong>Status: </strong><span style={theme.themePrimaryFontColor} className={[0, 1, 4].includes(this.state.auction.state) ? "open" : "offline"}> {this.state.auction.stateDescription}</span> </p>
                                                        </div>
                                                    </div>

                                                    {this.state.auction.state !== 0 &&
                                                        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
                                                            <AuctionCountdown countdownExpired={this.performSearch}
                                                                auctionSetTimestamp={this.state.auctionSetTimestamp}
                                                                startMilliSeconds={this.state.auction.startMilliSeconds}
                                                                endMilliSeconds={this.state.auction.endMilliSeconds}
                                                                theme={theme} />
                                                        </div>
                                                    }

                                                    <div className="auction-info">
                                                        <div className="row no-gutters">
                                                            <div className="col-12 col-lg-4 offset-lg-2 text-center text-lg-right p-1">
                                                                {
                                                                    this.state.auction.state > 0 &&
                                                                    <>
                                                                        <p><strong>Starts:</strong> {moment(this.state.auction.startDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="col-12 col-lg-4 text-center text-lg-left p-1">
                                                                {
                                                                    this.state.auction.state > 0 &&
                                                                    <>
                                                                        <p><strong>Ends:</strong> {moment(this.state.auction.endDateTime).format('DD/MM/YYYY HH:mm')}</p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-12 text-center mt-3">
                                                            <Link className="" to={`/Catalogue/${this.state.auction.id}`} target="_blank">
                                                                <Button buttonText="View Catalogue" divClass="btn btn-success" theme={theme} buttonType={theme.btnSuccess} buttonTypeHover={theme.btnSuccessHover} />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pb-3">
                                            <div className="container">
                                                <Message contentKeyword={this.getAuctionContentKeyword()} />
                                            </div>
                                        </div>

                                        <div className={`pb-2 mobile-buttons text-md-center ${!isLoggedIn() ? "d-md-none" : ""}`} id="mobileButtons">
                                            <div className="container">
                                                <div className={`mx-0 px-0 ${!isLoggedIn() ? "mb-3" : ""}`}>
                                                    <div className="btn-group" role="group" aria-label="Basic example">
                                                        {isLoggedIn() &&
                                                            <>
                                                                <Button buttonText="Auction" divClass="btn btn-success" theme={theme} buttonType={theme.btnSuccess} buttonTypeHover={theme.btnSuccessHover} clickFunc={() => this.setMyVans()} />
                                                                <Button buttonText="My Auction" divClass="btn btn-primary" theme={theme} buttonType={theme.btnPrimary} buttonTypeHover={theme.btnPrimaryHover} clickFunc={() => this.setMyVans()} icon={['fas', 'star']} />
                                                            </>
                                                        }
                                                    </div>
                                                    <button type="button" className="btn btn-primary float-right d-sm-block d-md-none" onClick={() => toggleFilter('filter')}><FontAwesomeIcon icon={['far', 'sliders-h']} className="lot-icons location-icon" /> Filter</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container">
                                            {this.state.myVans === true ?
                                                <div className="col-12">
                                                    <div className="card-deck pt-3">
                                                        <WatchList
                                                            watchList={this.state.watchList}
                                                            auctionId={this.state.auction.id}
                                                            bidIncrement={this.state.auction.bidIncrement}
                                                            addToWatchList={this.addToWatchList}
                                                            removeFromWatchList={this.removeFromWatchList}
                                                            auctionState={this.state.auction.state}
                                                            isBuyItnowSale={this.state.auction.stateDescription}
                                                            getFavourites={this.getFavourites}
                                                            theme={theme}
                                                        />
                                                    </div>
                                                </div> :
                                                <SearchFilters
                                                    updateFilter={this.updateFilter}
                                                    loadMore={this.loadMore}
                                                    auctionId={this.state.auction.id}
                                                    data={this.state.filteredLots}
                                                    bidIncrement={this.state.auction.bidIncrement}
                                                    facets={this.state.facets}
                                                    hasMore={this.state.hasMore}
                                                    hideEnded={this.state.hideEnded}
                                                    updateHideEnded={this.updateHideEnded}
                                                    watchList={this.state.watchList}
                                                    addToWatchList={this.addToWatchList}
                                                    removeFromWatchList={this.removeFromWatchList}
                                                    myVans={this.state.myVans}
                                                    openMyVans={this.openMyVans}
                                                    auctionState={this.state.auction.state}
                                                    isBuyItnowSale={this.state.auction.stateDescription}
                                                    addToPageHistory={this.props.addToPageHistory}
                                                    theme={theme}
                                                    getFavourites={this.getFavourites}
                                                />
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        )
                    }}
                    </ThemeContext.Consumer>
                );
            default:
                return <Loading />
        }
    }
}

export default Auction;