import React, { Component } from 'react';
import './Styles/Error.css';
import { Link } from 'react-router-dom';

const ErrorComponent = (props) => {
    return (
        <>
            <span className="fa fa-exclamation-circle fa-5x green"></span>
            <h1>{props.error.statusCode} {props.error.statusText}</h1>
            <p>{props.message}</p>
            <br />
            {props.refresh ?
                <button onClick={props.refresh} className="btn btn-primary">Refresh</button>
                :
                <Link to={props.buttonLink}><button className="btn btn-primary">{props.buttonText}</button></Link>
            }
        </>
    )
}

const DefaultComponent = (props) => {
    return (
        <>
            <span className="fa fa-exclamation-circle fa-5x green"></span>
            <h1>{props.status}</h1>
            <p>{props.message}</p>
            <br />
            <button onClick={props.refresh} className="btn btn-primary">Refresh</button>
        </>
    )
}

class Error extends Component {

    refresh() {
        window.location.reload()
    }
    /* Some props.error properties to use:
        * ok: bool
        * redirected: bool
        * status: int
        * statusText: string
        * url: string
     */

    renderComponentState() {
        switch (this.props.error.status) {
            case 404:
                return <ErrorComponent
                    error={this.props.error}
                    message="The page you are looking for could not be found."
                    buttonText="Return Home"
                    buttonLink="/"
                />;
            case 401:
                return <ErrorComponent
                    error={this.props.error}
                    message="You are not authorized to view this page"
                    buttonText="Login"
                    buttonLink="/Login"
                />;
            case 400:
                return <ErrorComponent
                    error={this.props.error}
                    message="There was a problem processing your request"
                    buttonText="Try again"
                    buttonLink="/"
                    refresh={this.props.refresh ? this.props.refresh : this.refresh}
                />;
            case 500:
                return <ErrorComponent
                    error={this.props.error}
                    message="There was a problem processing your request"
                    buttonText="Try again"
                    buttonLink="/"
                    refresh={this.props.refresh ? this.props.refresh : this.refresh}
                />;
            case 403:
                return <ErrorComponent
                    error={this.props.error}
                    message="You are not authorized to view this page"
                    buttonText="Home"
                    buttonLink="/"
                />;
            default:
                return <DefaultComponent
                    refresh={this.props.refresh ? this.props.refresh : this.refresh}
                    status="Something went wrong"
                    message="Please try refreshing the page using the button below"
                />;
        }
    }

    render() {
        return (
            <div className="row pt-5 pb-5 mx-auto">
                <div className="col-md-8 offset-md-2 text-center">
                    {this.renderComponentState()}
                </div>
            </div>
        );
    }
}

export default Error;