import { Component } from 'react';
import { withRouter } from "react-router-dom";

class ServerRedirect extends Component {
    componentDidMount() {
        this.redirect();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.redirect();   
        }
    }

    redirect() {
        const path = (/#!(\/.*)$/.exec(this.props.location.hash) || [])[1];
        if (path) {
            this.props.history.push(path);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ServerRedirect);
