import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Error from '../../../Shared/Common/Error';
import Loading from '../../../Shared/Common/Loading';
import { apiPost } from '../../../Shared/Utilities/ApiFetch';
import Breadcrumbs from '../../../Shared/Common/Breadcrumbs';

const PageContent = (props) => {
    return (
        <React.Fragment>
            {!props.tokenDetails.resultDto.expired ?
                <div className="row">
                    <Breadcrumbs pageCrumb={"Reset password"} />
                    <div className="col-12">
                        <h4>Hi {props.tokenDetails.resultDto.user.firstName}, please set a new password below.</h4>
                        <h4>{props.response.message}</h4>
                        <div className="form-group ">
                            <label>Password</label>
                            <input autoComplete="off" onChange={props.handleChange} placeholder="Password" type="password" id="password" name="password" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Repeat Password</label>
                            <input value={props.repeatPassword} autoComplete="off" onChange={props.handleChange} placeholder="Repeat password" type="password" id="repeatPassword" name="repeatPassword" className="form-control" />
                        </div>
                        <div className="form-group">
                            <button onClick={props.handleSubmit} className="btn btn-primary float-right">Update password</button>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <Breadcrumbs pageCrumb={"Reset password"} />
                    <div className="col-12">
                        <h1>Sorry, your token has expired.</h1>
                        <p>Please use the button below to try again</p>
                        <Link to="/ForgotPassword"><button className="btn btn-primary">Forgot password</button></Link>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            repeatPassword: '',
            token: '',
            response: '',
            tokenDetails: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({ [name]: value })
    }

    componentDidMount() {
        this.setState({ token: this.props.match.params.token },
            () => { this.getTokenDetails() });
    }

    getTokenDetails() {
        let dataToPost = {
            token: this.state.token
        }
        apiPost(`/api/Account/GetTokenDetails`, false, dataToPost)
            .then((result) => this.setState({ tokenDetails: result.body, componentState: "pageContent" }
            ))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    handleSubmit() {
        let dataToPost = {
            token: this.state.token,
            password: this.state.password,
            repeatPassword: this.state.repeatPassword
        }

        apiPost('/api/Account/ResetPassword', false, dataToPost)
            .then((result) => this.setState({ response: result.body, componentState: "pageContent" }
                ,
                () => {
                    if (result.body.succeeded) {
                        this.props.history.push('/login')
                    }
                }))
            .catch(error => this.setState({ componentState: "error", error: error }));
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return (
                    <PageContent
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        response={this.state.response}
                        password={this.state.password}
                        repeatPassword={this.state.repeatPassword}
                        tokenDetails={this.state.tokenDetails}
                    />
                );
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default ResetPassword;