import React from 'react';
import { Link } from 'react-router-dom';

const LocalBreadcrumbs = (props) => {
    const pageCrumb = props.pageCrumb;
    const crumbs = props.crumbs;

    const sections = props.crumbs === undefined
        ? {}
        : crumbs.map((element, i) => {
            const title = element[0];
            let url = element[1];
            if (url.includes("{") && 
                url.includes("}") && 
                props.match) {
                const match = url.substring(
                    url.lastIndexOf("{") + 1,
                    url.lastIndexOf("}")
                );

                url = url.replace('{' + match + '}', props.match.params[match]);
            }

            return {
                key: i,
                content: (<Link to={`${url}`}>{title}</Link>)
            }
        });

    return (
        <div className="breadcrumbs">
            <Link to={'/'}>Home</Link>
            {
                sections.length > 0 &&
                sections.map(x => <span key={x.key}> > {x.content}</span>)
            }
            <span> > {pageCrumb}</span>
        </div>
    );
}

export default LocalBreadcrumbs;