export const MercedesTheme = {
    auction: {
        backgroundColor: '#f4f4f4',
    },

    themePrimaryFontColor: {
        color: '#00adef'
    },

    themePrimaryBgColor: {
        backgroundColor: '#00adef'
    },

    title: { color: '#fff' },
    description: { color: '#fff' },

    countdownBox: {
        borderRadius: 'unset',
        // backgroundColor: '#666',
    },

    countdownText: {
        color: '#333',
    },

    body: { backgroundColor: '#000' },

    searchFilter: {
        background: '#fff',
        color: '#333',
        borderRadius: 'unset',
        border: '1px solid #d7d7d7'
        // borderTop: '1px solid #d7d7d7',
        // borderBottom: '1px solid #d7d7d7',
    },

    searchFilterLabel: {
        color: '#333'
    },

    searchFacetLabel: {
        color: '#333',
        paddingBottom: '10px'
    },

    lotCard: {
        borderRadius: 'unset',
        // boxShadow: '0 2px 24px 0 #00000014',
        //border: 'none',
    },

    lotCardHeader: {
        color: '#00adef',
        backgroundColor: '#fff'
    },

    lotCardFooter: {
        backgroundColor: '#fff'
    },

    icons: {
        color: '#333'
    },

    btnSuccess: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#00adef',
        borderColor: '#00adef',
        borderRadius: '0',
    },

    btnSuccessHover: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#0088c6',
        borderColor: '#0088c6',
        borderRadius: '0',
    },

    btnPrimary: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#333333',
        borderColor: '#333333',
        borderRadius: '0',
    },

    btnPrimaryHover: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#242424',
        borderColor: '#242424',
        borderRadius: '0',
    },

    btnSecondary: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#666666',
        borderColor: '#666666',
        borderRadius: '0',
    },

    btnSecondaryHover: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#424242',
        borderColor: '#424242',
        borderRadius: '0',
    },

    btnDanger: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#666666',
        borderColor: '#666666',
        borderRadius: '0',
    },

    btnDangerHover: {
        color: '#fff',
        fontWeight: '500',
        backgroundColor: '#424242',
        borderColor: '#424242',
        borderRadius: '0',
    },

    vehiclePageHeading: {
        color: '#00adef',
    },

    photoIndicator: { backgroundColor: '#00adef' },

    damageIcon: '#00adef',
}