import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Error from '../../Shared/Common/Error';
import Loading from '../../Shared/Common/Loading';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import queryString from 'query-string';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const PageContent = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 my-3">
                    <div className="row form-container mx-0 mt-4">
                        <div className="col-12">
                            <div className="mt-2">
                                <h4>Van Search</h4>
                            </div>
                        </div>

                        <div className="input-group">
                            <input type="text" id="search" name="search" className="form-control" placeholder="Search" value={props.state.search || ""} onChange={props.handleChange} />
                            <button className="btn btn-success" onClick={props.search}>
                                <i className="glyphicon glyphicon-align-left">
                                    <FontAwesomeIcon icon={['fal', 'search']} />
                                </i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 my-3">
                    {props.state.loading
                        ? <Loading />
                        : (props.state.response && props.state.response.success)
                            ? <React.Fragment>
                                <p>{props.state.response.vehicles.length} vehicles found.</p>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="head-text">State</TableCell>
                                            <TableCell className="head-text">Vehicle</TableCell>
                                            <TableCell className="head-text">Auction</TableCell>
                                            <TableCell className="head-text" align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.state.response.vehicles.map((v) =>
                                            <TableRow key={v.vehicleId + v.auctionId}>
                                                <TableCell>{v.lotStateDescription}</TableCell>
                                                <TableCell>Lot {v.lotNumber} - {v.vehicleMake} {v.vehicleModel} ({v.vehicleRegistrationNumber})<br />{v.vehicleDerivative}</TableCell>
                                                <TableCell>{v.auctionName} {v.auctionDescription}<br />{v.auctionState > 0 && moment(v.auctionStartDateTime).format('DD/MM/YYYY HH:mm') + " - " + moment(v.auctionEndDateTime).format('DD/MM/YYYY HH:mm')}</TableCell>
                                                <TableCell align="right"><Link onClick={() => props.updateHistory()} to={`/auction/${v.auctionId}?pos=${v.lotNumber}`}><button className="btn btn-success">View</button></Link></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                            : <p>No vehicles found.</p>
                    }
                </div>
            </div>
        </div>
    )
}

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentState: "pageContent",
            search: "",
            response: { errors: [] }
        }

        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.updateHistory = this.updateHistory.bind(this);
    }

    componentDidMount() {
        const querystringValues = queryString.parse(window.location.search);
        if (querystringValues.search !== null) {
            this.setState({ search: querystringValues.search }, () => this.search());
        }
    }

    componentWillUnmount() {
        clearTimeout(this.typingTimer);
    }

    search() {
        this.setState({ loading: true });

        apiGet("/api/Vehicle/search?search=" + this.state.search, true)
            .then(result => this.setState({ loading: false, response: result.body }))
            .catch(error => this.setState({ loading: false, error: error }));
    }

    handleChange(evt) {
        const name = evt.target.name;
        const value = evt.target.value;

        this.setState({ [name]: value });

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.search(); }, 750);
    }

    updateHistory() {
        const values = queryString.parse(window.location.search)
        values.search = this.state.search;
        this.props.addToPageHistory(window.location.pathname + '?' + queryString.stringify(values));
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    handleChange={this.handleChange}
                    search={this.search}
                    state={this.state}
                    updateHistory={this.updateHistory}
                />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default Search;