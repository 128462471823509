/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import { apiGet } from '../../Shared/Utilities/ApiFetch';
import './Styles/About.css';
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';*/

class BuyersFees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buyersFees: []
        };
    }

    componentDidMount() {
        apiGet('/api/lot/BuyersFees', true)
            .then(result => this.setState({ buyersFees: result.body }));
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 grey-box my-4 text-center">
                        <h1 className="text-center mt-5 mb-5">Buyers Fees</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <p>Van Monster Remarketing charges a fee on purchasing vehicles from the auction platform.</p>
                        <p><strong>Where buyers fees are applicable to a vehicle, the fees will be seen in the vehicle information and will be added to the sale invoice.</strong></p>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-12">
                        <div className="row row-eq-height mt-2">
                            <div className="col-sm-12 col-lg-4 mb-3">
                                <div className="services border p-3 h-100 rounded text-center">
                                    <FontAwesomeIcon icon={['fal', 'pound-sign']} className="services-icon" />
                                    <div className="mt-3">
                                        <h4>All Buyers Fees</h4>
                                        <table className="table">
                                            <thead>
                                                <tr><th>Selling Price</th><th>NET</th></tr>
                                            </thead>

                                            <tbody>
                                                {this.state.buyersFees.map((bf) =>
                                                    <tr key={bf.id}>
                                                        <td>{bf.description}</td>
                                                        <td>£{bf.fee}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
};

export default BuyersFees;