import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatMoney } from '../../../../Shared/Utilities/FormatMoney';

const ConfirmBidModal = (props) => {
    return (
        <Modal visible={props.showConfirmBidModal}>
        <div className="modal-header">
            <h5 className="modal-title">Place your bid</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={() => props.toggleConfirmBidModal()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div className="modal-body">
            {
                props.placeBidSuccess ?
                    <>
                        <p className="text-center"><FontAwesomeIcon icon={['fas', 'check']} className="bid-icon" /></p>
                        <p className="text-center">{props.placeBidSuccess}</p>
                    </>
                    :
                    <> {
                        props.placeBidError !== null ?
                            <>
                                <div className='text-center my-2'>
                                    <p className="errorMessage font-weight-bold">{props.placeBidError}</p>
                                </div>
                            </>
                            :
                            <div className='text-center font-weight-bold mb-3'>
                                <p>Are you sure you want to place the following bid?</p>
                                <h4 className='text-success'>£{formatMoney(props.amountToBid)}</h4>
                                <p>on Lot# {props.lot.ordinal}</p>
                            </div>
                    }
                    </>
            }
        </div>
        <div className="modal-footer d-flex justify-content-between">
            {
                props.placeBidSuccess ?
                    <button type="button" className="btn btn-success" onClick={() => props.closeConfirmBidModal()}>Close</button>
                    :
                    <>
                        <button type="button" className="btn btn-danger pull-left" onClick={() => props.toggleConfirmBidModal()}>Cancel</button>

                        {props.placeBidError === null &&
                            <>
                                {!props.disableBidButton ?
                                    <button type="button" className="btn btn-success pull-right" onClick={() => props.placeBid(props.id, true)}>Yes, Confirm bid</button>
                                    :
                                    <button type="button" className="btn btn-primary pull-right" disabled={true} >Processing...</button>
                                }
                            </>
                        }
                    </>
            }
        </div>
    </Modal>
    );
};

export default ConfirmBidModal;