import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import './Styles/Lot.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isAllowed, isLoggedIn } from '../../../Shared/Utilities/AuthUtilities';
import { formatMoney } from '../../../Shared/Utilities/FormatMoney';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import queryString from 'query-string';
import Button from '../../../Shared/Common/Button';
import { createBrowserHistory } from 'history';
import BidNowModal from './Modals/BidNowModal';
import ConfirmBidModal from './Modals/ConfirmBidModal';
import BuyNowModal from './Modals/BuyNowModal';
import LotCardHeader from './LotCard/LotCardHeader';
import LotCardImage from './LotCard/LotCardImage';
import LotNotification from './LotCard/LotNotification';
import LotCardBuyerInfo from './LotCard/LotCardBuyerInfo';
import LotCardBadges from './LotCard/LotCardBadges';
import LotCardPricing from './LotCard/LotCardPricing';
import { apiPost } from '../../../Shared/Utilities/ApiFetch';

const History = createBrowserHistory();

class Lot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageStatus: "loading",
            amountToBid: 0,
            showBidModal: false,
            showBuyItNowModal: false,
            disableBidButton: false,
            disableBuyNowButton: false,
            showConfirmBidModal: false
        };
    }

    componentDidMount() {
        if (isLoggedIn()) {
            this.setState({ amountToBid: this.minimumBid() });
            this.updateExtendedBiddingState();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (isLoggedIn() && prevProps.lotState.bidAmount !== this.props.lotState.bidAmount) {
            if (this.state.amountToBid < this.minimumBid()) {
                this.setState({ amountToBid: this.minimumBid() })
            }
        }
        if (isLoggedIn() && ((prevProps.lotState.state !== this.props.lotState.state) ||
            (prevProps.lotState.lotStateSetTimestamp !== this.props.lotState.lotStateSetTimestamp) ||
            (prevProps.lotState.extendedBiddingEndMilliSeconds !== this.props.lotState.extendedBiddingEndMilliSeconds))) {
            this.updateExtendedBiddingState();
        }
    }

    handleImageLoaded = () => {
        this.setState({ imageStatus: "loaded" });
    }

    handleChange = (event) => {
        let value = event.target.value;
        this.setState({ placeBidSuccess: null, placeBidError: null, amountToBid: value })
    }

    handleBuyItNowModalClick = () => {
        this.setState({ buyItNowSuccess: null, buyItNowError: null, showBuyItNowModal: !this.state.showBuyItNowModal, disableBuyNowButton: false })
    }

    handleBidNowModalClick = () => {
        this.setState({ placeBidSuccess: null, placeBidError: null, showBidModal: !this.state.showBidModal, amountToBid: this.minimumBid() })
    }

    minimumBid = () => {
        return this.props.lotState.bidAmount !== null ? this.props.lotState.bidAmount + (this.props.bidIncrement || 10) : this.props.lot.startPrice;
    }

    placeBid = (id, confirmedBid) => {

        let onSale = this.props.lotState.reserveMet,
            newBid = this.state.amountToBid;

        let showConfirmOverReserve = (onSale && newBid > this.props.lotState.bidAmount + this.props.bidIncrement) && !confirmedBid ? true : false;

        confirmedBid = onSale && !showConfirmOverReserve ? true : confirmedBid;

        if (showConfirmOverReserve) {
            this.setState({
                showConfirmBidModal: true, showBidModal: !this.state.showBidModal,
                placeBidSuccess: ""
            });
        } else {
            this.setState({ placeBidSuccess: null, placeBidError: null, disableBidButton: true }, () => {
                this.placeBidSubmit(this.props.id, this.state.amountToBid, this.props.lot.startPrice, confirmedBid)
                    .then(result => {
                        if (result.confirmBid) {
                            this.setState({
                                disableBidButton: !this.state.disableBidButton,
                                showConfirmBidModal: true, showBidModal: !this.state.showBidModal,
                                placeBidSuccess: result.message
                            });

                        } else {
                            this.setState({ placeBidSuccess: result.message, disableBidButton: false })
                        }
                    })
                    .catch(result => this.setState({ placeBidError: result.message, disableBidButton: false }));
            });
        }
    }

    placeBidSubmit = (lotId, amount, startPrice, bidConfirmed) => {
        if (amount === null || amount === undefined || amount === "") {
            return Promise.reject({ message: 'Provide the bid amount' });
        }
        //if the bid is not the start price
        if (amount !== startPrice) {
            if ((amount - startPrice) % this.props.bidIncrement) {
                return Promise.reject({ message: 'The bid must be an increment of £' + formatMoney(this.props.bidIncrement) });
            }
        }

        if (amount < 0) {
            return Promise.reject({ message: 'The bid must be a positive value' });
        }

        const data = {
            LotId: lotId,
            Amount: amount,
            BidConfirmed: bidConfirmed
        }

        return apiPost('/api/Bid/placebid', true, data)
            .then(result => {
                this.props.getFavourites();
                // this.updateUser();
                if (!result.body.confirmBid) {
                    if (result.body.response.success) {
                        return Promise.resolve({ message: result.body.response.message });
                    } else {
                        return Promise.reject({ message: result.body.response.message });
                    }
                } else {
                    return Promise.resolve({ confirmBid: result.body.confirmBid });
                }
            })
            .catch(error => {
                // this.updateUser();
                if (navigator.onLine) {
                    return Promise.reject({ message: error.message });
                } else {
                    return Promise.reject({ message: 'You have no internet connection. Check the connection and try again.' });
                };
            });
    }

    toggleConfirmBidModal = () => {
        this.setState({ showConfirmBidModal: !this.state.showConfirmBidModal, showBidModal: !this.state.showBidModal })
    }

    closeConfirmBidModal = () => {
        this.setState({ showConfirmBidModal: !this.state.showConfirmBidModal })
    }

    buyItNow = (id) => {
        this.setState({ buyItNowSuccess: null, buyItNowError: null, disableBuyNowButton: true });

        this.buyItNowSubmit(id, this.props.auctionId)
            .then(result => this.setState({ buyItNowSuccess: result.message, disableBuyNowButton: false }))
            .catch(result => this.setState({ buyItNowError: result.message, disableBuyNowButton: false }));
    }

    buyItNowSubmit = (lotId, auctionId) => {
        const data = {
            LotId: lotId,
            auctionId: auctionId,
            auctionState: this.props.auctionState
        }

        return apiPost('/api/Bid/buyItNow', true, data)
            .then(result => {
                if (result.body.success) {
                    return Promise.resolve({ message: result.body.message })
                } else {
                    return Promise.reject({ message: result.body.message })
                }
            })
            .catch(error => {
                if (navigator.onLine) {
                    return Promise.reject({ message: error.message });
                } else {
                    return Promise.reject({ message: 'You have no internet connection. Check the connection and try again.' });
                };
            });
    }

    updateExtendedBiddingState = () => {
        const localUser = localStorage.getItem('user');
        const user = JSON.parse(localUser);
        const userId = user !== null ? user.id : "";

        if (this.props.lotState.state === 2) { // ExtendedBidding
            let ended = true;

            if (this.props.lotState.extendedBiddingEndMilliSeconds !== null &&
                this.props.lotState.extendedBiddingEndMilliSeconds > 0) {
                const endMilliSecods = this.props.lotStateSetTimestamp + this.props.lotState.extendedBiddingEndMilliSeconds - 1000;
                ended = endMilliSecods < new Date().getTime();
            }

            if (this.props.lotState.bidders.includes(userId) && !ended) {
                this.setState({ extendedBiddingState: true, extendedBiddingCanBid: true, extendedBiddingEnd: this.props.lotStateSetTimestamp + this.props.lotState.extendedBiddingEndMilliSeconds });
            } else {
                this.setState({ extendedBiddingState: true, extendedBiddingCanBid: false, extendedBiddingEnd: null });
            }
        } else {
            this.setState({ extendedBiddingState: false, extendedBiddingCanBid: false, extendedBiddingEnd: null });
        }
    }

    updatePageHistoryWithLotNumber = (lotNumber) => {
        const values = queryString.parse(window.location.search)
        values.pos = lotNumber;
        History.push(window.location.pathname + '?' + queryString.stringify(values));
    }

    render() {
        const vehicle = this.props.vehicles[0] || {};
        const elementId = 'lot' + this.props.lotType + this.props.id;
        const localUser = localStorage.getItem('user');
        const user = JSON.parse(localUser);
        const userId = user !== null ? user.id : "";

        return (
            <>
                {isLoggedIn() &&
                    <>
                        <BidNowModal showBidModal={this.state.showBidModal}
                            handleBidNowModalClick={this.handleBidNowModalClick}
                            placeBidSuccess={this.state.placeBidSuccess}
                            bidIncrement={this.props.bidIncrement}
                            lotState={this.props.lotState}
                            minimumBid={this.minimumBid}
                            vehicle={vehicle}
                            amountToBid={this.state.amountToBid}
                            lot={this.props.lot}
                            elementId={elementId}
                            handleChange={this.handleChange}
                            placeBidError={this.state.placeBidError}
                            disableBidButton={this.state.disableBidButton}
                            placeBid={this.placeBid}
                            id={this.props.id}
                        />

                        <ConfirmBidModal showConfirmBidModal={this.state.showConfirmBidModal}
                            toggleConfirmBidModal={this.toggleConfirmBidModal}
                            placeBidSuccess={this.state.placeBidSuccess}
                            placeBidError={this.state.placeBidError}
                            amountToBid={this.state.amountToBid}
                            lot={this.props.lot}
                            closeConfirmBidModal={this.closeConfirmBidModal}
                            placeBid={this.placeBid}
                            id={this.props.id}
                        />

                        <BuyNowModal showBuyItNowModal={this.state.showBuyItNowModal}
                            handleBuyItNowModalClick={this.handleBuyItNowModalClick}
                            buyItNowSuccess={this.state.buyItNowSuccess}
                            vehicle={vehicle}
                            lot={this.props.lot}
                            buyItNowError={this.state.buyItNowError}
                            theme={this.props.theme}
                            disableBuyNowButton={this.state.disableBuyNowButton}
                            buyItNow={this.buyItNow}
                            id={this.props.id}
                        />
                    </>
                }

                <div className={`pt-3 lot ${this.props.bidAlertClass}`} id={"lot_" + this.props.lot.ordinal}>
                    <div className="card mb-3" style={this.props.theme.lotCard} id={`${this.props.id}card`}>
                        {
                            isLoggedIn() && isAllowed('Buyer') &&
                            this.props.lotState.showPricing &&
                            this.state.extendedBiddingCanBid &&
                            <div className="extended-bidding">
                                <div className="alert alert-warning">
                                    <span><strong>Extended bidding ends in: </strong><Countdown date={this.state.extendedBiddingEnd} onComplete={this.updateExtendedBiddingState} /></span>
                                </div>
                            </div>
                        }

                        {/* Card Header */}
                        <LotCardHeader
                            vehicle={vehicle}
                            auctionState={this.props.auctionState}
                            lotState={this.props.lotState}
                            theme={this.props.theme}
                            removeFromWatchList={this.props.removeFromWatchList}
                            addToWatchList={this.props.addToWatchList}
                            lot={this.props.lot}
                            userId={userId}
                        />

                        {/* Card Body */}
                        <div className="lot-card-body">
                            <div className="row no-gutters">
                                <LotCardImage
                                    vehicle={vehicle}
                                    auctionId={this.props.auctionId}
                                    isBuyItnowSale={this.props.isBuyItnowSale}
                                    handleImageLoaded={this.handleImageLoaded.bind(this)}
                                />

                                <div className="col d-flex flex-column">
                                    <LotNotification
                                        lotState={this.props.lotState}
                                        userId={userId}
                                    />

                                    <div className="row no-gutters">
                                        {/*First section - everyone can see */}
                                        <div className="col-md-4 col-sm-6 col-6 order-sm-1 order-1">
                                            <div className="pt-2 pl-2 pt-1">
                                                <p className="card-text"><FontAwesomeIcon style={this.props.theme.icons} icon={['fas', 'tachometer-fast']} className="lot-icons" /> {vehicle.mileage} {vehicle.mileageMilesDescription}</p>
                                                <p className="card-text">({vehicle.mileageStatusDescription})</p>
                                                <p className="card-text"><FontAwesomeIcon style={this.props.theme.icons} icon={['fas', 'map-marker-alt']} className="lot-icons location-icon" /> {vehicle.location}</p>
                                            </div>
                                        </div>

                                        {/*Second section - Only logged in users can see */}
                                        <LotCardBuyerInfo
                                            lotState={this.props.lotState}
                                            theme={this.props.theme}
                                            vehicle={vehicle}
                                            auctionState={this.props.auctionState}
                                            lot={this.props.lot}
                                        />

                                        {/*Third section - Only logged in users can see - badges */}
                                        <LotCardBadges lotState={this.props.lotState} />
                                    </div>

                                    {/*Mobile view - View Now button */}
                                    <div className="row no-gutters d-block d-sm-block d-md-none">
                                        <div className="col-12 view-btn-mob">
                                            {this.props.isBuyItnowSale === "Buy Now" && vehicle.id !== null && vehicle.id !== "" ?
                                                <Link onClick={() => this.updatePageHistoryWithLotNumber(this.props.lot.ordinal)} to={`/BuyNow/${vehicle.id}?pos=${this.props.lot.ordinal}`}>
                                                    <Button buttonText="View Now" theme={this.props.theme} divClass="btn btn-secondary btn-block my-2" buttonType={this.props.theme.btnSecondary} buttonTypeHover={this.props.theme.btnSecondaryHover} />
                                                </Link>
                                                :
                                                <Link onClick={() => this.updatePageHistoryWithLotNumber(this.props.lot.ordinal)} to={`/AuctionVehicle/${this.props.auctionId}/${vehicle.id}?pos=${this.props.lot.ordinal}`}>
                                                    <Button buttonText="View Now" theme={this.props.theme} divClass="btn btn-secondary btn-block my-2" buttonType={this.props.theme.btnSecondary} buttonTypeHover={this.props.theme.btnSecondaryHover} />
                                                </Link>
                                            }
                                        </div>
                                    </div>

                                    <div className="row no-gutters mt-auto">
                                        {/*View now Buttons section - Everyone can see */}
                                        <div className="col-md-4 mt-auto d-none d-sm-none d-md-block">
                                            <div className="view-now cta-container">
                                                {this.props.isBuyItnowSale === "Buy Now" && vehicle.id !== null && vehicle.id !== "" ?
                                                    <Link onClick={() => this.updatePageHistoryWithLotNumber(this.props.lot.ordinal)} to={`/BuyNow/${vehicle.id}?pos=${this.props.lot.ordinal}`}>
                                                        <Button buttonText="View Now" theme={this.props.theme} divClass="btn btn-secondary btn-block mb-2" buttonType={this.props.theme.btnSecondary} buttonTypeHover={this.props.theme.btnSecondaryHover} />
                                                    </Link>
                                                    :
                                                    <Link onClick={() => this.updatePageHistoryWithLotNumber(this.props.lot.ordinal)} to={`/AuctionVehicle/${this.props.auctionId}/${vehicle.id}?pos=${this.props.lot.ordinal}`}>
                                                        <Button buttonText="View Now" theme={this.props.theme} divClass="btn btn-secondary btn-block mb-2" buttonType={this.props.theme.btnSecondary} buttonTypeHover={this.props.theme.btnSecondaryHover} />
                                                    </Link>
                                                }
                                            </div>
                                        </div>

                                        {/*Pricing and bid/buy now buttons section - Only logged in users can see */}
                                        {isAllowed('Buyer') &&
                                            <LotCardPricing
                                                lotState={this.props.lotState}
                                                theme={this.props.theme}
                                                vehicle={vehicle}
                                                extendedBiddingState={this.state.extendedBiddingState}
                                                extendedBiddingCanBid={this.state.extendedBiddingCanBid}
                                                lot={this.props.lot}
                                                handleBuyItNowModalClick={this.handleBuyItNowModalClick.bind(this)}
                                                handleBidNowModalClick={this.handleBidNowModalClick.bind(this)}
                                                bidAlertClass={this.state.bidAlertClass}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            isAllowed('Buyer') &&
                            this.props.lotState.showPricing &&
                            this.props.lotState.sold === null &&
                            <div className="card-footer" style={this.props.theme.lotCardFooter}>
                                {
                                    this.props.lotState.bidTimeStamp ?
                                        <small className="text-muted">Last bid: {moment(this.props.lotState.bidTimeStamp).format('DD/MM/YYYY HH:mm:ss')}</small> :
                                        <small className="text-muted">No bids</small>
                                }
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

Lot.defaultProps = {
    vehicles: [],
    lot: {},
    lotState: {}
}

export default Lot;