import React, { Component } from 'react';
import Timer from 'react-compound-timer';
import './Styles/AuctionCountdown.scss';

const Countdown = (props) => {
    return (
        <>
            <Timer initialTime={props.endTime}
                lastUnit={props.showDays ? "d" : "h"}
                direction="backward"
                timeToUpdate="0"
                checkpoints={[
                    {
                        time: 0,
                        callback: () => { props.update(); props.countdownExpired(); },
                    },
                    {
                        time: 86400000,
                        callback: () => props.update()
                    }
                ]}>

                {() => (
                    <div className="col-12 coming-soon">
                        <div className="countdown" style={props.theme.countdownBox}>
                            <div className="text-center ctdwn-title"><p>{props.title}</p></div>
                            <div className="ctdown" >
                                {props.showDays &&
                                    <>
                                        <div className="col time-box"><p style={props.theme.countdownText}><Timer.Days /></p> <span>DAYS</span></div>
                                        <div className="separator">:</div>
                                    </>
                                }
                                <div className="col time-box"><p style={props.theme.countdownText}><Timer.Hours /></p> <span>HOURS</span></div>
                                <div className="separator">:</div>
                                <div className="col time-box"><p style={props.theme.countdownText}><Timer.Minutes /></p> <span>MINUTES</span></div>
                                <div className="separator">:</div>
                                <div className="col time-box"><p style={props.theme.countdownText}><Timer.Seconds /></p> <span>SECONDS</span></div>
                            </div>
                        </div>
                    </div>
                )}
            </Timer>
        </>
    )
}

const AuctionEnded = (props) => {
    return (
        <div className="col-12 coming-soon">
            <div className="countdown">
                <div className="text-center ctdwn-title"><p>{props.title}</p></div>
                <div className="ctdown">
                    <p>This auction has ended</p>
                </div>
            </div>
        </div>
    )
}

const Loading = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

class AuctionCountdown extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.auctionSetTimestamp !== this.props.auctionSetTimestamp ||
            prevProps.startMilliSeconds !== this.props.startMilliSeconds ||
            prevProps.endMilliSeconds !== this.props.endMilliSeconds) {
            this.update();
        }
    }

    update() {
        this.setState({ componentState: "updating" }, () => {
            const startEndTime = this.props.auctionSetTimestamp + this.props.startMilliSeconds;
            const endEndTime = this.props.auctionSetTimestamp + this.props.endMilliSeconds;

            if (startEndTime > Date.now()) {
                const endTime = startEndTime - Date.now();
                const showDays = endTime > 86400000;
                this.setState({ componentState: "countdown", endTime, showDays, title: "AUCTION STARTS IN" });
            } else if (endEndTime > Date.now()) {
                const endTime = endEndTime - Date.now();
                const showDays = endTime > 86400000;
                this.setState({ componentState: "countdown", endTime, showDays, title: "AUCTION ENDS IN" });
            } else {
                this.setState({ componentState: "ended", endTime: null, showDays: null, title: null });
            }
        });
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "countdown":
                return <Countdown update={this.update}
                    countdownExpired={this.props.countdownExpired}
                    endTime={this.state.endTime}
                    showDays={this.state.showDays}
                    title={this.state.title}
                    theme={this.props.theme} />
            case "ended":
                return <AuctionEnded />
            default:
                return <Loading />
        }
    }

    render() {
        return (
            <>
                {this.renderComponentState()}
            </>
        );
    }
}

export default AuctionCountdown;