import React, { useEffect, useState } from 'react';
import { apiGet } from '../../../../Shared/Utilities/ApiFetch';
import './Carousel.scss';
import CarouselLayout from './CarouselLayout';
var CarouselContent = require('react-responsive-carousel').Carousel;

const Carousel = () => {

    const [carousel, setCarousel] = useState([]);

    useEffect(() => {
        apiGet('/api/content/GetActiveCarouselContent', true)
            .then(result =>
                setCarousel(result.body.carouselContent)
            )
            .catch(error => { });
    }, []);

    return (
        <>
            <div className='w-100 desktop-only'>
                <CarouselContent autoPlay interval={4500} infiniteLoop={true} showArrows={true} showThumbs={false} showStatus={false} showIndicators={carousel.length > 1} >
                    {carousel.map((x, i) =>
                        <div key={i} >
                            <CarouselLayout
                                heading={x.heading}
                                subheading={x.subheading}
                                text={x.text}
                                image={x.imageUrl}
                                buttonText={x.buttonText}
                                buttonUrl={x.buttonUrl}
                                isDealOfTheWeek={x.isDealOfTheWeek}
                                regNo={x.regNo}
                                make={x.make}
                                model={x.model}
                                derivative={x.derivative}
                                year={x.year}
                                auctionName={x.auctionName}
                                isComingSoon={x.isComingSoon}
                                auctionDate={x.auctionDateTime}
                                auctionStartTime={x.auctionStartTime}
                                auctionEndTime={x.auctionEndTime}
                            />
                        </div>
                    )}
                </CarouselContent>
            </div>
            <div className='w-100 mobile-only'>
                {carousel.map((x, i) =>
                    <div key={i}>
                        <CarouselLayout
                            heading={x.heading}
                            subheading={x.subheading}
                            text={x.text}
                            image={x.imageUrl}
                            buttonText={x.buttonText}
                            buttonUrl={x.buttonUrl}
                            isDealOfTheWeek={x.isDealOfTheWeek}
                            regNo={x.regNo}
                            make={x.make}
                            model={x.model}
                            derivative={x.derivative}
                            year={x.year}
                            auctionName={x.auctionName}
                            isComingSoon={x.isComingSoon}
                            auctionDate={x.auctionStartDate}
                            auctionStartTime={x.auctionStartDate}
                            auctionEndTime={x.auctionEndDate}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Carousel;