import { baseUrl } from './AppConfig';
import { authHeader } from './AuthUtilities';

const formatOutput = (response, body) => {
    const returnValue = {
        body,
        response,
        status: response.status,
        statusText: response.statusText
    }

    return response.ok
        ? Promise.resolve(returnValue)
        : Promise.reject(returnValue)
}

const parseResponse = (response, type) => {
    // Response object can only be used once.
    // We clone the response object here so users can use it again if they want to.
    // Checks required because browser support isn't solid.
    // https://developer.mozilla.org/en-US/docs/Web/API/Response/clone

    const clone = typeof response.clone === 'function'
        ? response.clone()
        : undefined

    const passedResponse = clone || response

    // This will do response.json(), response.text(), etc.
    // We use bracket notation to allow multiple types to be parsed at the same time.
    return response[type]()
        .then(body => formatOutput(passedResponse, body))
}

function handleResponse(response) {
    const type = response.headers.get('content-type')
    if (type === null) return formatOutput(response, null)
    if (type.includes('json')) return parseResponse(response, 'json')
    if (type.includes('text')) return parseResponse(response, 'text')
    if (type.includes('image')) return parseResponse(response, 'blob')
    if (type.includes('pdf')) return parseResponse(response, 'blob')
    // Need to check for FormData, Blob and ArrayBuffer content types
    throw new Error(`apiFetch does not support content-type ${type}`)
}

function apiFetch(method, url, addAuth, json, file) {

    let opts = {
        method: method,
        headers: {}
    }

    if (addAuth) {
        opts.headers = { ...opts.headers, ...authHeader() }
    }

    if (json) {
        opts.headers = { ...opts.headers, ...{ 'content-type': 'application/json' } }
        opts.body = JSON.stringify(json);
    }

    if (file) {
        opts.body = file;
    }

    return fetch(baseUrl() + url, opts).then(handleResponse)
}

export function apiGet(url, addAuth) {
    return apiFetch('get', url, addAuth)
}

export function apiPost(url, addAuth, json) {
    return apiFetch('post', url, addAuth, json)
}

export function apiPostFile(url, addAuth, file) {
    return apiFetch('post', url, addAuth, null, file)
}

export function apiPut(url, addAuth, json) {
    return apiFetch('put', url, addAuth, json)
}

export function apiDelete(url, addAuth, json) {
    return apiFetch('delete', url, addAuth, json)
}
export function showFilePdf(blob,fileName){
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], {type: "application/pdf"})
  
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob,fileName + ".pdf");
      return;
    } 
  
    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download= fileName + ".pdf";
    link.click();
    setTimeout(function(){
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }