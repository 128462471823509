import React, { Component } from 'react';
import './Styles/Loading.css';

class Loading extends Component {
    render() {
        return ( 
            <div className="loading">
                <div className="spinner">
                    <div className="ball">
                        <p></p>
                    </div>
                    <div className="">
                        <p>Loading...</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;