/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import Mug from '../../Images/ng-mug.jpg';
import Vans from '../../Images/multi-vans.jpg';
import './Styles/About.css';

const About = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 grey-box my-4 text-center">
                    <h1 className="text-center mt-5">About Us</h1>
                    <p className="mb-5">A little bit about us</p>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12 col-lg-5 order-md-2 order-lg-1">
                    <img className="d-block w-100 py-2 px-2 about-img" src={Mug} alt="Northgate Plc" />
                </div>
                <div className="col-12 col-lg-7 d-flex align-items-center order-md-1 order-lg-1">
                    <div className="">
                        <h4>Who we are? </h4>
                        <p>We are part of a rich history in the automotive sector. Established in 1981 as part of the Northgate PLC family, Van Monster became a standalone brand in 2004. Since then, we’ve sold and facilitated sales for circa 300,000 used LCVs to customers across the UK & in Ireland. </p>
                        <p>In 2017 we launched our first E-auction platform and have seen our online sales rapidly grow to in excess of 10,000 vehicles. With the introduction of the Van Monster Remarketing brand, we have developed an easily accessible way for our members to buy and sell their vehicles nationwide.</p>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-lg-7 d-flex align-items-center">
                    <div className="">
                        <h4>What we offer?</h4>
                        <p>Van Monster remarketing now offer the commercial vehicle trade a dedicated online platform for buying and selling vans in the UK.</p>
                        <ul>
                            <li>
                                Exclusive access to weekly Northgate fleet vehicles
                            </li>
                            <li>
                                Access to nationwide fleet vehicles from multiple vendors
                            </li>
                            <li>
                                Regular Part exchange and lower price range sales
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <img className="d-block w-100 py-2 px-2 about-img" src={Vans} alt="Northgate Plc" />
                </div>
            </div>
        </div>
    );
};

export default About;