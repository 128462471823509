import React from 'react';
import { isAllowed } from '../../../../Shared/Utilities/AuthUtilities';
import { ASSIST40PRICE } from './../../../../Infrastructure/Config/Assist40Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatMoney } from '../../../../Shared/Utilities/FormatMoney';

const LotCardBuyerInfo = (props) => {
    return (
        <div className="col-md-4 col-sm-12 col-12 order-sm-3 order-3 order-md-2 pl-0">
            {
                isAllowed('Buyer') &&
                <>
                    {
                        props.lotState.buyersFee > 0 ?
                            <div className="pl-2 buyers-fee">
                                <p className="card-text"><FontAwesomeIcon style={props.theme.icons} icon={['fal', 'pound-sign']} className="lot-icons buyers-fee-icon" /> Buyers fee: £{formatMoney(props.lotState.buyersFee)} + VAT</p>
                            </div>
                            :
                            <div className="pl-2 buyers-fee">
                                <p className="card-text"><FontAwesomeIcon style={props.theme.icons} icon={['fal', 'pound-sign']} className="lot-icons buyers-fee-icon" /> No buyers fee</p>
                            </div>
                    }
                    {
                        props.vehicle.hasAssist40 &&
                        <div className="pl-2 buyers-fee">
                            <p className="card-text"><FontAwesomeIcon style={props.theme.icons} icon={['fal', 'pound-sign']} className="lot-icons buyers-fee-icon" /> Assist40: £{ASSIST40PRICE} + VAT</p>
                        </div>
                    }

                </>
            }
            {
                isAllowed('Buyer') &&
                props.auctionState === 2 &&
                <>
                    {
                        <div className="pl-2 buyers-fee">
                            <p className="card-text"><FontAwesomeIcon style={props.theme.icons} icon={['fal', 'pound-sign']} className="lot-icons buyers-fee-icon" /> Start Price: £{formatMoney(props.lot.startPrice)}</p>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default LotCardBuyerInfo;