import React, { Component } from 'react';
import ReusableModal from './ReusableModal'
class AlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlertModal:false,
        }
        this.modalTitle= "Notification";
        this.actionButtonText="OK";
        this.showAlertModal = this.showAlertModal.bind(this);
        this.hideAlertModal = this.hideAlertModal.bind(this);
        this.modalContentBody = this.modalContentBody.bind(this);
    }
    componentDidMount() {
        this.checkAlertMessage(); 
    }
    componentDidUpdate(prevProps) {
        if (this.props.alertMessage !== prevProps.alertMessage) {
            this.checkAlertMessage(); 
        }
    }
    checkAlertMessage()
    {
        if(this.props.alertMessage && this.props.alertMessage !=='')
        {
            this.showAlertModal();
        }
    }
    showAlertModal()
    {
        this.setState({showAlertModal:true});
    }
    hideAlertModal()
    {
        this.setState({showAlertModal:false},()=>this.props.clearAlertMessage());
    }
    modalContentBody()
    {
        return(
            <React.Fragment>
                 {this.props.alertMessage}
            </React.Fragment>
        );
    }
   
    render() {
        return(<ReusableModal 
            showModal={this.state.showAlertModal} 
            modalTitle={this.modalTitle} 
            modalContentBody={this.modalContentBody} 
            onActionClick={this.hideAlertModal} 
            actionName={this.actionButtonText} 
            props={this.props} />
        );
    }  
}
export default AlertModal;