import React, { Component } from 'react';
import moment from 'moment';
import { formatMoney } from '../../../Utilities/FormatMoney';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AwaitingImage from '../../../../Images/Awaiting_Image.jpg';
import Assist40Logo from "../../../../Images/assist40small.png";
import './Styles/Vehicle.scss';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DamageTab from './DamageTab';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Button from '../../../Common/Button';
import { Link } from 'react-router-dom';

class Vehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            damageImage: null,
            photoIndex: 0,
            isOpen: false,
            activeTab: null,
            damageLightBoxIsOpen: false,
            auctionId: null
        };
        this.getDisplayValue = this.getDisplayValue.bind(this);
        this.getCAPValue = this.getCAPValue.bind(this);
        this.getDisplayDate = this.getDisplayDate.bind(this);
        this.damageGetMapArea = this.damageGetMapArea.bind(this);
        this.damageDiagramEnterArea = this.damageDiagramEnterArea.bind(this);
        this.damageDiagramLeaveArea = this.damageDiagramLeaveArea.bind(this);
        this.setTab = this.setTab.bind(this);
        this.handleDamageAreaClick = this.handleDamageAreaClick.bind(this);
        this.toggleDamageLightBox = this.toggleDamageLightBox.bind(this);
    }

    componentDidMount() {
        const height = document.getElementsByClassName('thumbs-wrapper').offsetHeight;
        this.setState({ imgLabelHeight: height })

        const width = (80 / 100) * window.screen.width;
        this.setState({ damageImgWidth: window.screen.width >= 420 ? 420 : width })

        const [, , auctionId,] = window.location.pathname.split('/');
        this.setState({ auctionId: auctionId });
    }

    getDisplayValue(input) {
        if (input == null) {
            return "";
        }

        return input;
    }

    getCAPValue(input) {
        if (input == null) {
            return '0';
        }

        return formatMoney(input);
    }

    getDisplayDate(input) {
        if (input == null) {
            return "";
        }

        if (input && input.indexOf("T") > 0) {
            input = input.substr(0, input.indexOf("T"));
        }

        return moment(input).format('DD/MM/YYYY');
    }

    damageGetMapArea(area) {
        let mapArea = {
            name: "damage",
            areas: []
        };

        let prefillColor = this.props.theme.damageIcon ? this.props.theme.damageIcon : "green";

        this.props.vehicle.damage.forEach(damage => {
            if (damage.damageDiagramId === area) {
                mapArea.areas.push({ name: damage.id, shape: "circle", coords: [damage.x, damage.y, 10], preFillColor: prefillColor, fillColor: "orange" });
            }
        });

        return mapArea;
    }

    damageDiagramEnterArea(area) {
        let name = area.name;
        let damageImage = this.props.vehicle.damage.find(d => d.id === name);

        this.setState({
            damageImage: damageImage.image
        });
    }

    damageDiagramLeaveArea() {
        this.setState({
            damageImage: null
        });
    }

    openLighbox(image) {
        this.setState({
            mainSrc: image.uri.concat(image.name),
            isOpen: !this.state.isOpen,
            photoIndex: image.image,
            imageDescription: image.imageDescription
        });
    }

    toggleDamageLightBox() {
        this.setState({
            damageLightBoxIsOpen: !this.state.damageLightBoxIsOpen
        });
    }

    moveNext() {
        let images = this.props.vehicle.images;
        let nextIndex = (this.state.photoIndex + 1) % this.props.vehicle.images.length;

        const nextSrc = images[nextIndex];
        this.setState({
            mainSrc: nextSrc.uri.concat(nextSrc.name),
            photoIndex: nextIndex,
            imageDescription: nextSrc.imageDescription
        });
    }

    movePrev() {
        let images = this.props.vehicle.images;
        let prevIndex = (this.state.photoIndex + this.props.vehicle.images.length - 1) % this.props.vehicle.images.length;

        const prevSrc = images[prevIndex];
        this.setState({
            mainSrc: prevSrc.uri.concat(prevSrc.name),
            photoIndex: prevIndex,
            imageDescription: prevSrc.imageDescription
        });
    }

    setSelectedIndex = index => {
        this.setState({
            photoIndex: index
        })
    }

    setTab(id) {
        this.setState({ activeTab: id });
    }

    handleDamageAreaClick(area) {
        let name = area.name;
        let damageImage = this.props.vehicle.damage.find(d => d.id === name);

        this.setState({
            damageImage: damageImage.image
        }, () => { this.toggleDamageLightBox() });
    }



    renderDamageTab() {
        return (
            <DamageTab
                vd={this.state.activeTab === null ? this.props.vehicle.damageDiagrams[0] : this.props.vehicle.damageDiagrams.find(d => d.id === this.state.activeTab)}
                damageGetMapArea={this.damageGetMapArea}
                damageDiagramEnterArea={this.damageDiagramEnterArea}
                damageDiagramLeaveArea={this.damageDiagramLeaveArea}
                damageImage={this.state.damageImage}
                activeTab={this.state.activeTab}
                vehicle={this.props.vehicle}
                handleDamageAreaClick={this.handleDamageAreaClick}
                damageLightBoxIsOpen={this.state.damageLightBoxIsOpen}
                toggleDamageLightBox={this.toggleDamageLightBox}
                damageImgWidth={this.state.damageImgWidth}
                theme={this.props.theme}
            />
        )
    }

    render() {

        return (
            <React.Fragment>
                {this.state.isOpen &&
                    this.props.vehicle.images &&
                    this.props.vehicle.images.map((image) =>
                        <Lightbox
                            key={image.image}
                            animationOnKeyInput={true}
                            mainSrc={this.state.mainSrc}
                            nextSrc={image.uri.concat(image.thumbnailName || image.name)[(this.state.photoIndex + 1) % this.props.vehicle.images.length]}
                            prevSrc={image.uri.concat(image.thumbnailName || image.name)[(this.state.photoIndex + this.props.vehicle.images.length - 1) % this.props.vehicle.images.length]}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.movePrev()
                            }
                            onMoveNextRequest={() =>
                                this.moveNext()
                            }
                            imageCaption={this.state.imageDescription}
                        />
                    )}
                <div className="row">
                    <div className="col-12 my-3">
                        {this.props.lotNumber ?
                            <Button buttonText="Return to Sale" divClass="btn btn-success" clickFunc={this.props.goBack} theme={this.props.theme} buttonType={this.props.theme.btnSuccess} buttonTypeHover={this.props.theme.btnSuccessHover} />
                            :
                            <Link to={`/auction/${this.state.auctionId}`}>
                                <Button buttonText="Return to Sale" divClass="btn btn-success" theme={this.props.theme} buttonType={this.props.theme.btnSuccess} buttonTypeHover={this.props.theme.btnSuccessHover} />
                            </Link>
                        }
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-12 mb-2">
                        <h4 className="vehicle-title" style={this.props.theme.vehiclePageHeading}>{this.props.lotNumber > 0 && "Lot " + this.props.lotNumber + " - "} {this.props.vehicle.registrationYear} {this.props.vehicle.make} {this.props.vehicle.model} {this.props.vehicle.derivative}</h4>
                    </div>
                    <div className="col-md-8 offset-md-2 col-sm-12 vme-img justify-content-center">
                        <div className="row no-gutters mb-2">
                            <div className="col-6 vehicle-icons icon-left d-none d-md-block">
                                <p><FontAwesomeIcon icon={['fas', 'tachometer-fast']} style={this.props.theme.icons} className="vme-icons" /> <strong>{this.props.vehicle.mileage} {this.props.vehicle.mileageMilesDescription} <span className="d-none d-lg-inline"> ({this.props.vehicle.mileageStatusDescription})</span></strong> </p>
                            </div>
                            <div className="col-6 vehicle-icons icon-right d-none d-md-block">
                                <p><FontAwesomeIcon icon={['fas', 'map-marker-alt']} style={this.props.theme.icons} className="vme-icons" /><strong>{this.props.vehicle.location.name}</strong></p>
                            </div>
                        </div>
                        {
                            this.props.vehicle.images.length > 1 ?
                                <div className="vm-img-container">
                                    <div className="img-label--top">
                                        <span className="badge badge-reg m-0">
                                            {this.props.vehicle.registrationNumber}
                                        </span>
                                        {this.props.vehicle.vendorLogo &&
                                            <span className="badge badge-light m-0 float-right d-none d-sm-none d-md-block">
                                                <img src={this.props.vehicle.vendorLogo.url} title="Vendor" width="150" className="float-right" alt="vendor" />
                                            </span>
                                        }
                                    </div>
                                    <div className="img-label--bottom">
                                        <span className="badge badge-green m-0 mt-2" style={this.props.theme.photoIndicator}>
                                            {this.state.photoIndex + 1} / {this.props.vehicle.images.length}
                                        </span>
                                        {this.props.vehicle.hasAssist40 &&
                                            <span className="badge badge-light m-0 float-right mt-1">
                                                <img src={Assist40Logo} title="Assist40" width="85" className="float-right" alt="assist40" />
                                            </span>
                                        }
                                    </div>

                                    <Carousel className="" showStatus={false} onChange={this.setSelectedIndex} showIndicators={false} selectedItem={this.state.photoIndex}>
                                        {this.props.vehicle.images.map((image) =>
                                            <div key={image.image} onClick={() => this.openLighbox(image)}>
                                                <img className="img-thumbnail" src={image.uri.concat(image.thumbnailName || image.name)} alt={image.imageDescription} />
                                            </div>
                                        )}
                                    </Carousel>
                                </div>
                                :
                                <div>
                                    <img className="img-thumbnail" src={AwaitingImage} alt="Awaiting" />
                                </div>
                        }
                        {this.props.vehicle.vendorLogo &&
                            <div className="row mb-2 d-md-none">
                                <div className="col-12 text-center">
                                    <span className="badge badge-light">
                                        <img src={this.props.vehicle.vendorLogo.url} title="Vendor" className="float-right w-100" alt="vendor" />
                                    </span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <div className="row mb-3 d-md-none">
                            <div className="col-6 vehicle-icons icon-left ">
                                <p><FontAwesomeIcon icon={['fas', 'tachometer-fast']}  style={this.props.theme.icons} className="vme-icons" /> <strong>{this.props.vehicle.mileage} {this.props.vehicle.mileageMilesDescription}</strong> </p>
                            </div>
                            <div className="col-6 vehicle-icons icon-right">
                                <p><FontAwesomeIcon icon={['fas', 'map-marker-alt']} style={this.props.theme.icons} className="vme-icons" /><strong>{this.props.vehicle.location.name}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-2 px-0">
                    <Tabs className=" col-12 vm-tabs five" >
                        <Tab label="Specifications">
                            <div className="spec-container">
                                <li className="specs">
                                    <p className="mr-3">Mileage</p>
                                    <p>{this.props.vehicle.mileage + " " + this.props.vehicle.mileageMilesDescription}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Mileage Status</p>
                                    <p>{this.props.vehicle.mileageStatusDescription}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Engine Size</p>
                                    <p>{this.props.vehicle.engineSize}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">MPG Combined</p>
                                    <p>{this.props.vehicle.mpg}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Fuel Type</p>
                                    <p>{this.props.vehicle.fuelType}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">CO2(g/km)</p>
                                    <p>{this.props.vehicle.co2}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Colour</p>
                                    <p>{this.props.vehicle.colour}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Body Type</p>
                                    <p>{this.props.vehicle.bodyType}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Transmission</p>
                                    <p>{this.props.vehicle.transmission}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Emissions</p>
                                    <p>{this.props.vehicle.emissions}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Drive</p>
                                    <p>{this.props.vehicle.drive}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">BHP</p>
                                    <p>{this.props.vehicle.bhp}</p>
                                </li>
                            </div>
                        </Tab>

                        <Tab label="Features">
                            <div className="spec-container">
                                {
                                    this.props.vehicle.features && this.props.vehicle.features.length > 0 ?
                                        this.props.vehicle.features.filter(function (feat) {
                                            return feat.included === true;
                                        }).map((feature) =>
                                            <li className="specs" key={feature.featureDescription}>
                                                <p className="mr-3">{feature.featureDescription}</p>
                                                <FontAwesomeIcon icon={['fas', 'check']} className="vme-icons features-icon" />
                                            </li>
                                        )
                                        :
                                        <p className="specs mr-3">This vehicle has no additional features</p>
                                }
                            </div>
                        </Tab>
                        <Tab label={this.props.vehicle.vehicleNotes ? <span className="text-danger font-weight-bold">Notes <i className="fa fa-info-circle" /></span> : "Notes"}>
                            <div className="spec-container">
                                {this.props.vehicle.vehicleNotes ?
                                    <React.Fragment>
                                        <p>{this.props.vehicle.vehicleNotes}</p>
                                    </React.Fragment>
                                    :
                                    <p className="specs mr-3">There are no additional notes for this vehicle</p>
                                }
                            </div>
                        </Tab>
                        <Tab label="Additional">
                            <div className="spec-container">
                                <li className="specs">
                                    <p className="mr-3">Registration Date</p>
                                    <p>{this.getDisplayDate(this.props.vehicle.registrationDate)}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Registration Year</p>
                                    <p>{this.props.vehicle.registrationYear}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">MOT Date</p>
                                    <p>{this.getDisplayDate(this.props.vehicle.motDate)}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">V5</p>
                                    <p>{this.props.vehicle.v5Description}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Last Service Date</p>
                                    <p>{this.getDisplayDate(this.props.vehicle.lastServiceDate)}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Service History</p>
                                    <p>{this.props.vehicle.serviceHistoryDescription}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Registered Keepers</p>
                                    <p>{this.props.vehicle.registeredKeepers}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Spare Keys</p>
                                    <p>{this.props.vehicle.spareKeys}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">Outstanding Safety Recall</p>
                                    <p>{this.props.vehicle.outstandingSafetyRecall ? "Yes" : "No"}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">CAP ID</p>
                                    <p>{this.props.vehicle.capID}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">CAP Code</p>
                                    <p>{this.props.vehicle.capCode}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">CAP Clean</p>
                                    <p>&pound;{this.getCAPValue(this.props.vehicle.capCleanPrice)}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">CAP Average</p>
                                    <p>&pound;{this.getCAPValue(this.props.vehicle.capAveragePrice)}</p>
                                </li>
                                <li className="specs">
                                    <p className="mr-3">CAP Below</p>
                                    <p>&pound;{this.getCAPValue(this.props.vehicle.capBelowPrice)}</p>
                                </li>
                            </div>
                        </Tab>
                        <Tab label="Documents">
                            <div className="spec-container">
                                {this.props.vehicle.documents.length > 0 ?
                                    <React.Fragment>
                                        {this.props.vehicle.documents.map((x, i) =>
                                            <li key={i} className="specs">
                                                <p className="mr-3">{x.documentType.name}</p>
                                                <a href={x.url} rel="noopener noreferrer" target="_blank">View</a>
                                            </li>)
                                        }

                                    </React.Fragment>
                                    :
                                    <li className="specs">
                                        <p className="mr-3">This vehicle has no documents attached</p>
                                    </li>
                                }

                            </div>
                            {/* {isLoggedIn() &&
                                  <div className="container">
                                      <h4>Vehicle Brochure</h4>
                                      <p>Download the vehicle information as a pdf </p>
                                      <Brochure vehicleId={this.props.vehicle.id} userId={getUserId()} registrationNumber={this.props.vehicle.registrationNumber} />
                                  
                              </div>
                            } */}
                        </Tab>
                    </Tabs>
                </div>
                {
                    this.props.vehicle.damage.length > 0 &&
                    this.props.vehicle.damageDiagrams.length > 0 &&
                    <div className="row mb-2 mt-3">
                        <div className="col-12 mb-2">
                            <h4 style={this.props.theme.vehiclePageHeading}>Damage</h4>
                        </div>

                        {/* Damage tabs */}
                        <div className="tabs py-2 col-12 vm-tabs five">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {
                                    this.props.vehicle.damageDiagrams.map((vd, i) =>
                                        <li key={i} className={`nav-item vm-tabs p-0`}>
                                            <a onClick={() => this.setTab(vd.id)} className={`nav-link  ${this.state.activeTab === vd.id || (this.state.activeTab === null && i === 0) ? 'active' : null}`} data-toggle="tab" href={`#${vd.id}`} role="tab" aria-controls={vd.id} aria-selected="true">{vd.description}</a>
                                        </li>
                                    )
                                }
                            </ul>
                            <div className={`tab-content `}>
                                {this.renderDamageTab()}
                            </div>
                        </div>
                        {/* Damage tabs */}
                    </div>
                }

            </React.Fragment>
        );
    }
}

Vehicle.defaultProps = {
    vehicle: {}
};

export default Vehicle;