import React, { Component } from 'react';
import Lot from './Lot';

class WatchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const localUser = localStorage.getItem('user');
        const user = JSON.parse(localUser);
        const userId = user != null ? user.id : "";
        const watchlist = this.props.watchList.map(
            x =>
                <Lot key={x.id}
                    id={x.id}
                    auctionId={this.props.auctionId}
                    vehicles={x.vehicles}
                    lotState={x.lotState}
                    lotStateSetTimestamp={x.lotStateSetTimestamp}
                    lot={x}
                    lotType="Watchlist"
                    bidIncrement={this.props.bidIncrement}
                    // placeBid={this.props.placeBid}
                    // buyItNow={this.props.buyItNow}
                    addToWatchList={this.props.addToWatchList}
                    removeFromWatchList={this.props.removeFromWatchList}
                    bidAlertClass={x.lotState.sold && x.lotState.bidUserId === userId ? "bidder-bought" : (x.lotState.bidUserId === userId ? "highest-bidder" : (x.lotState.bidders.includes(userId) ? "bidder-outbid" : ""))}
                    auctionState={this.props.auctionState}
                    isBuyItnowSale={this.props.isBuyItnowSale}
                    theme={this.props.theme}
                    addToPageHistory={this.props.addToPageHistory}
                    getFavourites={this.props.getFavourites}
                />
        )
        return (
            <>
                { this.props.watchList && this.props.watchList.length > 0 ?
                    watchlist
                    :
                    <div className="col-12">
                        <div className="alert alert-info text-center" role="alert">
                            You currently have no vans in your auction
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default WatchList;