/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import NextGearLogo from '../../Images/next_gear_logo.png';
import ItFleetLogo from '../../Images/it_fleet_logo.png';

const Partners = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 grey-box my-4 text-center">
                    <h1 className="text-center mt-5">Our Partners</h1>
                    <p className="mb-5">Working together to bring you more</p>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <div className="row row-eq-height mt-5">
                        <div className="col-sm-12 col-lg-6 mb-3">
                            <div className="border p-4 h-100 rounded">
                                <div className="text-center px-5">
                                    <a href="https://www.nextgearcapital.co.uk/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" alt="Van monster remarketing" src={NextGearLogo} className="img-fluid px-5 py-5" />
                                    </a>
                                </div>
                                {/* <h4 className="text-center">NextGear</h4> */}
                                <p>Van Monster Remarketing now accepts payment direct from NextGear Capital, simplifying your buying process, enabling you to transport vehicles to your forecourt faster. NextGear Capital Stocking Plans are the smart way to buy the stock you want from the source you choose. It’s all about freeing up the cash you need to transform your sales, and maximise your profit. NextGear Stocking Plans are cost effective, easy to manage and could help you increase, upgrade and diversify your stock. To arrange an appointment with a NextGear Capital Account Manager call <strong>0843 50 60 606</strong>.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 mb-3">
                            <div className="border p-4 h-100 rounded">
                                <div className="text-center px-5">
                                    <a href="https://www.itfleet.co.uk/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" alt="Van monster remarketing" src={ItFleetLogo} className="img-fluid px-5 py-5" />
                                    </a>
                                </div>
                                {/* <h4 className="text-center">IT Fleet</h4> */}
                                <p>With over 350 trade plate drivers and 80 transporter drivers, IT Fleet Automotive can offer a complete logistics service across the UK whatever the volume or location. Using FleetPlus, they have processed over 1,000,000 transport movements and inspections since 2012. All collections and deliveries are tracked live using their FleetPlus app, so you can keep updated with the exact whereabouts and estimated delivery time of your vehicles. Register by calling <strong>01473 313057</strong> or visit <strong>www.itfleet.co.uk</strong> quoting Van Monster Remarketing to get our preferential rates.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;