/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import './Styles/Pledge.scss';
import Badge from '../../Images/Pledge/PledgeBadge.jpg';
import PledgeApp from '../../Images/Pledge/PledgePhone.jpg';

const Pledge = () => {
    return (
        <div className="container-fluid content">
            <div className="row py-5 justify-content-center">
                <img src={Badge} alt="Pledge" className="d-none d-sm-block" />
                <img src={Badge} alt="Pledge" className="w-50 d-block d-sm-none" />
            </div>
            <div className="container">
                <div className="row text-center pt-2">
                    <p>Whether you’re buying or selling from Van Monster Remarketing we want you to feel confident dealing with us. That’s why we’ve created the Van Monster Remarketing pledge demonstrating how we’re working with you to ensure transparency and honesty in all our vehicle listings. </p>
                </div>
                <div className="row my-3">
                    <div className="col-12 col-lg-4 mb-md-4">
                        <img className="d-block py-2 px-2 about-img m-auto pb-md-2" src={PledgeApp} alt="Northgate Plc" />
                    </div>
                    <div className="col-12 col-lg-8 d-flex align-items-center order-md-1 order-lg-1">
                        <div className="">
                            <p>Our Van Monster Remarketing Upload App provides a template to complete to give sellers the tools to give an accurate description of their vehicles.</p>
                            <p>  Our Assist 40 is a visual 40-point check which covers engine operations, windscreen and tyre conditions and documentation checks such as HPI check and certificates.</p>
                            <p>All vehicles are appraised with a portfolio of images detailing damage impairment and additional equipment, so you know exactly what you are buying from our online auctions. </p>
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-12">
                        <div className="light-grey-box text-center p-5">
                            To find out more about how we can help sell your vans quickly and conveniently or to get a valuation on any vehicles that you may want to sell in the future, contact one of our specialist team today <a href={"mailto:vendor.vm@vanmonsterremarketing.com"}>vendor.vm@vanmonsterremarketing.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pledge;