import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReusableModal from './ReusableModal';
import DynamicContent from '../../Containers/Home/DynamicContent';
const CookieKeyword = 'cookie-policy'
class CookieModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCookieConsentModal:false,
            showCookiePolicy: false
        }
        this.modalTitle= "Cookie Consent";
        this.actionButtonText="OK";
        this.cancelButtonText="No, take me to settings";
        this.setShowCookieConsentModal = this.setShowCookieConsentModal.bind(this);
        this.declineCookieConsent = this.declineCookieConsent.bind(this);
        this.acceptCookieConsent = this.acceptCookieConsent.bind(this);
        this.modalContentBody = this.modalContentBody.bind(this);
    }
    componentDidMount() {
        this.checkCookieConsent(); 
    }
    setShowCookieConsentModal(value)
    {
        this.setState({showCookieConsentModal:value});
    }
    toggleShowCookiePolicy()
    {
        this.setState({showCookiePolicy:!this.state.showCookiePolicy})
    }
    checkCookieConsent() {
        let cookie = this.getCookie('cookieConsent');
        if (cookie === "") {
            this.setShowCookieConsentModal(true);
        }
    }
    modalContentBody()
    {
        return(
            <React.Fragment>
                  Van Monster use cookies on this website to ensure that you get the best experience from our site. By continuing to browse the website you are agreeing to our use of cookies. <Link onClick={() => { this.toggleShowCookiePolicy()}} to="#">View cookie policy</Link>
                  { this.state.showCookiePolicy &&
                    <div className={"border border-grey border-left-0 border-right-0"}>
                       <DynamicContent keyword={CookieKeyword} />                        
                    </div>
                 }
            </React.Fragment>
        );
    }
    acceptCookieConsent() {
        this.props.setHasConsentedToCookies("true");
        this.setShowCookieConsentModal(false);
    }
    declineCookieConsent() {
        this.props.setHasConsentedToCookies("false");
        this.setShowCookieConsentModal(false);
        this.props.addToPageHistory('CookiePolicy');
    }
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    render() {
        return(<ReusableModal className="modal-lg" showModal={this.state.showCookieConsentModal} modalTitle={this.modalTitle} modalContentBody={this.modalContentBody} onActionClick={this.acceptCookieConsent} actionName={this.actionButtonText} setShowCookieConsentModal={this.setShowCookieConsentModal} cancelName={this.cancelButtonText} onCancelClick={this.declineCookieConsent} />
        );
    }  
}
export default CookieModal;
