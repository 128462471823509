import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Login } from '../Authentication/Login';
import Logo from '../../Images/VM_Remarketing-NoTagline_Logo_CMYK.png';
// import { isAllowed } from '../../Shared/Utilities/AuthUtilities.js';
import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import moment from 'moment';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutToggle: false
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);

        $(document).on('click', '.nav-link, .dropdown-item', function (e) {
            $('.navbar-toggler').addClass('collapsed').attr("aria-expanded", "false");
            $('.navbar-collapse').addClass('collapsed').removeClass("show");

        });
    }

    toggleDropdown(id) {
        let el = document.getElementById(id);
        el.style.display = el.style.display === 'none' ? 'block' : 'none';
        let toggleIcon = el.style.display === 'block' ? true : false;
        this.setState({ aboutToggle: toggleIcon })
    }

    render() {
        return (
            <nav className="vm-nav shadow bg-white navbar navbar-expand-lg navbar-light fixed-top"
                id="navBar">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img className="img logo" alt="logo" src={Logo} />
                    </a>
                    <div className="d-flex justify-content-center">
                        {this.props.user !== null &&
                            <button className="navbar-toggler">
                                <FontAwesomeIcon icon={['fas', 'bell']}
                                    className="vm-notification-icon"
                                    data-toggle="collapse"
                                    data-target="#notificationsContent"
                                    aria-controls="notificationsContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                />
                            </button>
                        }
                        <button className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <FontAwesomeIcon icon={['fal', 'bars']} className="vm-menu-icon" />
                        </button>
                    </div>

                    {this.props.notifications &&
                        this.props.notifications.length > 0 &&
                        <div className="collapse navbar-collapse notifications-mobile mob-nav" id="notificationsContent">
                            <div className="d-md-block d-lg-none my-2 px-2">
                                <div className="">
                                    <p onClick={this.props.markAllAsSeen} className="text-right clickable">
                                        <span className="clear-all">
                                            Clear all <FontAwesomeIcon icon={['far', 'times-circle']} className="nav-notification ml-1"></FontAwesomeIcon>
                                        </span>
                                    </p>
                                </div>
                                {
                                    this.props.notifications.map((x, i) =>
                                        <div className="notifications-container pb-2 mb-2" value={x.id} key={i}>
                                            <div>
                                                <span className="notification-time-sm">{moment(x.dateTimeStamp).format('DD/MM/YYYY HH:mm a')}</span>
                                            </div>
                                            <span className="">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="nav-notification-dot" />
                                                <span className="message">{x.message}</span>
                                            </span>
                                            <span className="pull-right ml-2 not-times">
                                                <span className="notification-time">{moment(x.dateTimeStamp).format('DD/MM/YYYY HH:mm a')}</span>
                                                <FontAwesomeIcon icon={['far', 'times-circle']} onClick={() => { this.props.markAsSeen(x.id) }} className="nav-notification ml-2" />
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                    <div className="collapse navbar-collapse mob-nav" id="navbarSupportedContent">
                        <ul className="navbar-nav vm-navbar">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/" exact>
                                    Home
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/Auctions">
                                    Auctions
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/Sell">
                                    Sell
                                </NavLink>
                            </li>

                            {this.props.buyNowId &&
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={`/BuyNow`}>
                                        Buy Now
                                    </NavLink>
                                </li>
                            }

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/Pledge">
                                    Pledge
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/Search">
                                    Search
                                </NavLink>
                            </li>

                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropbtn" to="/About">
                                    About

                                </NavLink>
                                <span className="float-right toggle-icon">
                                    {this.state.aboutToggle === true ?
                                        <FontAwesomeIcon icon={['fas', 'chevron-up']} className="logout-icon d-lg-none" onClick={() => this.toggleDropdown('about-toggle')} />
                                        : <FontAwesomeIcon icon={['fas', 'chevron-down']} className="logout-icon d-lg-none" onClick={() => this.toggleDropdown('about-toggle')} />
                                    }
                                </span>
                                <div className="dropdown-content" id="about-toggle">
                                    <NavLink className="dropdown-item" to="/Services">Our Services</NavLink>
                                    <NavLink className="dropdown-item" to="/Partners">Partners</NavLink>
                                    <NavLink className="dropdown-item" to="/Help">Help Guides</NavLink>
                                    <NavLink className="dropdown-item" to="/BuyersFees">Buyers Fees</NavLink>
                                </div>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to="/ContactUs">
                                    Contact
                                </NavLink>
                            </li>
                            {/*
                                isAllowed('Seller') &&
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Uploads">
                                        Uploads
                                </NavLink>
                                </li>
                            }
                            {
                                isAllowed('Seller') &&
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Vehicles">
                                        Vehicles
                                </NavLink>
                                </li>
                            */}
                        </ul>
                        <div className="col login-container">
                            {
                                this.props.user !== null ?
                                    <Login messageHandler={this.props.messageHandler}
                                        handleChange={this.props.handleChange}
                                        handleLogin={this.props.handleLogin}
                                        handleLogout={this.props.handleLogout}
                                        user={this.props.user}
                                        updateUser={this.props.updateUser}
                                        notifications={this.props.user.notifications}
                                        markAsSeen={this.props.markAsSeen}
                                        markAllAsSeen={this.props.markAllAsSeen}
                                    />
                                    :
                                    <Login messageHandler={this.props.messageHandler}
                                        handleChange={this.props.handleChange}
                                        handleLogin={this.props.handleLogin}
                                        handleLogout={this.props.handleLogout}
                                        user={this.props.user}
                                        updateUser={this.props.updateUser}
                                        notifications={[]}
                                        loginError={this.props.loginError}
                                        loginResponseMessage={this.props.loginResponseMessage}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;