import React, { Component } from 'react';

export const ThemeContext = React.createContext();

class ThemeContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <ThemeContext.Provider value={{ ...this.props.theme }}>
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}

export default ThemeContextProvider;