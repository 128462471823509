import React from 'react';
import { isAllowed } from '../../../../Shared/Utilities/AuthUtilities';

const LotNotification = (props) => {
    return (
        <>
            {
                isAllowed('Buyer') &&
                props.lotState.showPricing &&
                <div className="lot-notification">
                    {
                        <div className="card-text">
                            {
                                props.lotState.sold &&
                                    props.lotState.bidUserId === props.userId ?
                                    <div className="alert alert-info text-center">
                                        <strong>You have bought this vehicle</strong>
                                    </div> :
                                    (
                                        props.lotState.bidUserId === props.userId ?
                                            <div className="alert alert-success text-center">
                                                <strong>You are the highest bidder</strong>
                                            </div> :
                                            (
                                                props.lotState.bidders.includes(props.userId) ?
                                                    <div className="alert alert-danger text-center">
                                                        <strong>You have been outbid</strong>
                                                    </div> : null
                                            )
                                    )
                            }
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default LotNotification;