import React, { Component } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

class LotsWonVsLotsBid extends Component {
    render() {

        const data = [
            { name: 'Lots won', value: this.props.won },
            { name: 'Lots bid on', value: this.props.bid - this.props.won }
          ];
          
          const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
          


        return (
            <React.Fragment>
                <h4>You have won {this.props.won} lots out of {this.props.bid} lots bid on</h4>
            <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
      </React.Fragment>
        );
    }
}

export default LotsWonVsLotsBid;