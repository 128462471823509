import React, { Component } from 'react';
import { apiPost } from '../../../Shared/Utilities/ApiFetch';
import Error from '../../../Shared/Common/Error.js';
import Loading from '../../../Shared/Common/Loading';
import toastr from 'toastr';

const PageContent = (props) => {
    return (
<React.Fragment>
        <h4>Change Password</h4>
        <div className="col-md-12 grey-box grey-box--bottom ">
                <div className="col-12 mt-2">
                    <div className="form-group ">
                        <label>Current Password</label>
                        <input autoComplete="off" onChange={props.handleChange} placeholder="Current Password" type="password" id="currentPassword" name="currentPassword" className="form-control" />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group ">
                        <label>Password</label>
                        <input autoComplete="off" onChange={props.handleChange} placeholder="Password" type="password" id="password1" name="password1" className="form-control" />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>Repeat Password</label>
                        <input autoComplete="off" onChange={props.handleChange} placeholder="Repeat password" type="password" id="password2" name="password2" className="form-control" />
                    </div>
                </div>
                <div className="float-right">
                    <button onClick={props.UpdatePassword} className="btn btn-success float-right">Update password</button>
                </div>
            </div>
        </React.Fragment>
    )
}

const Success = () => {
    return (
        <div className="row">
            <div className="col-12">
                <h2>Password reset</h2>
                <p>You may now login using your new password</p>
            </div>
        </div>
    )
}

class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: null,
            password1: null,
            password2: null
        }
        this.UpdatePassword = this.UpdatePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({ componentState: "pageContent" })
    }

    UpdatePassword() {
        let dataToPost = {
            currentPassword: this.state.currentPassword,
            password: this.state.password1,
            repeatPassword: this.state.password2
        };

        apiPost('/api/account/UpdatePassword', true, dataToPost)
            .then((result) => { this.popToast(result.body) })
            .catch(error => this.setState({ componentState: "error", error: error }));
    }



    popToast(result) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "30000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
            "toastClass": "toastr"
        }

        if (result.suceeded) {
            toastr.success(result.message)
            this.setState({ componentState: "success" });
        }
        else {
            toastr.error(result.message);

        }

    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    renderComponentState() {
        switch (this.state.componentState) {
            case "pageContent":
                return <PageContent
                    UpdatePassword={this.UpdatePassword}
                    showError={this.state.showError}
                    handleChange={this.handleChange}
                />;
            case "success":
                return <Success />;
            case "error":
                return <Error error={this.state.error} />;
            default:
                return <Loading />;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderComponentState()}
            </React.Fragment>
        );
    }
}

export default UpdatePassword;