import React, { Component } from 'react';
import Select from 'react-select';
import './Styles/SearchFacet.scss';

class SearchFacet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            if (this.state.selectedOption) {
                if (this.props.data.isMulti) {
                    let selectedOption = [...this.state.selectedOption];
                    selectedOption.forEach((option) => { option.label = this.props.data.values.find(v => v.value === option.value).display });
                    this.setState({ selectedOption });
                } else {
                    let selectedOption = this.state.selectedOption;
                    selectedOption.label = this.props.data.values.find(v => v.value === selectedOption.value).display;
                    this.setState({ selectedOption });
                }
            }
        }
    }

    onChange(selectedOption) {
        const selectValues = selectedOption != null ? (this.props.data.isMulti ? selectedOption.map((so) => (so.value)) : [selectedOption.value]) : [];
        this.props.updateFilter(this.props.data.name, selectValues);

        this.setState({ selectedOption });
    }

    render() {
        const { selectedOption } = this.state;

        const customStyles = {
            option: (provided) => ({
                ...provided,
                color: '#495057',
            }),
            placeholder: (provided) => ({
                ...provided,
                color: '#495057',
            }),
            control: (provided, state) => ({
                ...provided,
                boxShadow: 0
            })
        }

        const options = this.props.data.values != null
            ? this.props.data.values.map((v) => ({ value: v.value, label: v.display }))
            : [];

        return (
            <div className="col-lg-4 col-md-4 col-sm-12">
                <p style={this.props.theme.searchFacetLabel}>{this.props.data.name}</p>

                <Select placeholder='All'
                    isMulti={this.props.data.isMulti}
                    isClearable
                    value={selectedOption}
                    onChange={this.onChange}
                    styles={customStyles}
                    options={options} />
            </div>
        );
    }
}

export default SearchFacet;
